import React, { createContext, useContext, useState } from "react";
import Requests from "../helpers/Requests";
import { diagnosticResponse } from "../helpers/Helpers";
const TreeDataContext = createContext();

export function TreeDataProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [lastSearch, setLastSearch] = useState("");
  const getItems = (search = "", to = null) => {
    if (!loading) {
      setLoading(true);
      if (to === null) {
        setTreeData([]);
      }
      setLastSearch(search);
      Requests.postData("/user/getTreeNodes", { search: search, to: to })
        .then((response) => {
          let res = response.data;
          if (diagnosticResponse(res) && res.type === "success") {
            if (to === null) {
              setTreeData(res.data);
            } else {
              if (res.data.length > 0)
                setTreeData((prev) => [...prev, ...res.data]);
              else {
                setTreeData((prevArray) => {
                  const updatedArray = prevArray.map((item) =>
                    item.id === to ? { ...item, droppable: false } : item
                  );
                  return updatedArray;
                });
              }
            }
            if (selectedNode !== res.treeTo) {
              setSelectedNode(res.treeTo);
            }
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <TreeDataContext.Provider
      value={{
        treeData,
        setTreeData,
        getItems,
        selectedNode,
        setSelectedNode,
        loading,
        lastSearch,
      }}
    >
      {children}
    </TreeDataContext.Provider>
  );
}
export function useTreeData() {
  return useContext(TreeDataContext);
}

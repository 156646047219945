import React, { useEffect, useRef, useState } from "react";
import { useToastr } from "../context/ToastrContext";
import { useConfig } from "../context/ConfigContext";
import Requests from "../helpers/Requests";
import { diagnosticResponse } from "../helpers/Helpers";

function ImgUpload({ path, loading, callback, clickUpload, setClickUpload }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/i;
    if (!allowedExtensions.test(selectedFile.name)) {
      ToastrContext.showToast({
        type: "error",
        text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
      });
      return;
    }

    uploadFile(selectedFile);
  };
  const uploadFile = (file) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);
      const postData = new FormData();
      postData.append("file", file);
      postData.append("path", path);
      Requests.postDataForm("/upload/uploadImg", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            callback(res.file);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  useEffect(() => {
    if (clickUpload) {
      fileInputRef.current.click();
      setClickUpload(false);
    }
    // eslint-disable-next-line
  }, [clickUpload]);

  return (
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
      style={{ display: "none" }}
    />
  );
}

export default ImgUpload;

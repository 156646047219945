import React from "react";
import { useConfig } from "../../context/ConfigContext";

function ClassicTotals({ item }) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative mt-2 flex flex-col gap-2 px-3 py-2">
      {item.amount && (
        <div>{`${ConfigProvider.getTranslation("Total")}: ${item.amount} ${
          item.currency
        }`}</div>
      )}
      {item.won && (
        <div>{`${ConfigProvider.getTranslation("Won")}: ${item.won} ${
          item.currency
        }`}</div>
      )}
      {item.lost && (
        <div>{`${ConfigProvider.getTranslation("Lost")}: ${item.lost} ${
          item.currency
        }`}</div>
      )}
      {item.pending && (
        <div>{`${ConfigProvider.getTranslation("Pending")}: ${item.pending} ${
          item.currency
        }`}</div>
      )}
      {item.net && (
        <div>{`${ConfigProvider.getTranslation("NET")}: ${item.net} ${
          item.currency
        }`}</div>
      )}
      {item.paid && (
        <div>{`${ConfigProvider.getTranslation("Paid")}: ${item.paid} ${
          item.currency
        }`}</div>
      )}
      {item.unpaid && (
        <div>{`${ConfigProvider.getTranslation("Unpaid")}: ${item.unpaid} ${
          item.currency
        }`}</div>
      )}
      {item.total && (
        <div>{`${ConfigProvider.getTranslation("Total")}: ${item.total} ${
          item.currency
        }`}</div>
      )}
    </div>
  );
}

export default ClassicTotals;

import React from "react";
import CardLayout from "./helpers/CardLayout";
import Logs from "../Logs";
import { useParams } from "react-router-dom";
function LogsCard() {
  return (
    <CardLayout>
      <LogsCardPage />
    </CardLayout>
  );
}
function LogsCardPage() {
  const { _id } = useParams();
  return (
    <>
      <Logs selCard={_id} />
    </>
  );
}

export default LogsCard;

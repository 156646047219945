import React, { useEffect, useState } from "react";
import {
  ArrowRightCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Confirm from "../../components/Confirm";
import { useToastr } from "../../context/ToastrContext";

import Dropdown from "../../components/Dropdown";
import DropdownFilter from "../../components/DropdownFilter";
function SportMarkets() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SportsManageSportMarkets")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 0,
    sid: "50",
    stat: "-1",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/sports/getMarketTypes", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setOddValues(res.oddValues);

            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  const [oddValues, setOddValues] = useState(false);

  const [allSports, setAllSports] = useState({});
  useEffect(() => {
    if (Object.keys(allSports).length === 0) {
      getSports();
    }
    // eslint-disable-next-line
  }, []);
  const getSports = () => {
    Requests.postData("/sports/getAllSports", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setAllSports(res.data);
        }
      })
      .catch(() => {});
  };
  const allStat = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    1: ConfigProvider.getTranslation("Active"),
    0: ConfigProvider.getTranslation("Passive"),
  };
  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("Markets")} ✨
            </h1>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0"></div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />

            <DropdownFilter align="right">
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Status")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.stat === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            stat: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Sport")}
              </div>
              <ul className="mb-4">
                {Object.keys(allSports).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.sid === item}
                        onChange={() => {
                          setFormData({
                            ...formData,
                            sid: formData.sid === item ? null : item,
                          });
                        }}
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allSports[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </DropdownFilter>
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
      </form>
      {/* Table */}
      <SportMarketsTable
        data={data}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
        getItems={getItems}
        oddValues={oddValues}
        setData={setData}
      />
      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}
function SportMarketsTable({
  data,
  loading,
  formData,
  setFormData,
  getItems,
  oddValues,
  setData,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmItem, setConfirmItem] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const setIsShowMarketType = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/sports/setIsShowMarketType", {
        id: confirmItem.data._id,
        isShow: confirmItem.type === "isShow" ? !confirmItem.data.isShow : null,
        odd: confirmItem.type === "odd" ? confirmItem.data.odd : null,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            setConfirmItem(null);
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowConfirm(false);
          setConfirmItem(null);
        });
    }
  };
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Markets")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-full">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Market")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap min-w-[150px]">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sport")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Rate")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap max-w-[120px] min-w-[120px]">
                  &nbsp;
                </th>
              </tr>
            </thead>
            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="5">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="5" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>{" "}
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <SportMarketsTableItem
                  key={item._id}
                  item={item}
                  setShowConfirm={setShowConfirm}
                  setConfirmItem={setConfirmItem}
                  setData={setData}
                  loading={loading}
                  oddValues={oddValues}
                />
              ))}
          </table>
        </div>
      </div>
      {showConfirm && confirmItem !== null && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{confirmItem.data.name}</p>
              {confirmItem.type === "isShow" && (
                <p>{`${ConfigProvider.getTranslation("Status")} -> ${
                  confirmItem.data.isShow
                    ? ConfigProvider.getTranslation("Passive")
                    : ConfigProvider.getTranslation("Active")
                }`}</p>
              )}
              {confirmItem.type === "odd" && (
                <p>{`${ConfigProvider.getTranslation("Rate")} -> ${
                  confirmItem.data.odd
                }`}</p>
              )}
            </>
          }
          confirm={() => {
            setIsShowMarketType();
          }}
          callback={() => {
            setShowConfirm(false);
            setConfirmItem(null);
          }}
        />
      )}
    </div>
  );
}
function SportMarketsTableItem({
  item,
  setShowConfirm,
  setConfirmItem,
  setData,
  loading,
  oddValues,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const [editOdd, setEditOdd] = useState(false);

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [formData, setFormData] = useState({
    market: item.market,
    selid: item.selid,
    secid: item.secid,
    preSingleLimit: 0,
    preMultibleLimit: 0,
    liveSingleLimit: 0,
    liveMultibleLimit: 0,
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [dataLoading, setDataLoading] = useState(false);
  const getItems = () => {
    if (!dataLoading) {
      setDataLoading(true);

      Requests.postData("/sports/getLimitEventMarket", {
        market: item.market,
        selid: item.selid,
        secid: item.secid,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
          } else {
            setFormData({
              ...formData,
              preSingleLimit: res.data.preSingleLimit,
              preMultibleLimit: res.data.preMultibleLimit,
              liveSingleLimit: res.data.liveSingleLimit,
              liveMultibleLimit: res.data.liveMultibleLimit,
            });
          }
          setDataLoading(false);
        })
        .catch(() => {
          document.getElementById("mainBody").scrollTo(0, 0);
          setDataLoading(false);
        });
    }
  };
  useEffect(() => {
    if (descriptionOpen) {
      getItems();
    }
    // eslint-disable-next-line
  }, [descriptionOpen]);
  const updateLimitEventMarket = (e) => {
    e.preventDefault();

    if (!dataLoading) {
      setDataLoading(true);

      Requests.postData("/sports/updateLimitEventMarket", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
            setDescriptionOpen(false);
          }
          setDataLoading(false);
        })
        .catch(() => {
          setDataLoading(false);
        });
    }
  };
  const removeLimitEventMarket = () => {
    if (!dataLoading) {
      setDataLoading(true);
      Requests.postData("/sports/removeLimitEventMarket", {
        market: item.market,
        selid: item.selid,
        secid: item.secid,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setDataLoading(false);
          setDescriptionOpen(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setDataLoading(false);
        });
    }
  };
  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {checkRole(
            ConfigProvider.config.user,
            "ReportMatchReportsManageLimitClass"
          ) ? (
            <Link
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              className="font-semibold text-sky-500 dark:text-sky-400"
            >
              {item.name}
            </Link>
          ) : (
            <div className="text-left text-slate-900 dark:text-slate-100">
              {item.name}
            </div>
          )}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.sport}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link
            className={classNames(
              "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
              item.isShow
                ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            )}
            onClick={() => {
              setConfirmItem({ type: "isShow", data: item });
              setShowConfirm(true);
            }}
          >
            {item.isShow
              ? ConfigProvider.getTranslation("Active")
              : ConfigProvider.getTranslation("Passive")}
          </Link>
        </td>
        <td
          colSpan={2}
          className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
        >
          {editOdd ? (
            <form
              className="flex space-x-2"
              onSubmit={(e) => {
                e.preventDefault();
                setConfirmItem({ type: "odd", data: item });
                setShowConfirm(true);
              }}
            >
              <Dropdown
                name="rate"
                items={oddValues}
                selected={item.odd}
                setSelected={(val) =>
                  setData((prev) => {
                    const updatedItems = prev.map((subItem) => {
                      if (subItem._id === item._id) {
                        return {
                          ...subItem,
                          odd: val,
                        };
                      }
                      return subItem;
                    });
                    return updatedItems;
                  })
                }
                disabled={loading}
              />
              <button
                type="submit"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Button.Save")}
                </span>
              </button>
            </form>
          ) : (
            <div className="flex items-center space-x-2">
              <span className="font-semibold mx-2 grow">{item.odd}</span>
              <button
                type="button"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => setEditOdd(true)}
              >
                <PencilSquareIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              </button>
            </div>
          )}
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item._id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="4"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="w-full bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              <form
                onSubmit={(e) => {
                  updateLimitEventMarket(e);
                }}
                className="p-6 space-y-6"
              >
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {ConfigProvider.getTranslation("PreGame")}
                </h2>

                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preSingleLimit"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="preSingleLimit"
                        name="preSingleLimit"
                        type="number"
                        value={formData.preSingleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preMultibleLimit"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="preMultibleLimit"
                        name="preMultibleLimit"
                        type="number"
                        value={formData.preMultibleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {ConfigProvider.getTranslation("Live")}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveSingleLimit"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="liveSingleLimit"
                        name="liveSingleLimit"
                        type="number"
                        value={formData.liveSingleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveMultibleLimit"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="liveMultibleLimit"
                        name="liveMultibleLimit"
                        type="number"
                        value={formData.liveMultibleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>
                <footer>
                  <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                    <div className="flex justify-between">
                      <button
                        type="button"
                        disabled={dataLoading}
                        className="btn bg-slate-500 hover:bg-slate-600 text-white ml-3 flex items-center"
                        onClick={() => {
                          removeLimitEventMarket();
                        }}
                      >
                        {dataLoading ? (
                          <span>
                            <LoadingSpinner className="w-4 h-4 mb-1" />
                          </span>
                        ) : (
                          <span>
                            <EllipsisHorizontalCircleIcon className="w-4 h-4" />
                          </span>
                        )}
                        <span className="ml-2 uppercase">
                          {ConfigProvider.getTranslation("Reset")}
                        </span>
                      </button>
                      <button
                        type="submit"
                        disabled={dataLoading}
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                      >
                        <span className="mr-2 uppercase">
                          {ConfigProvider.getTranslation("Button.Save")}
                        </span>
                        {dataLoading ? (
                          <span>
                            <LoadingSpinner className="w-4 h-4 mb-1" />
                          </span>
                        ) : (
                          <span>
                            <ArrowRightCircleIcon className="w-4 h-4" />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </footer>
              </form>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}
export default SportMarkets;

import React, { useEffect, useState } from "react";
import CardLayout from "./helpers/CardLayout";
import { useConfig } from "../../context/ConfigContext";
import { useToastr } from "../../context/ToastrContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import Alert from "../../components/Alert";
import Dropdown from "../../components/Dropdown";

function AddFreespinCard() {
  return (
    <CardLayout>
      <AddFreespinCardPage />
    </CardLayout>
  );
}

function AddFreespinCardPage() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CasinoAddFreespin")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [formData, setFormData] = useState({
    aid: _id,
    template: "",
    count: "",
    betlevel: "1",
    expireDays: "",
  });
  const [freespins, setFreespins] = useState(null);
  const [freespinsSelect, setFreespinsSelect] = useState(null);
  const getItems = () => {
    if (
      !loading &&
      checkRole(ConfigProvider.config.user, "CasinoAddFreespin")
    ) {
      setLoading(true);
      Requests.postData("/cards/getFreespinsSelect", { _id: _id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setFreespins(res.data);
            let a = {};
            Object.keys(res.data).forEach((item) => {
              a[item] = res.data[item].name;
            });
            setFreespinsSelect(a);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (freespins && freespins.hasOwnProperty(formData.template)) {
      setFormData({
        ...formData,
        count: freespins[formData.template].count,
        betlevel: freespins[formData.template].betlevel,
        expireDays: freespins[formData.template].expireDays,
      });
    }
    // eslint-disable-next-line
  }, [formData.template]);

  const submitForm = (e) => {
    e.preventDefault();

    let requiredFieldErros = [];
    if (formData.template === null) {
      requiredFieldErros.push(ConfigProvider.getTranslation("template"));
    }

    if (requiredFieldErros.length > 0) {
      setMessages([
        {
          type: "error",
          text:
            "[" +
            requiredFieldErros.join(",") +
            "] " +
            ConfigProvider.getTranslation("Forms.EmptyValueError"),
        },
      ]);
      document.getElementById("mainBody").scrollTo(0, 0);
    } else if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/cards/addFreespin", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            navigate("/cards/card/freespins/list/" + _id);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <>
      <form
        className="w-full border-b border-slate-200 dark:border-slate-700"
        onSubmit={submitForm}
        autoComplete="off"
      >
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("AddFreespin")}
          </h2>
          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="freespin"
                >
                  {ConfigProvider.getTranslation("Freespin")}
                </label>

                {freespinsSelect && Object.keys(freespinsSelect).length > 0 && (
                  <Dropdown
                    name="Freespin"
                    items={freespinsSelect}
                    selected={formData.template}
                    setSelected={(val) => {
                      setFormData({ ...formData, template: val });
                    }}
                  />
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="count"
                >
                  {ConfigProvider.getTranslation("Count")}
                </label>
                <input
                  id="count"
                  name="count"
                  type="number"
                  value={formData.count}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Count")}
                  required
                  maxLength={10}
                  disabled={loading}
                  step={1}
                  pattern="\d*"
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="betlevel"
                >
                  {ConfigProvider.getTranslation("BetLevel")}
                </label>
                <input
                  id="betlevel"
                  name="betlevel"
                  type="number"
                  value={formData.betlevel}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("BetLevel")}
                  required
                  min={1}
                  step={1}
                  pattern="\d*"
                  disabled={loading}
                  maxLength={10}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="expireDays"
                >
                  {ConfigProvider.getTranslation("ExpireDays")}
                </label>
                <input
                  id="expireDays"
                  name="expireDays"
                  type="number"
                  value={formData.expireDays}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("ExpireDays")}
                  maxLength={10}
                  disabled={loading}
                  step={1}
                  pattern="\d*"
                  required
                  min={1}
                  max={100}
                />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </>
  );
}
export default AddFreespinCard;

import React, { useState, useEffect } from "react";
import { usePrefences } from "../../context/PrefencesContext";
import { useConfig } from "../../context/ConfigContext";
import TreeMain from "../../components/Tree/TreeMain";
import Alert from "../../components/Alert";
import {
  ArrowRightCircleIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";

import PaginationClassic from "../../components/PaginationClassic";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import Requests from "../../helpers/Requests";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

import ShopTooltip from "../../components/ShopTooltip";
import DropdownCard from "../../components/Search/DropdownCard";
import ClassicTotals from "../../components/TotalsFooter/ClassicTotals";
import { Link, useNavigate } from "react-router-dom";
function ReportCard() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "ReportCardReportsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [excel, setExcel] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [formData, setFormData] = useState({
    date1: formatDate(getDate(), true, false),
    date2: formatDate(addDays(getDate(), 1), true, false),
    card: null,
    page: 1,
    sort: 0,
    sortBy: 1,
  });
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.excel = excel;
      Requests.postData("/report/getBetReportPlayer", postData, excel)
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res);
          if (excel) {
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "export.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
          } else {
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              if (formData.page === 1) {
                setTotalItems(res.c);
                setTotals(res.totals);
              }
              setData(res.data);
            }
          }
          setLoading(false);
          setExcel(false);
        })
        .catch((e) => {
          console.log(e);
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
          setExcel(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  useEffect(() => {
    if (excel) {
      getItems();
    } // eslint-disable-next-line
  }, [excel]);

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("CardReports")} (
                  {ConfigProvider.getTranslation("Sports")}) ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
                {checkRole(
                  ConfigProvider.config.user,
                  "ReportCardReportsExcel"
                ) && (
                  <button
                    type="button"
                    onClick={() => {
                      setExcel(true);
                    }}
                    className="btn bg-emerald-500 hover:bg-emerald-600 text-slate-800"
                  >
                    <DocumentTextIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="ml-2">
                      {ConfigProvider.getTranslation("Excel")}
                    </span>
                  </button>
                )}
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col sm:flex-row sm:mb-0 gap-4 mr-2">
                <DropdownCard
                  setSelectedItem={(val) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        card: val,
                      };
                    });
                  }}
                />
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="datetime-local"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="datetime-local"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
              </div>
            </div>
            {/* Table */}
            <ReportCardTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
            />
            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />

              <div className="lg:flex gap-2">
                {totals.map((item, index) => (
                  <ClassicTotals key={index} item={item} />
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}
function ReportCardTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("CardReports")} (
          {ConfigProvider.getTranslation("Sports")})
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Account")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Total")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Won")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("NET")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("GGR")}
                  </div>
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="6" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        {ConfigProvider.config.isShopSystem && (
                          <ShopTooltip
                            size="lg"
                            position="right"
                            bg="dark"
                            shopName=""
                            shop={item.shop}
                            auth="2"
                          />
                        )}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <Link
                          to={"/cards/card/view/" + item.aid}
                          className="text-sky-500 dark:text-sky-400 font-medium text-left"
                        >
                          {`${item.uname}${
                            item.name && item.name.length > 0
                              ? ` | ${item.name}`
                              : ""
                          }`}
                        </Link>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-sky-500">
                        <div className="text-center">{`${item.amount} ${item.currency}`}</div>
                      </td>

                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-emerald-500">
                        <div className="text-center">{`${item.won} ${item.currency}`}</div>
                      </td>

                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div
                          className={`text-center ${
                            item.net2 < 0
                              ? "text-rose-500"
                              : item.net2 > 0
                              ? "text-emerald-500"
                              : ""
                          }`}
                        >{`${item.net} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div
                          className={`text-center ${
                            item.net2 < 0
                              ? "text-rose-500"
                              : item.net2 > 0
                              ? "text-emerald-500"
                              : ""
                          }`}
                        >{`%${item.ggr}`}</div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReportCard;

import React, { useState } from "react";
import { useDeviceSize } from "../../context/DeviceSizeContext";
import TreeComponent from "./TreeComponent";
import { classNames } from "../../helpers/Helpers";
import { useConfig } from "../../context/ConfigContext";

function TreeMainNav({ callback }) {
  const ConfigProvider = useConfig();
  const DeviceSizeContext = useDeviceSize();
  const [showTree, setShowTree] = useState(false);
  return DeviceSizeContext.deviceType !== "lg" ? (
    <>
      <div className="w-full fixed bottom-0 left-0 bg-white dark:bg-[#182235] border-t border-slate-200 dark:border-slate-700 z-10 flex flex-col">
        {showTree && (
          <div className="p-2 h-[calc(100vh-41px)] overflow-y-auto">
            <TreeComponent callback={callback} />
          </div>
        )}
        <div
          className={classNames(
            "flex items-center p-2 w-full justify-center h-full",
            !showTree ? "text-slate-500" : "text-indigo-400"
          )}
          onClick={() => {
            setShowTree((prev) => !prev);
          }}
        >
          <svg
            className={classNames(
              "w-6 h-6 mr-2",
              !showTree ? "fill-slate-500" : "fill-indigo-400"
            )}
            viewBox="0 0 32 32"
          >
            <path d="M23 9.005h6a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v1H11v-1a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h4v20a2.002 2.002 0 0 0 2 2h4v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v1h-4v-9h4v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v1h-4v-9h4v1a2 2 0 0 0 2 2Zm0-6h6v4h-6Zm-14 4H3v-4h6Zm14 18h6v4h-6Zm0-11h6v4h-6Z" />
          </svg>
          {ConfigProvider.getTranslation("Role.Reseller")}
          {" | "}
          {ConfigProvider.getTranslation("Role.Shop")}
        </div>
      </div>
    </>
  ) : null;
}

export default TreeMainNav;

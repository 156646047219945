import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../../context/TransitionContext";

import { useConfig } from "../../context/ConfigContext";
import { ClockIcon, UserIcon } from "@heroicons/react/24/solid";
import { usePrefences } from "../../context/PrefencesContext";
import Confirm from "../Confirm";

function UserMenu({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  const ConfigProvider = useConfig();
  const auth = ConfigProvider.config.user.auth;
  const PrefencesProvider = usePrefences();

  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="rounded-full p-1.5 bg-slate-100 dark:bg-indigo-500 mr-1 md:mr-0">
          <UserIcon className="w-5 h-5 text-indigo-500 dark:text-indigo-700" />
        </span>
        <div className="flex items-center truncate">
          <span className="hidden md:inline-block truncate ml-2 text-sm font-medium dark:text-slate-300 group-hover:text-slate-800 dark:group-hover:text-slate-200">
            {ConfigProvider.config.user.uname}
          </span>
          <svg
            className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700">
            <div className="font-medium text-slate-800 dark:text-slate-100">
              {ConfigProvider.config.user.uname}
            </div>
            <div className="text-xs text-slate-500 dark:text-slate-400 italic">
              {auth === "0"
                ? "Supervisor"
                : auth === "1"
                ? ConfigProvider.getTranslation("Role.Reseller")
                : auth === "2"
                ? ConfigProvider.getTranslation("Role.Shop")
                : "Translator"}
            </div>
          </div>
          <div className="pt-1 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700">
            <Link
              to="/profile/changeTimezone"
              className="flex items-center space-x-1 text-xs font-semibold truncate min-w-0 max-w-[150px] text-slate-600 dark:text-slate-400 hover:text-indigo-500 hover:dark:text-white cursor-pointer"
            >
              <ClockIcon className="w-4 h-4" />
              <span>{PrefencesProvider.prefences.timezone}</span>
            </Link>
          </div>
          <ul>
            <li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
                to="/profile/changePassword"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {ConfigProvider.getTranslation("ChangePassword")}
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                  setShowConfirm(true);
                }}
              >
                {ConfigProvider.getTranslation("SignOut")}
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("ConfirmSignOut")}</p>
            </>
          }
          confirm={() => {
            ConfigProvider.signout();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}

export default UserMenu;

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function formatDate(date, time = false, showSeconds = true) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  if (time && !showSeconds)
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  if (time && showSeconds)
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  else return `${year}-${month}-${day}`;
}
export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getDate() {
  let date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}
export function diagnosticResponse(res) {
  if (res.type === "error" && res.code === 101) {
    window.location.href = "/error";
    console.log("gitti");
    return false;
  } else if (res.type === "error" && res.code === 401) {
    console.log("gitti");
    window.location.reload();
    return false;
  } else return true;
}
export function checkRole(user, role) {
  if (
    user &&
    !user.roles.hasOwnProperty(role) &&
    !user.roles.hasOwnProperty("Supervisor")
  ) {
    return false;
  }
  return true;
}

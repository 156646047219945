import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

function UpsertReserveDomain() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SiteManageDomain")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);

      Requests.postData("/administration/reserveDomain", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
            setTimeout(() => {
              navigate(`/system/reserveDomain`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <div className="grow max-w-3xl mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("DomainRezerv")}
        {" / "}
        {ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <section>
            <div className="relative">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                {ConfigProvider.getTranslation("Name")}
              </label>
              <input
                id="name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                className="w-full form-input"
                placeholder={ConfigProvider.getTranslation("Domain")}
                required
                maxLength={20}
                disabled={loading}
              />
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex self-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default UpsertReserveDomain;

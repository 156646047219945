import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useConfig } from "../context/ConfigContext";

function NoData() {
  const ConfigProvider = useConfig();
  return (
    <div className="text-lg py-20 w-full flex flex-col items-center text-slate-700 dark:text-slate-500">
      <div>
        <ExclamationTriangleIcon className="w-24 h-24" />
      </div>
      <div className="my-3">{ConfigProvider.getTranslation("NoData")}</div>
    </div>
  );
}

export default NoData;

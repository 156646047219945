import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import Dropdown from "../../components/Dropdown";

function AddSupportTicket() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "Support")) {
      navigate("/");
    } else if (ConfigProvider.config.user.auth === "0") {
      navigate("/support/tickets");
    }
    // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    category: null,
    title: "",
    content: "",
    phone: "",
    telegram: "",
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      let requiredFieldErros = [];
      if (formData.category === null) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Category"));
      }

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        document.getElementById("mainBody").scrollTo(0, 0);
      } else {
        setLoading(true);
        setMessages([]);
        Requests.postData("/support/createTicket", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setTimeout(() => {
                navigate(`/support/tickets`);
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const [allCategories, setAllCategories] = useState(null);
  const getSupportCategoriesFilterSelect = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/support/getSupportCategoriesFilterSelect", {})
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setAllCategories(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getSupportCategoriesFilterSelect();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="grow max-w-4xl mx-auto">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("SupportCategories")}
        {" / "}
        {ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>

          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              {allCategories && (
                <div className="relative col-span-2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="category"
                  >
                    {ConfigProvider.getTranslation("Category")}
                  </label>
                  <Dropdown
                    name="category"
                    items={allCategories}
                    selected={formData.category}
                    setSelected={(val) => {
                      setFormData({ ...formData, category: val });
                    }}
                  />
                </div>
              )}
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="title"
                >
                  {ConfigProvider.getTranslation("Title")}
                </label>
                <input
                  id="title"
                  name="title"
                  type="text"
                  value={formData.title}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Title")}
                  required
                  maxLength={100}
                  disabled={loading}
                />
              </div>

              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="phone"
                >
                  {ConfigProvider.getTranslation("Phone")}
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder="+1 123 45 67"
                  maxLength={30}
                  disabled={loading}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="telegram"
                >
                  {ConfigProvider.getTranslation("Telegram")}
                </label>
                <input
                  id="telegram"
                  name="telegram"
                  type="text"
                  value={formData.telegram}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder="@username"
                  maxLength={30}
                  disabled={loading}
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="content"
                >
                  {ConfigProvider.getTranslation("Content")}:
                </label>
                <div className="bg-white w-full text-black">
                  <textarea
                    value={formData.content}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return { ...prev, content: e.target.value };
                      });
                    }}
                    className="w-full min-h-[500px]"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default AddSupportTicket;

import React from "react";
import CardLayout from "./helpers/CardLayout";
import ListFreespins from "../helperCasino/ListFreespins";
import { useParams } from "react-router-dom";
function ListFreespinsCard() {
  return (
    <CardLayout>
      <ListFreespinsCardPage />
    </CardLayout>
  );
}
function ListFreespinsCardPage() {
  const { _id } = useParams();
  return (
    <>
      <ListFreespins selCard={_id} />
    </>
  );
}

export default ListFreespinsCard;

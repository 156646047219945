import React, { useEffect, useState } from "react";
import CardLayout from "./helpers/CardLayout";
import { useConfig } from "../../context/ConfigContext";
import { useToastr } from "../../context/ToastrContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import Alert from "../../components/Alert";
import Dropdown from "../../components/Dropdown";

function AddBonusCard() {
  return (
    <CardLayout>
      <AddBonusCardPage />
    </CardLayout>
  );
}
function AddBonusCardPage() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CasinoAddBonus")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [formData, setFormData] = useState({
    aid: _id,
    bonus: null,
    amount: "",
  });
  const [bonusList, setBonusList] = useState(null);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/cards/getListBonusSelect", {})
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setBonusList(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount" && value.length > 0 && !/^\d*\.?\d*$/.test(value)) {
      return false;
    }
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const submitForm = (e) => {
    e.preventDefault();

    let requiredFieldErros = [];
    if (formData.bonus === null) {
      requiredFieldErros.push(ConfigProvider.getTranslation("Bonus"));
    }

    if (requiredFieldErros.length > 0) {
      setMessages([
        {
          type: "error",
          text:
            "[" +
            requiredFieldErros.join(",") +
            "] " +
            ConfigProvider.getTranslation("Forms.EmptyValueError"),
        },
      ]);
      document.getElementById("mainBody").scrollTo(0, 0);
    } else if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/cards/addBonus", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            navigate("/cards/card/bonus/list/" + _id);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <form
        className="w-full border-b border-slate-200 dark:border-slate-700"
        onSubmit={submitForm}
        autoComplete="off"
      >
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("AddBonus")}
          </h2>
          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="bonus"
                >
                  {ConfigProvider.getTranslation("Bonus")}
                </label>

                {bonusList && Object.keys(bonusList).length > 0 && (
                  <Dropdown
                    name="bonus"
                    items={bonusList}
                    selected={formData.bonus}
                    setSelected={(val) => {
                      setFormData({ ...formData, bonus: val });
                    }}
                  />
                )}
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="amount"
                >
                  {ConfigProvider.getTranslation("Amount")}
                </label>
                <input
                  id="amount"
                  name="amount"
                  type="text"
                  value={formData.amount}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Amount")}
                  required
                  maxLength={20}
                  disabled={loading}
                />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </>
  );
}
export default AddBonusCard;

import React, { createContext, useContext, useEffect, useState } from "react";
import Loading from "../components/Loading/Loading";
import Requests from "../helpers/Requests";
const ConfigContext = createContext();

export function ConfigProvider({ children }) {
  const key = "translations";
  const [config, setConfig] = useState(null);
  const [translations, setTranslations] = useState(null);
  const [error, setError] = useState(false);
  const [checkUserTimeout, setCheckUserTimeout] = useState(null);
  const checkUserTimeoutDuration = 60000;
  const checkUser = async () => {
    checkUserTimeout && clearTimeout(checkUserTimeout);
    const fetchData = async () => {
      const data = await Requests.postData("/user/check", {});
      return data;
    };
    fetchData()
      .then((response) => {
        const res = response.data;
        if (res.type === "success") {
          setConfig((prevObject) => {
            prevObject.user = res.data;
            if (prevObject.lan !== res.lan) {
              sessionStorage.removeItem(key);
              // window.location.reload();
              console.log(prevObject.lan !== res.lan);
            }
            return prevObject;
          });
        } else {
          setConfig((prevObject) => {
            prevObject.user !== null && window.location.reload();
            prevObject.user = null;
            return prevObject;
          });
        }
        setCheckUserTimeout(
          setTimeout(() => {
            checkUser();
          }, checkUserTimeoutDuration)
        );
      })
      .catch((e) => {
        console.log(e);
        console.log("/error/404/check request error");
      });
  };
  useEffect(() => {
    Requests.getConf()
      .then((response) => {
        setConfig(response.data.data);
        let sessionlan = sessionStorage.getItem(key + "_lan");
        if (
          !(
            sessionlan &&
            sessionlan.length === 2 &&
            sessionlan === response.data.data.lan
          )
        ) {
          refreshTranslations();
        }
      })
      .catch(() => {
        setError(true);
      });

    let found = false;
    const savedData = sessionStorage.getItem(key);
    if (savedData) {
      let data = JSON.parse(savedData);
      if (typeof data === "object" && Object.keys(data).length > 0) {
        found = true;
        setTranslations(data);
      }
    }
    if (!found) {
      refreshTranslations();
    }

    setCheckUserTimeout(
      setTimeout(() => {
        checkUser();
      }, checkUserTimeoutDuration)
    );
    return () => {
      clearTimeout(checkUserTimeout);
    };
    // eslint-disable-next-line
  }, []);
  const refreshTranslations = () => {
    Requests.getTranslations()
      .then((response) => {
        setTranslations(response.data.data);
        sessionStorage.setItem(key, JSON.stringify(response.data.data));
        sessionStorage.setItem(key + "_lan", response.data.lan);
      })
      .catch((err) => {
        setError(true);
      });
  };
  const translateItem = (item) => {
    const lan = config.lan;
    return item.hasOwnProperty("translation") && item.translation
      ? item.translation.hasOwnProperty(lan)
        ? item.translation[lan]
        : item.translation.hasOwnProperty("en")
        ? item.translation["en"]
        : item.hasOwnProperty("name")
        ? item.name
        : ""
      : item.hasOwnProperty("name")
      ? item.name
      : "";
  };
  const getTranslation = (item) => {
    return translations.hasOwnProperty(item) ? translations[item] : item;
  };
  const signout = () => {
    const fetchData = async () => {
      const data = await Requests.postData("/user/logout", {});
      return data;
    };
    fetchData()
      .then((res) => {
        window.location.reload();
      })
      .catch(() => {
        console.log("/error/404/logout error");
      });
  };
  const [customData, setCustomData] = useState(null);
  const [refreshCustomData, setRefreshCustomData] = useState(false);
  const ObjectIdEmpty = "000000000000000000000000";
  const ObjectIdUndefined = "000000000000000000000001";

  const allPlatforms = {
    web: "Web",
    mobile: "Mobile",
  };

  return (
    <ConfigContext.Provider
      value={{
        config,
        translations,
        translateItem,
        getTranslation,
        checkUser,
        signout,
        customData,
        setCustomData,
        refreshCustomData,
        setRefreshCustomData,
        ObjectIdEmpty,
        ObjectIdUndefined,
        allPlatforms,
      }}
    >
      {error ? (
        <div>API Connection Error</div>
      ) : config !== null && translations !== null ? (
        children
      ) : (
        <Loading />
      )}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  return useContext(ConfigContext);
}

import React, { useState, useEffect } from "react";
import { usePrefences } from "../../context/PrefencesContext";
import { useConfig } from "../../context/ConfigContext";
import TreeMain from "../../components/Tree/TreeMain";
import Alert from "../../components/Alert";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import TreeMainNav from "../../components/Tree/TreeMainNav";
import Requests from "../../helpers/Requests";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

import ShopTooltip from "../../components/ShopTooltip";
import DropdownFilter from "../../components/DropdownFilter";
import DropdownCard from "../../components/Search/DropdownCard";
import CasinoTotals from "../../components/TotalsFooter/CasinoTotals";
import { Link, useNavigate } from "react-router-dom";
function GeneralAccountingReport() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "ReportCasinoBetsReportsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    date1: formatDate(getDate(), false),
    date2: formatDate(addDays(getDate(), 1), false),
    card: null,
    provider: [],
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({});
  const [totals, setTotals] = useState([]);
  const [showTotals, setShowTotals] = useState(false);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      Requests.postData("/report/getGeneralAccountingReport", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setTotal(res.total);
            setTotals(res.totals);
            setShowTotals(false);
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [PrefencesProvider.prefences.offset]);

  const [allProviders, setAllProviders] = useState({});
  useEffect(() => {
    if (
      ConfigProvider.config.user.auth === "0" &&
      checkRole(
        ConfigProvider.config.user,
        "ReportCasinoBetsReportsProviders"
      ) &&
      Object.keys(allProviders).length === 0
    ) {
      getProviders();
    }
    // eslint-disable-next-line
  }, []);
  const getProviders = () => {
    Requests.postData("/general/getAllProviders", { type: 0 })
      .then((response) => {
        let res = response.data;
        if (res.type === "success") {
          setAllProviders(res.data);
        }
      })
      .catch(() => {});
  };

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getItems();
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("Reports")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col sm:flex-row sm:mb-0 gap-4 mr-2">
                <DropdownCard
                  setSelectedItem={(val) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        card: val,
                      };
                    });
                  }}
                />
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="date"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="date"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
                {checkRole(
                  ConfigProvider.config.user,
                  "ReportCasinoBetsReportsProviders"
                ) && (
                  <DropdownFilter align="right">
                    <>
                      <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                        {ConfigProvider.getTranslation("Provider")}
                      </div>
                      <ul className="mb-4">
                        {Object.keys(allProviders).map((item) => (
                          <li key={item} className="py-1 px-3">
                            <label className="flex items-center cursor-pointer">
                              <input
                                id={item}
                                name={item}
                                type="checkbox"
                                className="form-checkbox"
                                checked={formData.provider.includes(item)}
                                onChange={() =>
                                  setFormData((prev) => {
                                    if (prev.provider.includes(item)) {
                                      return {
                                        ...prev,
                                        provider: prev.provider.filter(
                                          (val) => val !== item
                                        ),
                                      };
                                    } else {
                                      return {
                                        ...prev,
                                        provider: [...prev.provider, item],
                                      };
                                    }
                                  })
                                }
                                disabled={loading}
                              />
                              <span className="text-sm font-medium ml-2">
                                {allProviders[item]}
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </>
                  </DropdownFilter>
                )}
              </div>
            </div>

            {/* Table */}
            <GeneralAccountingReportTable data={data} loading={loading} />

            {/* Pagination */}
            <div className="mt-8">
              <div className="lg:flex gap-2">
                {Object.keys(total).length > 0 && <CasinoTotals item={total} />}
              </div>
              <div className="my-2">
                <button
                  type="button"
                  onClick={() => setShowTotals(!showTotals)}
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  {ConfigProvider.getTranslation("Totals")}
                </button>
              </div>
              {showTotals && (
                <div className="lg:flex gap-2">
                  {totals.map((item, index) => (
                    <CasinoTotals key={index} item={item} />
                  ))}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}
function GeneralAccountingReportTable({ data, loading }) {
  const ConfigProvider = useConfig();

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Reports")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Account")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Total")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Casino")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("LiveCasino")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("BonusWageringBet")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("BonusWageringWin")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("BonusAward")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Freespin")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Jackpot")}
                  </div>
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="10">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="10" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        {ConfigProvider.config.isShopSystem && (
                          <ShopTooltip
                            size="lg"
                            position="right"
                            bg="dark"
                            shopName=""
                            shop={item.shop}
                            auth={item.auth}
                          />
                        )}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <Link
                          to={"/accounts/account/view/" + item.aid}
                          className="text-sky-500 dark:text-sky-400 font-medium text-left"
                        >
                          {`${item.uname}${
                            item.name && item.name.length > 0
                              ? ` | ${item.name}`
                              : ""
                          }`}
                        </Link>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center">{`${item.total} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-sky-500">{`${item.slot} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-purple-500">{`${item.liveCasino} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-slate-500">{`${item.bonusWageringBet} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-slate-500">{`${item.bonusWageringWin} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-teal-500">{`${item.bonusAward} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-rose-500">{`${item.freespinWin} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-amber-500">{`${item.jackpot} ${item.currency}`}</div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GeneralAccountingReport;

import React, { useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import AccountSidebar from "../../components/AccountSidebar";
import {
  ArrowRightCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Alert from "../../components/Alert";
import Requests from "../../helpers/Requests";
import { diagnosticResponse } from "../../helpers/Helpers";

function ChangePassword() {
  const ConfigProvider = useConfig();
  return (
    <>
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
          {ConfigProvider.getTranslation("Tab.Account")} ✨
        </h1>
      </div>

      {/* Content */}
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <AccountSidebar />
          <ChangePasswordPage />
        </div>
      </div>
    </>
  );
}
function ChangePasswordPage() {
  const ConfigProvider = useConfig();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    password2: "",
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [messages, setMessages] = useState([]);
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        document.getElementById("mainBody").scrollTo(0, 0);
        setLoading(false);
      } else {
        Requests.postData("/user/changePassword", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setFormData({
                oldPassword: "",
                password: "",
                password2: "",
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  return (
    <form className="grow" onSubmit={updateForm}>
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
          {ConfigProvider.getTranslation("ChangePassword")}
        </h2>

        {/* Password */}
        <section>
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
            <div className="relative sm:col-span-2">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="oldPassword"
              >
                {ConfigProvider.getTranslation("Password.Current")}
              </label>
              <input
                id="oldPassword"
                name="oldPassword"
                type={showPassword ? "text" : "password"}
                className="w-full form-input"
                placeholder="*******"
                value={formData.oldPassword}
                onChange={handleChange}
                required
                maxLength={15}
              />
              <div
                className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <EyeIcon className="w-4 h-4 text-color-3" />
                ) : (
                  <EyeSlashIcon className="w-4 h-4 text-color-3" />
                )}
              </div>
            </div>
            <div className="relative">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="password"
              >
                {ConfigProvider.getTranslation("Password.New")}
              </label>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                className="w-full form-input"
                placeholder="*******"
                value={formData.password}
                onChange={handleChange}
                required
                maxLength={15}
              />
              <div
                className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <EyeIcon className="w-4 h-4 text-color-3" />
                ) : (
                  <EyeSlashIcon className="w-4 h-4 text-color-3" />
                )}
              </div>
            </div>
            <div className="relative">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="password2"
              >
                {ConfigProvider.getTranslation("Password.NewAgain")}
              </label>
              <input
                id="password2"
                name="password2"
                type={showPassword ? "text" : "password"}
                className="w-full form-input"
                placeholder="*******"
                value={formData.password2}
                onChange={handleChange}
                required
                maxLength={15}
              />
              <div
                className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <EyeIcon className="w-4 h-4 text-color-3" />
                ) : (
                  <EyeSlashIcon className="w-4 h-4 text-color-3" />
                )}
              </div>
            </div>
            <div className="col-span-2">
              <ul className="ml-10 my-2 list-disc">
                <li>
                  {ConfigProvider.getTranslation("Password.MinMaxLength")}
                </li>
                <li>
                  {ConfigProvider.getTranslation(
                    "Password.RequiredUppcaseandLowercaseAndNumber"
                  )}
                </li>
                <li>
                  {ConfigProvider.getTranslation("Password.CaseSensetive")}
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      {/* Panel footer */}
      <footer>
        <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
          <div className="flex self-end">
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
            >
              <span className="mr-2 uppercase">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
              {loading ? (
                <span>
                  <LoadingSpinner className="w-4 h-4 mb-1" />
                </span>
              ) : (
                <span>
                  <ArrowRightCircleIcon className="w-4 h-4" />
                </span>
              )}
            </button>
          </div>
        </div>
      </footer>
    </form>
  );
}

export default ChangePassword;

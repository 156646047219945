import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import {
  CalendarIcon,
  ChatBubbleLeftIcon,
  ChevronRightIcon,
  IdentificationIcon,
  PaperAirplaneIcon,
  PhoneIcon,
  TagIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import Dropdown from "../../components/Dropdown";

import { usePrefences } from "../../context/PrefencesContext";
import Confirm from "../../components/Confirm";

function EditSupportTicket() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  const PrefencesProvider = usePrefences();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "Support")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    comment: "",
    _id: _id ? _id : undefined,
  });
  const [ticketData, setTicketData] = useState({
    uname: "",
    date: null,
    lastUpdate: null,
    lastUpdatedAccount: "",
    statusDesc: "",
    status: 0,
    title: "",
    content: "",
    priority: 0,
    priorityDesc: "",
    category: "",
    phone: "",
    telegram: "",
    comments: [],
  });

  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/support/writeComment", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              navigate(`/support/tickets`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  useEffect(() => {
    if (formData._id) {
      Requests.postData("/support/getSupportTicket", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setTicketData((prev) => {
              return {
                ...prev,
                uname: res.data.uname,
                date: res.data.date,
                lastUpdate: res.data.lastUpdate,
                lastUpdatedAccount: res.data.lastUpdatedAccount,
                note: res.data.note,
                status: res.data.status,
                statusDesc: res.data.statusDesc,
                title: res.data.title,
                priority: res.data.priority,
                priorityDesc: res.data.priorityDesc,
                category: res.data.category,
                telegram: res.data.telegram,
                phone: res.data.phone,
                content: res.data.content,
                comments: res.data.comments,
              };
            });
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [formData._id]);

  const [editStatus, setEditStatus] = useState(false);
  return (
    <div className="grow mx-auto">
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        {messages.length > 0 && (
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        <div className="w-full border border-slate-200 dark:border-slate-700">
          <section>
            <div className="relative md:flex md:h-full">
              <LeftSide
                ticketData={ticketData}
                editStatus={editStatus}
                setEditStatus={setEditStatus}
              />
              <div className="grow flex flex-col md:translate-x-0 transition-transform duration-300 ease-in-out">
                <div className="grow px-4 sm:px-6 md:px-5 py-6">
                  <div className="md:overflow-y-auto md:h-[calc(100dvh-250px)]">
                    <div className="border-b w-full pt-2 pb-4  border-slate-200 dark:border-slate-700">
                      <Message
                        text={ticketData.content}
                        date={
                          ticketData.date &&
                          new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            hour: "numeric",
                            year: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(ticketData.date))
                        }
                        byWhom={1}
                      />
                    </div>
                    <div className="pt-4 pb-2">
                      {ticketData.comments.map((item, index) => (
                        <Message
                          key={index}
                          text={item.content}
                          date={item.createdAt}
                          byWhom={item.byWhom}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="sticky bottom-0">
                  <div className="flex items-center justify-between bg-white dark:bg-slate-900 border-t border-slate-200 dark:border-slate-700 px-4 sm:px-6 md:px-5 h-16">
                    {/* Message input */}

                    <div className="grow mr-3">
                      <input
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        id="message-input"
                        className="form-input w-full bg-slate-100 dark:bg-slate-800 border-transparent dark:border-transparent focus:bg-white dark:focus:bg-slate-800 placeholder-slate-500"
                        type="text"
                        placeholder={ConfigProvider.getTranslation("Message")}
                        required
                        maxLength={3000}
                      />
                    </div>
                    <button
                      type="submit"
                      className=" flex items-center btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
                    >
                      {ConfigProvider.getTranslation("Button.Send")}
                      <ChevronRightIcon className="w-4 h-4 ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </div>
  );
}
function LeftSide({ ticketData, editStatus, setEditStatus }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const navigate = useNavigate();
  const ToastrContext = useToastr();
  const { _id } = useParams();

  const [formData, setFormData] = useState({
    priority: ticketData.priority.toString(),
    status: ticketData.status.toString(),
  });
  useEffect(() => {
    if (!editStatus) {
      setFormData({
        priority: ticketData.priority.toString(),
        status: ticketData.status.toString(),
      });
    }
    // eslint-disable-next-line
  }, [ticketData]);

  const allStat = {
    0: ConfigProvider.getTranslation("WaitingFromSystem"),
    1: ConfigProvider.getTranslation("WaitingFromCustomer"),
    2: ConfigProvider.getTranslation("Closed"),
  };
  const allPriority = {
    0: ConfigProvider.getTranslation("Normal"),
    1: ConfigProvider.getTranslation("High"),
    2: ConfigProvider.getTranslation("Low"),
  };

  const [loading, setLoading] = useState(false);
  const updateSupportTicketStatus = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/support/updateSupportTicketStatus", {
        priority: formData.priority,
        status: formData.status,
        _id: _id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
            setTimeout(() => {
              navigate(`/support/tickets`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div className=" w-full md:w-auto -mr-px md:translate-x-0 transition-transform duration-200 ease-in-out translate-x-0">
      <div className="bg-white dark:bg-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 lg:border-r border-slate-200 dark:border-slate-700 md:w-72 xl:w-80 lg:h-[calc(100dvh-130px)]">
        <div>
          {/* Group header */}
          <div className="z-10">
            <div className="flex items-center bg-white dark:bg-slate-900 border-b border-slate-200 dark:border-slate-700 px-5 h-16">
              <div className="w-full flex items-center justify-between">
                <div className="relative">
                  <span className="font-semibold text-slate-800 dark:text-slate-100">
                    #{ConfigProvider.getTranslation("Support")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Group body */}
          <div className="flex flex-col">
            <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex items-center">
              <UserIcon className="w-4 h-4 mr-2" />
              {ticketData.uname}
            </div>
            <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex items-center">
              <TagIcon className="w-4 h-4 mr-2" />
              {ticketData.category}
            </div>
            <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex items-center">
              <ChatBubbleLeftIcon className="w-4 h-4 mr-2" />
              {ticketData.title}
            </div>
            <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex items-center">
              <CalendarIcon className="w-4 h-4 mr-2" />
              {new Intl.DateTimeFormat("default", {
                day: "numeric",
                month: "numeric",
                hour: "numeric",
                year: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }).format(new Date(ticketData.date))}
            </div>
            {ticketData.phone && ticketData.phone.length > 0 && (
              <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex items-center">
                <PhoneIcon className="w-4 h-4 mr-2" />
                {ticketData.phone}
              </div>
            )}
            {ticketData.telegram && ticketData.telegram.length > 0 && (
              <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex items-center">
                <PaperAirplaneIcon className="w-4 h-4 mr-2" />
                {ticketData.telegram}
              </div>
            )}

            {!editStatus ? (
              <>
                <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex justify-between">
                  <span
                    className={`rounded px-2 py-0.5 text-white font-semibold ${
                      ticketData.priority === 1
                        ? "bg-rose-500"
                        : ticketData.priority === 0
                        ? "bg-emerald-600"
                        : "bg-indigo-600"
                    }`}
                  >
                    {ticketData.priorityDesc}
                  </span>
                  {ConfigProvider.config.user.auth === "0" && (
                    <button
                      type="button"
                      className="p-1.5 shrink-0 rounded bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm ml-2"
                      onClick={() => setEditStatus(true)}
                    >
                      <svg
                        className="w-4 h-4 fill-current text-slate-500"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex justify-between">
                  <span
                    className={`rounded px-2 py-0.5 text-white font-semibold ${
                      ticketData.status === 2
                        ? "bg-indigo-500"
                        : ticketData.status === 1
                        ? "bg-emerald-600"
                        : "bg-rose-600"
                    }`}
                  >
                    {ticketData.statusDesc}
                  </span>
                  {ConfigProvider.config.user.auth === "0" && (
                    <button
                      type="button"
                      className="p-1.5 shrink-0 rounded bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm ml-2"
                      onClick={() => setEditStatus(true)}
                    >
                      <svg
                        className="w-4 h-4 fill-current text-slate-500"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                      </svg>
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex justify-between">
                  <Dropdown
                    name="priority"
                    items={allPriority}
                    selected={formData.priority}
                    setSelected={(val) => {
                      setFormData({ ...formData, priority: val });
                    }}
                    align="top"
                  />
                </div>
                <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex justify-between">
                  <Dropdown
                    name="status"
                    items={allStat}
                    selected={formData.status}
                    setSelected={(val) => {
                      setFormData({ ...formData, status: val });
                    }}
                    align="top"
                  />
                </div>
                <div className="px-5 py-4 border-b border-slate-200 dark:border-slate-700 flex justify-between">
                  <button
                    type="button"
                    className="flex items-center btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap w-full"
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                  >
                    {ConfigProvider.getTranslation("Button.Save")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("ToContinuePleaseConfirm")}</p>
            </>
          }
          confirm={() => {
            updateSupportTicketStatus();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}

function Message({ text, date, byWhom }) {
  return (
    <>
      <div className="flex items-start mb-4 last:mb-0">
        {byWhom === 1 ? (
          <UserIcon className="min-w-[3rem] w-12 border  border-slate-200 dark:border-slate-700 rounded-full p-2 mr-4" />
        ) : (
          <IdentificationIcon className="min-w-[3rem] w-12 border  border-indigo-500 text-indigo-500 rounded-full p-2 mr-4" />
        )}
        <div>
          <div
            className={`"text-sm ${
              byWhom === 1
                ? "bg-white dark:bg-slate-800 text-slate-800 dark:text-slate-100 border border-slate-200 dark:border-slate-700"
                : "bg-indigo-500 text-white"
            } p-3 rounded-lg rounded-tl-none shadow-md mb-1"`}
          >
            {text}
          </div>
          <div className="flex items-center justify-between">
            <div className="text-xs text-slate-500 font-medium">{date}</div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditSupportTicket;

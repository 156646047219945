import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AuthImage from "../assets/img/auth-image.jpg";
import AuthDecoration from "../assets/img/auth-decoration.png";
import { Helmet } from "react-helmet";
import { useConfig } from "../context/ConfigContext";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import {
  ArrowRightCircleIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import Requests from "../helpers/Requests";
import Alert from "../components/Alert";
import { diagnosticResponse } from "../helpers/Helpers";

function TwoFA() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  const [barcodeImageUrl, setBarcodeImageUrl] = useState("");
  const [secret, setSecret] = useState("");
  useEffect(() => {
    if (ConfigProvider.config.user) {
      navigate("/");
    } else {
      Requests.postData("/user/checkGA", {})
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            navigate("/");
          } else {
            if (res.data !== null) {
              setBarcodeImageUrl(res.data.barcodeImageUrl);
              setSecret(res.data.secret);
            }
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    console.log("x");
  }, []);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ code: "" });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [messages, setMessages] = useState([]);
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/user/confirmGA", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleCopyClick = () => {
    navigator.clipboard.writeText(secret);
  };
  return (
    <>
      <Helmet>
        <title>{ConfigProvider.config.hostnames.name} Backoffice</title>
        <link rel="icon" href={ConfigProvider.config.hostnames.favicon} />
      </Helmet>
      <main className="bg-white dark:bg-slate-900">
        <div className="relative md:flex">
          {/* Content */}
          <div className="md:w-1/2">
            <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
              {/* Header */}
              <div className="flex-1">
                <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                  {/* Logo */}
                  <Link className="block" to="/">
                    <svg width="32" height="32" viewBox="0 0 32 32">
                      <defs>
                        <linearGradient
                          x1="28.538%"
                          y1="20.229%"
                          x2="100%"
                          y2="108.156%"
                          id="logo-a"
                        >
                          <stop
                            stopColor="#A5B4FC"
                            stopOpacity="0"
                            offset="0%"
                          />
                          <stop stopColor="#A5B4FC" offset="100%" />
                        </linearGradient>
                        <linearGradient
                          x1="88.638%"
                          y1="29.267%"
                          x2="22.42%"
                          y2="100%"
                          id="logo-b"
                        >
                          <stop
                            stopColor="#38BDF8"
                            stopOpacity="0"
                            offset="0%"
                          />
                          <stop stopColor="#38BDF8" offset="100%" />
                        </linearGradient>
                      </defs>
                      <rect fill="#6366F1" width="32" height="32" rx="16" />
                      <path
                        d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                        fill="#4F46E5"
                      />
                      <path
                        d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                        fill="url(#logo-a)"
                      />
                      <path
                        d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                        fill="url(#logo-b)"
                      />
                    </svg>
                  </Link>
                </div>
              </div>

              <div className="max-w-sm mx-auto w-full px-4 py-8">
                <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
                  {ConfigProvider.getTranslation("2FA")} ✨
                </h1>
                {/* Form */}
                <form onSubmit={updateForm} autoComplete="off">
                  <div className="mt-2 mb-4">
                    <Alert messages={messages} setMessages={setMessages} />
                  </div>
                  <div className="space-y-4">
                    {barcodeImageUrl.length > 0 && (
                      <div className="flex justify-center items-center ">
                        <img
                          srcSet={barcodeImageUrl}
                          alt=""
                          className="w-[300px] rounded-xl"
                        />
                      </div>
                    )}
                    {secret.length > 0 && (
                      <div className="flex items-center space-x-2">
                        <input
                          type="text"
                          value={secret}
                          className="form-input w-full"
                          readOnly
                          disabled
                        />
                        <button
                          type="button"
                          onClick={handleCopyClick}
                          className="form-input"
                        >
                          <ClipboardIcon className="w-4 h-4" />
                        </button>
                      </div>
                    )}
                    <div>
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="code"
                      >
                        {ConfigProvider.getTranslation("Code")}
                      </label>
                      <input
                        id="code"
                        name="code"
                        className="form-input w-full"
                        type="text"
                        value={formData.code}
                        maxLength="6"
                        onChange={handleChange}
                        required
                        disabled={loading}
                        pattern="^[0-9]+$"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <div className="mr-1">&nbsp;</div>
                    <button
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                      type="submit"
                      disabled={loading}
                    >
                      <span className="mr-2">
                        {ConfigProvider.getTranslation("Button.Send")}
                      </span>
                      {loading ? (
                        <span className="flex">
                          <LoadingSpinner className="w-4 h-4" />
                        </span>
                      ) : (
                        <span>
                          <ArrowRightCircleIcon className="w-4 h-4" />
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Image */}
          <div
            className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
            aria-hidden="true"
          >
            <img
              className="object-cover object-center w-full h-full"
              src={AuthImage}
              width="760"
              height="1024"
              alt="Authentication"
            />
            <img
              className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
              src={AuthDecoration}
              width="218"
              height="224"
              alt="Authentication decoration"
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default TwoFA;

import React from "react";
import CardLayout from "./helpers/CardLayout";
import ListBonus from "../helperCasino/ListBonus";
import { useParams } from "react-router-dom";
function ListBonusCard() {
  return (
    <CardLayout>
      <ListBonusCardPage />
    </CardLayout>
  );
}
function ListBonusCardPage() {
  const { _id } = useParams();
  return (
    <>
      <ListBonus selCard={_id} />
    </>
  );
}

export default ListBonusCard;

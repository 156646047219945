import React, { useEffect, useRef, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import Transition from "../context/TransitionContext";
import SearchFilter from "./SearchFilter";

function Dropdown({
  name,
  topItems = undefined,
  items,
  selected,
  setSelected,
  align = undefined,
  multi = false,
}) {
  const ConfigProvider = useConfig();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const [search, setSearch] = useState("");
  const [filterItems, setFilterItems] = useState([]);

  const [searchTimeout, setSearchTimeout] = useState(null);
  const handleFilter = () => {
    setFilterItems(
      Object.keys(items).filter((x) =>
        search.length > 0
          ? items[x].toLowerCase().includes(search.toLowerCase())
          : true
      )
    );
  };
  useEffect(() => {
    searchTimeout && window.clearTimeout(searchTimeout);

    if (items && Object.keys(items).length > 0) {
      setSearchTimeout(
        window.setTimeout(() => {
          handleFilter();
        }, 300)
      );
    }
    return () => {
      if (searchTimeout !== null) window.clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="relative inline-flex w-full">
      <button
        name={name}
        id={name}
        type="button"
        ref={trigger}
        className="btn w-full justify-between min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex grow items-center min-w-0 truncate">
          <span>
            {selected && selected.length > 0 ? (
              <>
                {multi && typeof selected === "object"
                  ? selected.map((sel, index) => (
                      <span key={index}>
                        {`${
                          topItems && topItems.hasOwnProperty(sel)
                            ? topItems[sel]
                            : items[sel]
                        }${index !== selected.length - 1 ? "," : ""} `}
                      </span>
                    ))
                  : topItems && topItems.hasOwnProperty(selected)
                  ? topItems[selected]
                  : items[selected]}
              </>
            ) : (
              ConfigProvider.getTranslation("Drowndown.SelectOne")
            )}
          </span>
        </span>
        <svg
          className="shrink-0 ml-1 fill-current text-slate-400"
          width="11"
          height="7"
          viewBox="0 0 11 7"
        >
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`z-20 absolute ${
          align === "top" ? "bottom-full mb-1" : "top-full mt-1"
        } left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden`}
        enter="transition ease-out duration-100 transform"
        enterStart={`opacity-0 ${
          align === "top" ? "translate-y-2" : "-translate-y-2"
        }`}
        enterEnd={`opacity-100 ${
          align === "top" ? "-translate-y-0" : "translate-y-0"
        }`}
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700 overflow-auto max-h-[210px] scroll-m-1"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {items && Object.keys(items).length > 10 && (
            <div className="w-full flex px-3 mb-2">
              <SearchFilter
                placeholder={ConfigProvider.getTranslation("Search")}
                search={search}
                setSearch={setSearch}
              />
            </div>
          )}
          {topItems &&
            Object.keys(topItems).map((key) => (
              <button
                key={key}
                tabIndex="0"
                className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                  selected &&
                  (multi && typeof selected === "object"
                    ? selected.includes(key)
                    : selected === key) &&
                  "text-indigo-500"
                }`}
                onClick={() => {
                  setSelected(key);
                  !multi && setDropdownOpen(false);
                }}
                type="button"
              >
                <span className="text-left">{topItems[key]}</span>
                <svg
                  className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                    (multi && typeof selected === "object"
                      ? !selected.includes(key)
                      : selected !== key) && "invisible"
                  }`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
              </button>
            ))}
          {filterItems.map((key) => {
            return (
              <button
                key={key}
                tabIndex="0"
                className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                  selected &&
                  (multi && typeof selected === "object"
                    ? selected.includes(key)
                    : selected === key) &&
                  "text-indigo-500"
                }`}
                onClick={() => {
                  setSelected(key);
                  !multi && setDropdownOpen(false);
                }}
                type="button"
              >
                <span className="text-left">{items[key]}</span>

                <svg
                  className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                    (multi && typeof selected === "object"
                      ? !selected.includes(key)
                      : selected !== key) && "invisible"
                  }`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}
export default Dropdown;

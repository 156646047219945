import React, { useRef, useState, useEffect } from "react";
import { useConfig } from "../../context/ConfigContext";
import Transition from "../../context/TransitionContext";
import SearchFilter from "../SearchFilter";
import Requests from "../../helpers/Requests";
import { diagnosticResponse } from "../../helpers/Helpers";
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/solid";

function DropdownCasinoGame({ name, setSelectedItem }) {
  const ConfigProvider = useConfig();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  const [items, setItems] = useState({});
  const [search, setSearch] = useState("");

  const getItems = () => {
    Requests.postData("/casino/searchCasinoGamesSelect", { q: search })
      .then((response) => {
        const res = response.data;
        diagnosticResponse(res) && res.type === "success" && setItems(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (search.length >= 3) {
      getItems();
    }
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="relative inline-flex grow md:max-w-[350px] min-w-0 ">
      <button
        name={name}
        id={name}
        type="button"
        ref={trigger}
        className="btn w-full truncate justify-between min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="flex grow items-center">
          <span>
            {selected ? (
              <>{selected.name}</>
            ) : (
              ConfigProvider.getTranslation("Game") +
              " | " +
              ConfigProvider.getTranslation("Filter.All")
            )}
          </span>
        </span>
        <svg
          className="shrink-0 ml-1 fill-current text-slate-400"
          width="11"
          height="7"
          viewBox="0 0 11 7"
        >
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="w-full flex px-3 mb-2">
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />
          </div>
          <div className="overflow-y-auto w-full max-h-[250px]">
            <div className="divide-y divide-slate-200 dark:divide-slate-700">
              <button
                tabIndex="0"
                className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                  selected === null && "text-indigo-500"
                }`}
                onClick={() => {
                  setSelected(null);
                  setSelectedItem(null);
                  setDropdownOpen(false);
                }}
                type="button"
              >
                <span className="text-left">
                  {ConfigProvider.getTranslation("Filter.All")}
                </span>
                <svg
                  className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                    selected !== null && "invisible"
                  }`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
              </button>
              {Object.keys(items).map((key) => {
                return (
                  <button
                    key={key}
                    tabIndex="0"
                    className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                      key === selected && selected.id && "text-indigo-500"
                    }`}
                    onClick={() => {
                      setSelected({ id: key, name: items[key].name });
                      setSelectedItem(key);
                      setDropdownOpen(false);
                    }}
                    type="button"
                  >
                    <div className="flex flex-col grow items-start justify-start text-left">
                      <div>{items[key].name}</div>
                      <div className="text-slate-400 dark:text-slate-400 text-xs flex space-x-1">
                        <span> {`${items[key].provider} | `}</span>
                        {items[key].desktop && (
                          <ComputerDesktopIcon className="w-4 h-4 text-slate-300 dark:text-slate-500" />
                        )}
                        {items[key].mobile && (
                          <DevicePhoneMobileIcon className="w-4 h-4 text-slate-300 dark:text-slate-500" />
                        )}
                      </div>
                    </div>
                    <svg
                      className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                        !(key === selected && selected.id) && "invisible"
                      }`}
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                    >
                      <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                    </svg>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownCasinoGame;

import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import PaginationClassic from "../../components/PaginationClassic";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TreeMain from "../../components/Tree/TreeMain";
import TreeMainNav from "../../components/Tree/TreeMainNav";

import ShopTooltip from "../../components/ShopTooltip";

function LimitShops() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SportsManageShopLimits")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 1,
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;

      Requests.postData("/sports/getLimitShops", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  return (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <div>
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}

            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (formData.page !== 1) {
                  setFormData((prev) => {
                    return { ...prev, page: 1 };
                  });
                } else {
                  getItems();
                }
              }}
              className=""
            >
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                    {`${ConfigProvider.getTranslation(
                      "Shop"
                    )} ${ConfigProvider.getTranslation("Limits")}`}
                    ✨
                  </h1>
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  <Link
                    to="/sports/limitShop/add"
                    className="btn bg-green-500 hover:bg-green-600 text-slate-100"
                  >
                    <svg
                      className="w-4 h-4 fill-slate-100 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">
                      {ConfigProvider.getTranslation("Add")}
                    </span>
                  </Link>
                </div>
              </div>
              {/* More actions */}
              <div className="lg:flex lg:justify-between lg:items-center mb-5">
                {/* Left side */}
                <div className="mb-4 lg:mb-0"></div>
                {/* Right side */}
                <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
                  <button
                    type="submit"
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  >
                    <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="ml-2">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                  </button>
                </div>
              </div>
            </form>
            {/* Table */}
            <LimitShopsTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
            </div>
          </div>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  );
}

function LimitShopsTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {`${ConfigProvider.getTranslation(
            "Shop"
          )} ${ConfigProvider.getTranslation("Limits")}`}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  {ConfigProvider.config.isShopSystem &&
                    (ConfigProvider.config.user.auth === "0") |
                      (ConfigProvider.config.user.auth === "1") && (
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Shop")}
                      </div>
                    )}
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Username")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-[300px]">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Class")}
                    </div>
                  </SortByItem>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="3">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan="3" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              )}
              {!loading &&
                data.map((item) => (
                  <tr key={item._id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                      {ConfigProvider.config.isShopSystem &&
                        (ConfigProvider.config.user.auth === "0") |
                          (ConfigProvider.config.user.auth === "1") && (
                          <ShopTooltip
                            size="lg"
                            position="right"
                            bg="dark"
                            shopName=""
                            shop={item.shop}
                            auth="2"
                          />
                        )}
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                        <Link
                          className="text-sky-500 dark:text-sky-400"
                          to={"/sports/limitShop/edit/" + item._id}
                        >
                          {item.uname}
                        </Link>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
                      <div className="font-medium text-slate-100 text-center">
                        {item.classname}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LimitShops;

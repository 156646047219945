import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../../context/TransitionContext";
import { usePrefences } from "../../context/PrefencesContext";
import { ListBulletIcon } from "@heroicons/react/24/solid";
function OffsetSwitch({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  const PrefencesProvider = usePrefences();
  const changeOffset = async (item) => {
    PrefencesProvider.savePrefences("offset", item);
    setDropdownOpen(false);
  };
  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ${
          dropdownOpen && "bg-slate-200"
        }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="text-[10px] font-bold text-slate-500 dark:text-slate-400">
          {PrefencesProvider.prefences.offset}
        </span>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === "right" ? "right-0" : "left-0"
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            {[3, 5, 10, 15, 20, 25, 50, 75, 100].map((key) => (
              <li key={key}>
                <Link
                  className={`font-medium text-sm hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3 ${
                    key === PrefencesProvider.prefences.offset
                      ? "bg-slate-100 dark:bg-slate-900 text-indigo-600 dark:text-indigo-400"
                      : "text-slate-500"
                  }`}
                  to="#0"
                  onClick={() => changeOffset(key)}
                >
                  <ListBulletIcon className="w-3 h-3 fill-current text-indigo-300 dark:text-indigo-500 shrink-0 mr-2" />
                  <span>{key}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default OffsetSwitch;

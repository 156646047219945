import React, { useState } from "react";
import { usePrefences } from "../../context/PrefencesContext";
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/react/24/outline";

function AudioToggle() {
  const PrefencesProvider = usePrefences();
  const [audio, setAudio] = useState(PrefencesProvider.prefences.audio);
  const changeAudio = async () => {
    let newVal = audio === 1 ? 0 : 1;
    PrefencesProvider.savePrefences("audio", newVal);
    setAudio(newVal);
  };
  return (
    <div>
      <input
        type="checkbox"
        name="audio-switch"
        id="audio-switch"
        className="audio-switch sr-only"
        checked={audio === 1}
        onChange={() => changeAudio()}
      />
      <label
        className="flex items-center justify-center cursor-pointer w-8 h-8 bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full"
        htmlFor="audio-switch"
      >
        {audio === 1 ? (
          <SpeakerWaveIcon className="w-4 h-4" />
        ) : (
          <SpeakerXMarkIcon className="w-4 h-4 text-red-500" />
        )}
      </label>
    </div>
  );
}

export default AudioToggle;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import Dropdown from "../../components/Dropdown";
import SearchFilter from "../../components/SearchFilter";

function UpsertFreespinTemplate() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkRole(
        ConfigProvider.config.user,
        "CasinoManageCasinoFreespinTemplates"
      )
    ) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    _id: _id ? _id : undefined,
    name: "",
    currency: "",
    count: "",
    betlevel: "1",
    expireDays: "",
    startDate: undefined,
    endDate: undefined,
    order: "",
    games: [],
    active: true,
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();

    let requiredFieldErros = [];
    if (formData.currency.length !== 3) {
      requiredFieldErros.push(ConfigProvider.getTranslation("Currency"));
    } else if (formData.games.length === 0) {
      requiredFieldErros.push(ConfigProvider.getTranslation("Games"));
    }

    if (requiredFieldErros.length > 0) {
      setMessages([
        {
          type: "error",
          text:
            "[" +
            requiredFieldErros.join(",") +
            "] " +
            ConfigProvider.getTranslation("Forms.EmptyValueError"),
        },
      ]);
      document.getElementById("mainBody").scrollTo(0, 0);
    } else if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/casino/updateCasinoFreespinTemplate", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);

            setTimeout(() => {
              navigate(`/helperCasino/freespinTemplates/list`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  useEffect(() => {
    Requests.postData("/casino/getFreespinTemplate", { _id: formData._id })
      .then((response) => {
        let res = response.data;
        if (res.type !== "success") {
          diagnosticResponse(res);
          ToastrContext.showToast({
            type: "error",
            text: res.message.length
              ? res.message
              : ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
        } else {
          let foundProvider = false;
          if (res.data.item !== null) {
            setFormData((prev) => {
              return {
                ...prev,
                name: res.data.item.name,
                currency: res.data.item.currency,
                count: res.data.item.count,
                betlevel: res.data.item.betlevel,
                expireDays: res.data.item.expireDays,
                startDate: res.data.item.startDate
                  ? res.data.item.startDate
                  : undefined,
                endDate: res.data.item.endDate
                  ? res.data.item.endDate
                  : undefined,
                order: res.data.item.order,
                games: res.data.item.games,
              };
            });
            if (res.data.item.games.length > 0) {
              let firstId = res.data.item.games.find((g) =>
                res.data.gameList.find((x) => x._id === g)
              );
              let findGame = res.data.gameList.find((x) => x._id === firstId);
              if (findGame) {
                setSelectProvider(findGame.provider);
                foundProvider = true;
              }
            }
          }
          if (!foundProvider) {
            if (Object.keys(res.data.providers).length > 0) {
              setSelectProvider(Object.keys(res.data.providers)[0]);
            }
          }
          setGameList(res.data.gameList);
          setProviders(res.data.providers);
        }
        setLoading(false);
      })
      .catch(() => {
        ToastrContext.showToast({
          type: "error",
          text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
        });
        document.getElementById("mainBody").scrollTo(0, 0);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const [gameList, setGameList] = useState([]);
  const [providers, setProviders] = useState({});
  const [searchGame, setSearchGame] = useState("");
  const [selectProvider, setSelectProvider] = useState(
    ConfigProvider.ObjectIdEmpty
  );
  return (
    <div className="grow">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("FreespinTemplate")}
        {" / "}
        {formData._id
          ? ConfigProvider.getTranslation("Edit")
          : ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("FreespinTemplate")}
          </h2>
          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Name")}
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Name")}
                  required
                  maxLength={50}
                  disabled={loading}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="currency"
                >
                  {ConfigProvider.getTranslation("Currency")}
                </label>
                <CurrencyDropdown
                  name="currency"
                  selected={formData.currency}
                  setSelected={(val) => {
                    setFormData((prev) => {
                      return { ...prev, currency: val };
                    });
                  }}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="count"
                >
                  {ConfigProvider.getTranslation("Count")}
                </label>
                <input
                  id="count"
                  name="count"
                  type="number"
                  value={formData.count}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Count")}
                  required
                  maxLength={10}
                  disabled={loading}
                  step={1}
                  pattern="\d*"
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="betlevel"
                >
                  {ConfigProvider.getTranslation("BetLevel")}
                </label>
                <input
                  id="betlevel"
                  name="betlevel"
                  type="number"
                  value={formData.betlevel}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("BetLevel")}
                  required
                  min={1}
                  step={1}
                  pattern="\d*"
                  disabled={loading}
                  maxLength={10}
                />
              </div>

              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="startDate"
                >
                  {ConfigProvider.getTranslation("StartDate")}
                </label>
                <input
                  id="startDate"
                  name="startDate"
                  type="datetime-local"
                  value={formData.startDate}
                  onChange={(e) => {
                    return setFormData({
                      ...formData,
                      startDate: e.target.value,
                    });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="endDate"
                >
                  {ConfigProvider.getTranslation("EndDate")}
                </label>

                <input
                  id="endDate"
                  name="endDate"
                  type="datetime-local"
                  value={formData.endDate}
                  onChange={(e) => {
                    return setFormData({
                      ...formData,
                      endDate: e.target.value,
                    });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="betlevel"
                >
                  {ConfigProvider.getTranslation("ExpireDays")}
                </label>
                <input
                  id="expireDays"
                  name="expireDays"
                  type="number"
                  value={formData.expireDays}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("ExpireDays")}
                  maxLength={10}
                  disabled={loading}
                  step={1}
                  pattern="\d*"
                  required
                  min={1}
                  max={100}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="order"
                >
                  {ConfigProvider.getTranslation("SortOrder")}
                </label>
                <input
                  id="order"
                  name="order"
                  type="number"
                  value={formData.order}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("SortOrder")}
                  maxLength={10}
                  disabled={loading}
                  step={1}
                  pattern="\d*"
                  required
                />
              </div>

              {formData._id !== null && (
                <div className="relative col-span-2">
                  <div className="flex items-center my-2">
                    <div className="form-switch">
                      <input
                        id="active"
                        name="active"
                        type="checkbox"
                        className="sr-only"
                        checked={formData.active}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            active: !formData.active,
                          })
                        }
                        disabled={loading}
                      />
                      <label
                        className="bg-slate-400 dark:bg-slate-700"
                        htmlFor="active"
                      >
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                      </label>
                    </div>
                    <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                      {formData.active
                        ? ConfigProvider.getTranslation("Active")
                        : ConfigProvider.getTranslation("Passive")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("Games")}
          </h2>
          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="selectProvider"
                >
                  {ConfigProvider.getTranslation("Provider")}
                </label>
                {providers && Object.keys(providers).length > 0 && (
                  <Dropdown
                    name="selectProvider"
                    items={providers}
                    selected={selectProvider}
                    setSelected={(val) => {
                      setSelectProvider(val);
                      setFormData((prev) => {
                        return { ...prev, games: [] };
                      });
                    }}
                  />
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="selectProvider"
                >
                  {ConfigProvider.getTranslation("Game")}
                </label>
                <SearchFilter
                  placeholder={ConfigProvider.getTranslation("Search")}
                  search={searchGame}
                  setSearch={setSearchGame}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              {gameList
                .filter(
                  (x) =>
                    (selectProvider === ConfigProvider.ObjectIdEmpty) |
                      (x.provider === selectProvider) &&
                    (searchGame === "") |
                      x.name.toLowerCase().includes(searchGame.toLowerCase())
                )
                .sort(
                  (a, b) =>
                    (formData.games.includes(a._id) ? 0 : 1) -
                    (formData.games.includes(b._id) ? 0 : 1)
                )
                .map((item, index) => (
                  <div
                    key={index}
                    className={`shadow-lg rounded-sm border px-5 py-4 cursor-pointer ${
                      formData.games.includes(item._id)
                        ? "bg-amber-50 dark:bg-amber-400/10 border-amber-300 dark:border-amber-400/50"
                        : "bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700"
                    }`}
                    onClick={() => {
                      setFormData((prev) => {
                        if (prev.games.includes(item._id)) {
                          return {
                            ...prev,
                            games: prev.games.filter((val) => val !== item._id),
                          };
                        } else {
                          return {
                            ...prev,
                            games: [...prev.games, item._id],
                          };
                        }
                      });
                    }}
                  >
                    <div className="md:flex justify-between items-center space-y-4 md:space-y-0 space-x-2">
                      {/* Left side */}
                      <div className="flex items-start space-x-3 md:space-x-4">
                        <div className="w-9 h-9 shrink-0 mt-1">
                          <input
                            type="checkbox"
                            className="form-checkbox w-6 h-6"
                            checked={formData.games.includes(item._id)}
                            onChange={() => {}}
                            disabled={loading}
                          />
                        </div>
                        <div>
                          <div className="inline-flex font-semibold text-slate-800 dark:text-slate-100">
                            {item.name}
                          </div>
                          <div className="text-sm">
                            {providers.hasOwnProperty(item.provider)
                              ? providers[item.provider]
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default UpsertFreespinTemplate;

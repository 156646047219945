import React from "react";
import { useConfig } from "../../context/ConfigContext";

function TransactionsTotals({ item }) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative mt-2 flex flex-col gap-2 px-3 py-2">
      {item.income && (
        <div className="text-emerald-500">{`${ConfigProvider.getTranslation(
          "Income"
        )}: ${item.income} ${item.currency}`}</div>
      )}
      {item.expense && (
        <div className="text-rose-500">{`${ConfigProvider.getTranslation(
          "Expense"
        )}: ${item.expense} ${item.currency}`}</div>
      )}
      {item.amount && (
        <div>{`${ConfigProvider.getTranslation("NET")}: ${item.amount} ${
          item.currency
        }`}</div>
      )}
    </div>
  );
}

export default TransactionsTotals;

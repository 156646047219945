import React from "react";
import { TypeIcon } from "./TypeIcon";
import styles from "./css/CustomNode.module.css";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useDragOver } from "@minoru/react-dnd-treeview";

export const CustomNode = (props) => {
  const indent = props.depth * 24;

  const handleToggle = (e) => {
    e.stopPropagation();
    if (props.onToggle) {
      props.onToggle(props.node.id);
    }
  };

  const handleSelect = () => props.onSelect && props.onSelect(props.node);
  const dragOverProps = useDragOver(
    props.node.id,
    props.isOpen,
    props.onToggle
  );

  return (
    <div
      className={`tree-node cursor-pointer items-center ${styles.root} ${
        props.isSelected ? styles.isSelected : ""
      }`}
      style={{ paddingInlineStart: indent }}
      onClick={handleSelect}
      {...dragOverProps}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ""
        }`}
      >
        {props.node.auth !== "2" && props.node.droppable && (
          <div onClick={handleToggle}>
            <ChevronRightIcon className="text-slate-500 dark:text-white w-4 h-4 shrink-0" />
          </div>
        )}
      </div>
      {props.enableCheckbox && (
        <div className="">
          <input
            type="checkbox"
            className="form-checkbox w-5 h-5 mr-2"
            checked={props.node.id === props.selectedNode}
            onChange={() => {}}
          />
        </div>
      )}
      <div>
        <TypeIcon auth={props.node.auth} stat={props.node.stat} />
      </div>
      <div className={styles.labelGridItem}>
        <h3>{props.node.text}</h3>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Dropdown from "../../components/Dropdown";
import { useToastr } from "../../context/ToastrContext";
function Translate() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CMSManageTranslation")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 0,
    lan: ConfigProvider.config.lan,
    type: "general",
    sid: "-1",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/cms/getTranslate", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  const allType = {
    general: ConfigProvider.getTranslation("General"),
    supportCategory: ConfigProvider.getTranslation("SupportCategory"),
    casinoPage: ConfigProvider.getTranslation("CasinoPages"),
    casinoPageHome: ConfigProvider.getTranslation("CasinoPagesHome"),
    casinoCategory: ConfigProvider.getTranslation("CasinoCategories"),
    menu: ConfigProvider.getTranslation("Menu"),
  };

  const allLanguages = ConfigProvider.config.languages;

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}

      {messages.length > 0 && (
        <div className="mt-2 mb-4">
          <Alert messages={messages} setMessages={setMessages} />
        </div>
      )}
      <form
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
      >
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("Translate")} ✨
            </h1>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 space-y-2 lg:grow lg:mb-0 lg:flex lg:space-x-2 lg:space-y-0">
            <div className="relative grow">
              <label className="block text-sm font-medium mb-1" htmlFor="type">
                {ConfigProvider.getTranslation("Type")}
              </label>
              <Dropdown
                name="type"
                items={allType}
                selected={formData.type}
                setSelected={(val) => {
                  setFormData({ ...formData, type: val });
                }}
              />
            </div>
            <div className="relative grow">
              <label className="block text-sm font-medium mb-1" htmlFor="lan">
                {ConfigProvider.getTranslation("Language")}
              </label>
              <Dropdown
                name="lan"
                items={allLanguages}
                selected={formData.lan}
                setSelected={(val) => {
                  setFormData({ ...formData, lan: val });
                }}
              />
            </div>

            <div className="relative grow flex items-end space-x-2 ">
              {/* Search form */}
              <SearchFilter
                placeholder={ConfigProvider.getTranslation("Search")}
                search={search}
                setSearch={setSearch}
              />
              <button
                type="submit"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
              </button>
            </div>
          </div>
          {/* Right side */}
        </div>
      </form>
      {/* Table */}
      <TranslateTable
        data={data}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
        setData={setData}
      />

      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}

function TranslateTable({ data, loading, formData, setFormData, setData }) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const translateElem = (id, translation) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/cms/translateElem", {
        id,
        val: translation,
        lan: formData.lan,
        type: formData.type,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Translate")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th
                  width="500"
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Key")}
                    </div>
                  </SortByItem>
                </th>

                <th
                  width="200"
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Source")} (don't translate
                    by value here, just informational)
                  </div>
                </th>
                <th
                  width="500"
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Value")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap max-w-[120px] min-w-[120px]">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Button.Save")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan="4" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              )}
              {!loading &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-pre-line">
                      <div className="text-left max-w-[400px] truncate">
                        {`${item.sport !== "" ? item.sport + " / " : ""} ${
                          item.name
                        }`}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-pre-line">
                      <div className="text-left max-w-[400px] truncate">
                        {item.hasOwnProperty("name") ? item.name : item.key}
                      </div>
                    </td>
                    <td
                      colSpan={2}
                      className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                    >
                      <form
                        className="flex space-x-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          translateElem(item.id, item.translation);
                        }}
                      >
                        <input
                          name="translation"
                          type="text"
                          value={item.translation}
                          className="w-full form-input"
                          disabled={loading}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedItems = prev.map((subItem) => {
                                if (subItem.id === item.id) {
                                  return {
                                    ...subItem,
                                    translation: e.target.value,
                                  };
                                }
                                return subItem;
                              });
                              return updatedItems;
                            });
                          }}
                        />
                        <button
                          type="submit"
                          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                        >
                          <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                          <span className="ml-2">
                            {ConfigProvider.getTranslation("Button.Save")}
                          </span>
                        </button>
                      </form>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default Translate;

import React, { useEffect, useRef, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { usePrefences } from "../context/PrefencesContext";
import { Link } from "react-router-dom";
import { classNames } from "../helpers/Helpers";
import Transition from "../context/TransitionContext";

function PaginationClassic({ page, setPage, totalItems }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const totalPage =
    parseInt(totalItems / PrefencesProvider.prefences.offset) +
    (totalItems % PrefencesProvider.prefences.offset === 0 ? 0 : 1);

  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav
        className="mb-4 sm:mb-0 sm:order-1"
        role="navigation"
        aria-label="Navigation"
      >
        <ul className="flex justify-center">
          <li className="ml-3 first:ml-0">
            <Link
              className={classNames(
                "btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700",
                page < 2
                  ? "text-slate-300 dark:text-slate-600 cursor-default"
                  : "hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
              )}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
            >
              &lt;
            </Link>
          </li>
          <li className="ml-3 first:ml-0">
            <PageDropdown
              totalPage={totalPage}
              selected={page}
              setSelected={(option) => {
                setPage(option);
              }}
            />
          </li>
          <li className="ml-3 first:ml-0">
            <Link
              className={classNames(
                "btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700",
                page >= totalPage
                  ? "text-slate-300 dark:text-slate-600 cursor-default"
                  : "hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
              )}
              onClick={() => {
                if (page < totalPage) {
                  setPage(page + 1);
                }
              }}
            >
              &gt;
            </Link>
          </li>
        </ul>
      </nav>
      <div className="text-sm text-slate-500 dark:text-slate-400 text-center sm:text-left">
        {ConfigProvider.getTranslation("Total")}
        {": "}
        <span className="font-medium text-slate-600 dark:text-slate-300">
          {totalItems}
        </span>
      </div>
      <div className="w-full h-[20px]">&nbsp;</div>
    </div>
  );
}
function PageDropdown({ totalPage, selected, setSelected }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let send = [];
    for (let i = 0; i < totalPage; i++) {
      send.push(i + 1);
    }
    setOptions(send);
  }, [totalPage]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex w-full ">
      <button
        ref={trigger}
        className="btn w-full justify-between min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        type="button"
      >
        <span className="flex items-center">
          <span>{selected}</span>
        </span>
        <svg
          className="shrink-0 ml-1 fill-current text-slate-400"
          width="11"
          height="7"
          viewBox="0 0 11 7"
        >
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10  absolute top-full left-0 w-full py-1.5  shadow-lg overflow-hidden"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm  bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300 rounded divide-y divide-slate-200 dark:divide-slate-700"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.map((option) => {
            return (
              <button
                key={option}
                tabIndex="0"
                className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${
                  option === selected && "text-indigo-500"
                }`}
                onClick={() => {
                  setSelected(option);
                  setDropdownOpen(false);
                }}
                type="button"
              >
                <span>{option}</span>
                <svg
                  className={`shrink-0 mr-2 fill-current text-indigo-500 ${
                    option !== selected && "invisible"
                  }`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
              </button>
            );
          })}
        </div>
        <div className="w-full h-[40px]">&nbsp;</div>
      </Transition>
    </div>
  );
}
export default PaginationClassic;

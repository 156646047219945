import React from "react";
import AccountLayout from "./helpers/AccountLayout";
import DashboardComponent from "../../components/DashboardComponent";
import { useParams } from "react-router-dom";
function ViewAccount() {
  return (
    <AccountLayout>
      <ViewAccountPage />
    </AccountLayout>
  );
}
function ViewAccountPage() {
  const { _id } = useParams();
  return (
    <div className="grow min-w-0 p-2">
      <DashboardComponent id={_id} />
    </div>
  );
}

export default ViewAccount;

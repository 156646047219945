import React from "react";
import AccountLayout from "./helpers/AccountLayout";
import { useParams } from "react-router-dom";
import CreditTransactions from "../finance/CreditTransactions";
function CreditTransactionsAccount() {
  return (
    <AccountLayout>
      <CreditTransactionsAccountPage />
    </AccountLayout>
  );
}
function CreditTransactionsAccountPage() {
  const { _id } = useParams();
  return (
    <>
      <CreditTransactions selAdmin={_id} />
    </>
  );
}

export default CreditTransactionsAccount;

import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon, CalendarIcon } from "@heroicons/react/24/solid";
import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  addDays,
  checkRole,
  classNames,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import Dropdown from "../../components/Dropdown";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Confirm from "../../components/Confirm";
function SettlementEvents() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SettlementEventSettlement")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -5), true, false),
    date2: formatDate(addDays(getDate(), 1), true, false),
    page: 1,
    sort: 0,
    sortBy: 1,
    sid: "-1",
    stat: "0",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/resulting/getResultEvents", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const [allSports, setAllSports] = useState({});
  useEffect(() => {
    if (Object.keys(allSports).length === 0) {
      getSports();
    }
    // eslint-disable-next-line
  }, []);
  const getSports = () => {
    Requests.postData("/sports/getAllSports", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          res.data["-1"] = ConfigProvider.getTranslation("Filter.All");
          setAllSports(res.data);
        }
      })
      .catch(() => {});
  };

  const allStat = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("Pending"),
    1: ConfigProvider.getTranslation("Resulted"),
    2: ConfigProvider.getTranslation("Return"),
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const syncItems = () => {
    if (!loading) {
      Requests.postData("/resulting/syncSettlementService", {})
        .then((response) => {
          const res = response.data;
          diagnosticResponse(res) && res.type === "success" && getItems();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("EventSettlement")} ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0">
            <Dropdown
              name="sid"
              items={allSports}
              selected={formData.sid}
              setSelected={(val) => {
                setFormData({ ...formData, sid: val });
              }}
            />
          </div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            <input
              id="date1"
              name="date1"
              type="datetime-local"
              value={formData.date1}
              onChange={(e) => {
                return setFormData({ ...formData, date1: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("StartDate")}
              disabled={loading}
              required
            />
            <input
              id="date2"
              name="date2"
              type="datetime-local"
              value={formData.date2}
              onChange={(e) => {
                return setFormData({ ...formData, date2: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("EndDate")}
              disabled={loading}
              required
            />
            <Dropdown
              name="stat"
              items={allStat}
              selected={formData.stat}
              setSelected={(val) => {
                setFormData({ ...formData, stat: val });
              }}
            />
            {checkRole(
              ConfigProvider.config.user,
              "SettlementEventSettlementSync"
            ) && (
              <button
                type="button"
                className="btn bg-rose-500 hover:bg-rose-600 text-white"
                onClick={() => setShowConfirm(true)}
              >
                <ArrowPathIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Sync")}
                </span>
              </button>
            )}
          </div>
        </div>

        {/* Table */}
        <SettlementEventsTable
          data={data}
          loading={loading}
          formData={formData}
          setFormData={setFormData}
        />

        {/* Pagination */}
        <div className="mt-8">
          <PaginationClassic
            page={formData.page}
            setPage={(val) => {
              setFormData((prev) => {
                return { ...prev, page: val };
              });
            }}
            totalItems={totalItems}
          />
        </div>
      </form>
      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("Sync")}</p>
            </>
          }
          confirm={() => {
            syncItems();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </>
  );
}
function SettlementEventsTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Events")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("StartDate")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Competition")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap max-w-[120px]">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sport")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {`${ConfigProvider.getTranslation(
                        "Country"
                      )} / ${ConfigProvider.getTranslation("League")}`}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="5">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan="5" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              )}
              {!loading &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="flex items-center justify-start w-full font-semibold text-slate-700 dark:text-slate-400">
                        <CalendarIcon className="w-4 h-4 mr-1" />
                        {new Intl.DateTimeFormat("default", {
                          day: "numeric",
                          month: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: PrefencesProvider.prefences.timezone,
                        }).format(new Date(item.eventdate))}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                        <Link
                          className="text-sky-500 dark:text-sky-400"
                          to={"/settlement/event/" + item.id}
                        >
                          {item.label}
                        </Link>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
                      <div className="text-center text-slate-100 font-semibold">
                        {item.sport}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-500 dark:text-slate-400 flex flex-col">
                        <div>{item.countryName}</div>
                        <div className="text-slate-700 dark:text-slate-500">
                          {item.leagueName}
                        </div>
                      </div>
                    </td>

                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={classNames(
                          "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
                          item.postponed
                            ? "bg-sky-100 dark:bg-sky-400/40 text-sky-600 dark:text-sky-400"
                            : item.isR
                            ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                            : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
                        )}
                      >
                        {item.postponed
                          ? ConfigProvider.getTranslation("Return")
                          : !item.isR
                          ? ConfigProvider.getTranslation("Pending")
                          : ConfigProvider.getTranslation("Resulted")}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SettlementEvents;

import React, { useEffect } from "react";
import { useConfig } from "../../../context/ConfigContext";
import AccountSidebar from "./AccountSidebar";
import AccountBanner from "./AccountBanner";

function AccountLayout({ children }) {
  const ConfigProvider = useConfig();

  useEffect(() => {
    if (ConfigProvider.refreshCustomData) {
      ConfigProvider.customData && ConfigProvider.setCustomData(null);
      ConfigProvider.setRefreshCustomData(false);
    }
    // eslint-disable-next-line
  }, [ConfigProvider.refreshCustomData]);

  return (
    <>
      <div className="mb-8">
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
          {ConfigProvider.getTranslation("Account")} ✨
        </h1>
      </div>

      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <AccountSidebar />
          <div className="grow min-w-0 p-2">
            {!ConfigProvider.refreshCustomData && (
              <AccountBanner
                callback={(val) => {
                  ConfigProvider.setCustomData(val);
                }}
              />
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountLayout;

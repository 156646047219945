import React, { createContext, useContext, useEffect } from "react";
import toastr from "toastr";

const ToastrContext = createContext();

export function ToastrProvider({ children }) {
  useEffect(() => {
    toastr.options = {
      positionClass: "toast-top-right",
      hideDuration: 300,
      timeOut: 2000,
      extendedTimeOut: 1000,
      closeButton: true,
    };
  }, []);
  const showToast = (item) => {
    if (item.type === "success") {
      toastr.success(item.text);
    } else {
      toastr.error(item.text);
    }
  };

  return (
    <ToastrContext.Provider value={{ showToast }}>
      {children}
    </ToastrContext.Provider>
  );
}

export function useToastr() {
  return useContext(ToastrContext);
}

import React from "react";
import AccountLayout from "./helpers/AccountLayout";
import { useParams } from "react-router-dom";
import Logs from "../Logs";

function LogsAccount() {
  return (
    <AccountLayout>
      <LogsAccountPage />
    </AccountLayout>
  );
}
function LogsAccountPage() {
  const { _id } = useParams();
  return (
    <>
      <Logs selAdmin={_id} />
    </>
  );
}

export default LogsAccount;

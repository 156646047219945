import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  addDays,
  checkRole,
  classNames,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import Confirm from "../../components/Confirm";
import Requests from "../../helpers/Requests";
import { useNavigate, useParams } from "react-router-dom";
import { useToastr } from "../../context/ToastrContext";
import RecentTransactions from "../../components/Finance/RecentTransactions";
import CardLayout from "./helpers/CardLayout";
import Dropdown from "../../components/Dropdown";
function FinanceCard() {
  return (
    <CardLayout>
      <FinanceCardPage />
    </CardLayout>
  );
}
function FinanceCardPage() {
  const { _id } = useParams();
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CardsBalance")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const ToastrContext = useToastr();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [formData, setFormData] = useState({
    _id: _id,
    amount: "",
    add: "1",
    note: "",
    type: "0",
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const updateCard = (e) => {
    e.preventDefault();
    if (!showConfirm) {
      setShowConfirm(true);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount" && value.length > 0 && !/^\d*\.?\d*$/.test(value)) {
      return false;
    }
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const updateForm = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);

      Requests.postData("/cards/updateBalance", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            ConfigProvider.setRefreshCustomData(true);
            setFormData({
              ...formData,
              amount: "",
              add: "1",
              note: "",
              type: "0",
            });
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const [data, setData] = useState(null);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/cards/getCardDashboard", {
        _id: _id,
        type: 1,
        date1: formatDate(addDays(getDate(), -10)),
        date2: formatDate(addDays(getDate(), 1)),
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  const typesList = {
    0: ConfigProvider.getTranslation("Credit"),
    1: ConfigProvider.getTranslation("Discount"),
    2: ConfigProvider.getTranslation("NoWageringBonus"),
    3: ConfigProvider.getTranslation("NoWageringCash"),
  };
  return (
    <>
      <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
        <header className="px-5 py-4 border-b bg-slate-100 dark:bg-slate-700 border-slate-200 dark:border-slate-700">
          <h2 className="font-semibold text-slate-800 dark:text-slate-100">
            {`${ConfigProvider.getTranslation("Balance")}: ${
              data && data.convertedBalance
            } ${data && data.curSession}`}
          </h2>
        </header>

        <form className="w-full p-3" onSubmit={updateCard} autoComplete="off">
          {/* Panel body */}
          <div className="p-6 space-y-6">
            <div className="w-full mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative col-span-full">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="amount"
                >
                  {ConfigProvider.getTranslation("Amount")}
                </label>
                <input
                  id="amount"
                  name="amount"
                  type="text"
                  value={formData.amount}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Amount")}
                  required
                  maxLength={20}
                  disabled={loading | showConfirm}
                />

                <ul className="flex flex-wrap mt-5 w-full justify-end">
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.add === "0"
                          ? "border-transparent bg-rose-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, add: "0" });
                      }}
                    >
                      {ConfigProvider.getTranslation("Balance.Decrease")} (-)
                    </button>
                  </li>

                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.add === "1"
                          ? "border-transparent bg-emerald-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, add: "1" });
                      }}
                    >
                      {ConfigProvider.getTranslation("Balance.Increase")} (+)
                    </button>
                  </li>
                </ul>
              </div>
              {ConfigProvider.config.addBonus && (
                <div className="relative col-span-full">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="type"
                  >
                    {ConfigProvider.getTranslation("Type")}
                  </label>
                  <Dropdown
                    name="type"
                    items={typesList}
                    selected={formData.type}
                    setSelected={(val) => {
                      setFormData({ ...formData, type: val });
                    }}
                  />
                </div>
              )}
              <div className="relative col-span-full">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="note"
                >
                  {ConfigProvider.getTranslation("Note")}
                </label>
                <input
                  id="note"
                  name="note"
                  type="text"
                  value={formData.note}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Note")}
                  maxLength={255}
                  disabled={loading | showConfirm}
                />
              </div>
            </div>
          </div>
          {/* Panel footer */}
          <footer>
            <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
              <div className="flex self-end">
                <button
                  type="submit"
                  disabled={loading | showConfirm}
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                >
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightCircleIcon className="w-4 h-4" />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </footer>
        </form>

        {showConfirm && (
          <Confirm
            title={ConfigProvider.getTranslation("AreYouSure")}
            text={
              <>
                <p>{`${ConfigProvider.getTranslation("Amount")}: ${parseFloat(
                  formData.amount
                ).toFixed(2)} ${ConfigProvider.customData.curSession}`}</p>
                <p>
                  {`${ConfigProvider.getTranslation("Type")}: `}
                  {formData.add === "1" ? (
                    <span className="text-emerald-500 font-semibold">
                      {ConfigProvider.getTranslation("Balance.Increase")}
                    </span>
                  ) : (
                    <span className="text-rose-500 font-semibold">
                      {ConfigProvider.getTranslation("Balance.Decrease")}
                    </span>
                  )}
                </p>
                {ConfigProvider.config.addBonus && (
                  <p>{`${ConfigProvider.getTranslation("Type")}: ${
                    typesList[formData.type]
                  }`}</p>
                )}
                <p>{`${ConfigProvider.getTranslation("Note")}: ${
                  formData.note
                }`}</p>
              </>
            }
            confirm={() => {
              updateForm();
            }}
            callback={() => {
              setShowConfirm(false);
            }}
          />
        )}
      </div>

      <div className="col-span-full mt-2">
        <RecentTransactions
          data={data ? data.balanceTransactions : undefined}
        />
      </div>
    </>
  );
}

export default FinanceCard;

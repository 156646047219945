import React from "react";
import GroupIcon from "../../assets/img/tree/resellerActiveSmall.svg";
import GroupPassiveIcon from "../../assets/img/tree/resellerPassiveSmall.svg";
import ShopIcon from "../../assets/img/tree/shopActiveSmall.svg";
import ShopPassiveIcon from "../../assets/img/tree/shopPassiveSmall.svg";

export const TypeIcon = (props) => {
  switch (props.auth) {
    case "1":
      return (
        <img
          srcSet={props.stat === 1 ? GroupIcon : GroupPassiveIcon}
          className={
            props.className ? props.className : "text-white w-6 h-6 shrink-0"
          }
          alt="Reseller"
          style={{ minWidth: "1.5rem", minHeight: "1.5rem" }}
        />
      );
    case "2":
      return (
        <img
          srcSet={props.stat === 1 ? ShopIcon : ShopPassiveIcon}
          className={
            props.className ? props.className : "text-white w-6 h-6 shrink-0"
          }
          alt="Shop"
          style={{ minWidth: "1.5rem", minHeight: "1.5rem" }}
        />
      );
    default:
      return null;
  }
};

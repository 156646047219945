import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon, KeyIcon } from "@heroicons/react/24/solid";
import { TypeIcon } from "../../components/Tree/TypeIcon";
import Dropdown from "../../components/Dropdown";

function UpsertRuleset() {
  const ConfigProvider = useConfig();
  const { _id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SiteManageRulesets")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    auth: "1",
    name: "",
    isDefault: false,
    roles: {},
    subRoles: [],
    _id: _id ? _id : null,
  });
  const [messages, setMessages] = useState([]);
  const [subRoles, setSubRoles] = useState({});
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);

      Requests.postData("/administration/updateUserRole", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
            setTimeout(() => {
              navigate(`/system/rulesets`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  useEffect(() => {
    if (allRules.length === 0) {
      setSubRoles({});
      Requests.postData("/administration/getAllRules", {})
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res) &&
            res.type === "success" &&
            setAllRules(res.data);
          Object.keys(res.subRoles).forEach((item) => {
            if (item !== _id) {
              setSubRoles((prev) => {
                return { ...prev, [item]: res.subRoles[item] };
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formData._id !== null) {
      Requests.postData("/administration/getRuleRoles", { _id: formData._id })
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res) &&
            res.type === "success" &&
            setFormData((prev) => {
              return {
                ...prev,
                roles: res.data.roles,
                subRoles: res.data.subRoles,
                auth: res.data.auth,
                name: res.data.name,
                isDefault: res.data.isDefault,
              };
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, []);

  const [allRules, setAllRules] = useState([]);

  const [mappedRules, setMappedRules] = useState({});
  const renderRules = () => {
    const groupBy = (array, key) => {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    };
    setMappedRules(
      groupBy(
        allRules.filter((x) => x.auth.includes(formData.auth)),
        "group"
      )
    );
  };
  useEffect(() => {
    renderRules();
    // eslint-disable-next-line
  }, [formData.auth, allRules]);
  return (
    <div className="grow max-w-3xl mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("Ruleset")}
        {" / "}
        {formData._id
          ? ConfigProvider.getTranslation("Edit")
          : ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Name")}
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Name")}
                  required
                  maxLength={20}
                  disabled={loading}
                />
              </div>

              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Role")}
                </label>
                <div className="flex w-full flex-wrap -space-x-px">
                  <button
                    type="button"
                    className={`inline-flex w-full sm:w-1/3 justify-center items-center border text-sm font-semibold p-2  border-slate-200 dark:border-slate-600 rounded-none sm:first:rounded-l last:sm:rounded-r ${
                      formData.auth === "0"
                        ? "text-white dark:text-slate:200 bg-indigo-500"
                        : "bg-slate-50 dark:bg-slate-800 hover:bg-slate-50  dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300"
                    }`}
                    onClick={() =>
                      !loading && setFormData({ ...formData, auth: "0" })
                    }
                    disabled={loading | (formData._id !== null)}
                  >
                    <span className="mr-2">
                      <KeyIcon className="w-5 h-5" />
                    </span>
                    {ConfigProvider.getTranslation("Admin")}
                  </button>
                  <button
                    type="button"
                    className={`inline-flex w-full sm:w-1/3 justify-center items-center border text-sm font-semibold p-2  border-slate-200 dark:border-slate-600 rounded-none sm:first:rounded-l sm:last:rounded-r ${
                      formData.auth === "1"
                        ? "text-white dark:text-slate:200 bg-indigo-500"
                        : "bg-slate-50 dark:bg-slate-800 hover:bg-slate-50  dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300"
                    }`}
                    onClick={() =>
                      !loading && setFormData({ ...formData, auth: "1" })
                    }
                    disabled={loading | (formData._id !== null)}
                  >
                    <span className="mr-2">
                      <TypeIcon auth="1" stat={1} />
                    </span>
                    {ConfigProvider.getTranslation("Role.Reseller")}
                  </button>
                  <button
                    type="button"
                    className={`inline-flex w-full sm:w-1/3 justify-center items-center border text-sm font-semibold p-2  border-slate-200 dark:border-slate-600 rounded-none sm:first:rounded-l sm:last:rounded-r ${
                      formData.auth === "2"
                        ? "text-white dark:text-slate:200 bg-indigo-500"
                        : "bg-slate-50 dark:bg-slate-800 hover:bg-slate-50  dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300"
                    }`}
                    onClick={() =>
                      !loading && setFormData({ ...formData, auth: "2" })
                    }
                    disabled={loading | (formData._id !== null)}
                  >
                    <span className="mr-2">
                      <TypeIcon auth="2" stat={1} />
                    </span>
                    {ConfigProvider.getTranslation("Role.Shop")}
                  </button>
                </div>
              </div>
              <div className="relative">
                <div className="flex items-center gap-3">
                  <div className="form-switch">
                    <input
                      id="isDefault"
                      name="isDefault"
                      type="checkbox"
                      className="sr-only"
                      checked={formData.isDefault}
                      onChange={() =>
                        !loading &&
                        setFormData({
                          ...formData,
                          isDefault: formData.isDefault ? false : true,
                        })
                      }
                      disabled={loading}
                    />
                    <label
                      className="bg-slate-400 dark:bg-slate-700"
                      htmlFor="isDefault"
                    >
                      <span
                        className="bg-white shadow-sm"
                        aria-hidden="true"
                      ></span>
                    </label>
                  </div>
                  <label
                    className="block text-sm font-medium"
                    htmlFor="isDefault"
                  >
                    {ConfigProvider.getTranslation("Default")}
                  </label>
                </div>
              </div>
              {formData.auth === "0" && Object.keys(subRoles).length ? (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="lan"
                  >
                    {ConfigProvider.getTranslation("AuthedRulesets")}
                  </label>
                  <Dropdown
                    name="subRule"
                    items={subRoles}
                    selected={formData.subRoles}
                    setSelected={(item) =>
                      setFormData((prev) => {
                        if (prev.subRoles.includes(item)) {
                          return {
                            ...prev,
                            subRoles: prev.subRoles.filter(
                              (val) => val !== item
                            ),
                          };
                        } else {
                          return {
                            ...prev,
                            subRoles: [...prev.subRoles, item],
                          };
                        }
                      })
                    }
                    multi={true}
                  />
                </div>
              ) : null}
            </div>
          </section>
          <section>
            <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
              {ConfigProvider.getTranslation("Rules")}
            </h2>

            <div className="mt-5">
              <div className="sm:grid sm:grid-cols-2  space-y-4 sm:space-y-0 gap-4 mt-5">
                <div>
                  {Object.keys(mappedRules).map(
                    (group, index) =>
                      index % 2 === 0 && (
                        <RuleGroupRender
                          group={group}
                          key={index}
                          mappedRules={mappedRules}
                          formData={formData}
                          setFormData={setFormData}
                          loading={loading}
                        />
                      )
                  )}
                </div>
                <div>
                  {Object.keys(mappedRules).map(
                    (group, index) =>
                      index % 2 === 1 && (
                        <RuleGroupRender
                          group={group}
                          key={index}
                          mappedRules={mappedRules}
                          formData={formData}
                          setFormData={setFormData}
                          loading={loading}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex self-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}
function RuleGroupRender({
  group,
  mappedRules,
  formData,
  setFormData,
  loading,
}) {
  const ConfigProvider = useConfig();
  return (
    <div className="px-2 py-4">
      <h3 className="font-semibold text-slate-700 dark:text-slate-400 border-slate-300 dark:border-slate-800 pb-2 border-b">
        <span className="pl-2">{ConfigProvider.getTranslation(group)}</span>
      </h3>

      <div className="px-1 py-2 w-full">
        {mappedRules[group].map((item, index2) => (
          <div key={index2} className="m-3">
            <label htmlFor={item.id} className="flex items-center">
              <input
                id={item.id}
                name={item.id}
                type="checkbox"
                checked={formData.roles.hasOwnProperty(item.id)}
                onChange={() => {
                  if (formData.roles.hasOwnProperty(item.id)) {
                    !loading &&
                      setFormData((prev) => {
                        const { [item.id]: _, ...newState } = prev.roles;
                        return { ...formData, roles: newState };
                      });
                  } else {
                    !loading &&
                      setFormData((prev) => {
                        return {
                          ...prev,
                          roles: {
                            ...prev.roles,
                            [item.id]: item.id,
                          },
                        };
                      });
                  }
                }}
                className="form-checkbox"
                disabled={loading}
              />
              <span className="text-sm ml-2 select-none">
                {ConfigProvider.getTranslation(item.val)}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UpsertRuleset;

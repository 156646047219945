import React, { createContext, useEffect, useContext, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";

const SocketContext = createContext();

export function SocketProvider({ children }) {
  const [error, setError] = useState(false);
  const [connection, setConnection] = useState(null);
  const [connected, setConnected] = useState(false);

  const [userQueue, setUserQueue] = useState([]);
  const [paymentTransactionsQueue, setPaymentTransactionsQueue] = useState([]);

  const clearUserMessages = (l) => {
    setUserQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearPaymentTransactionsMessages = (l) => {
    setPaymentTransactionsQueue((prev) => (prev.length === l ? [] : prev));
  };

  useEffect(() => {
    let url = "/stakeHub";
    const newConnection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    newConnection.on("user", (cmd, message) => {
      setUserQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify({ cmd: cmd, message: message })),
      ]);
    });
    newConnection.on("paymentTransaction", (cmd, message) => {
      setPaymentTransactionsQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify({ cmd: cmd, message: message })),
      ]);
    });

    newConnection.onreconnecting(() => {
      setConnected(false);
    });

    newConnection.onclose(() => {
      setConnected(false);
      setError(true);
    });

    newConnection.onreconnected(() => {
      setConnected(true);
    });
    setConnection(newConnection);

    return () => {
      setConnected(false);
    };
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          setConnected(true);
        })
        .catch((error) => {
          setConnected(false);
        });
    }
  }, [connection]);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (connected) {
      if (userId !== "") {
        subscribe(["user-" + userId]);
      }
    }
    // eslint-disable-next-line
  }, [connected]);

  const subscribe = (ids) => {
    if (connection && connection.state === "Connected") {
      connection.invoke("subscribe", ids);
    }
  };
  const unsubscribe = async (ids) => {
    if (connection && connection.state === "Connected") {
      await connection.invoke("unsubscribe", ids);
    }
  };

  const defineUserId = (id) => {
    setUserId(id);
  };
  return (
    <SocketContext.Provider
      value={{
        connection,
        error,
        connected,
        subscribe,
        unsubscribe,
        userQueue,
        clearUserMessages,
        paymentTransactionsQueue,
        clearPaymentTransactionsMessages,
        defineUserId,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
export const useSocket = () => {
  return useContext(SocketContext);
};

import React from "react";
import { useConfig } from "../context/ConfigContext";

function SearchFilter({
  placeholder = "",
  search,
  setSearch,
  disabled = false,
}) {
  const ConfigProvider = useConfig();
  return (
    <div className="relative w-full">
      <label htmlFor="action-search" className="sr-only">
        {ConfigProvider.getTranslation("Search")}
      </label>
      <input
        id="action-search"
        className="form-input w-full bg-white dark:bg-slate-800"
        style={{ paddingLeft: "35px" }}
        type="search"
        placeholder={
          placeholder.length > 0
            ? placeholder
            : ConfigProvider.getTranslation("Search") + ".."
        }
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        disabled={disabled}
      />
      <button className="absolute inset-0 right-auto group" type="button">
        <svg
          className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
          <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
        </svg>
      </button>
    </div>
  );
}

export default SearchFilter;

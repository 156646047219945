import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import DropdownFilter from "../../components/DropdownFilter";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useToastr } from "../../context/ToastrContext";
import ImgUpload from "../../components/ImgUpload";
import { XMarkIcon } from "@heroicons/react/24/outline";

function PaymentsList() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "PaymentMethodsManage")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 0,
    status: "1",
    type: "-1",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/paymentMethods/getPaymentMethods", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const allStat = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    1: ConfigProvider.getTranslation("Active"),
    0: ConfigProvider.getTranslation("Passive"),
  };
  const allTypes = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    0: ConfigProvider.getTranslation("Deposit"),
    1: ConfigProvider.getTranslation("Withdrawal"),
  };

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <div className="">
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (formData.page !== 1) {
              setFormData((prev) => {
                return { ...prev, page: 1 };
              });
            } else {
              getItems();
            }
          }}
          className=""
        >
          {/* Page header */}
          <div className="sm:flex sm:justify-between sm:items-center mb-5">
            {/* Left: Title */}
            <div className="mb-4 sm:mb-0">
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                {ConfigProvider.getTranslation("PaymentMethods")} ✨
              </h1>
            </div>

            {/* Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              <Link
                to="/payments/add"
                className="btn bg-green-500 hover:bg-green-600 text-slate-100"
              >
                <svg
                  className="w-4 h-4 fill-slate-100 shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">
                  {ConfigProvider.getTranslation("Add")}
                </span>
              </Link>
            </div>
          </div>
          {/* More actions */}
          <div className="lg:flex lg:justify-between lg:items-center mb-5">
            {/* Left side */}
            <div className="mb-4 lg:mb-0"></div>
            {/* Right side */}
            <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
              {/* Search form */}
              <SearchFilter
                placeholder={ConfigProvider.getTranslation("Search")}
                search={search}
                setSearch={setSearch}
              />
              <DropdownFilter align="right">
                <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                  {ConfigProvider.getTranslation("Type")}
                </div>
                <ul className="mb-4">
                  {Object.keys(allTypes).map((item) => (
                    <li key={item} className="py-1 px-3">
                      <label className="flex items-center cursor-pointer">
                        <input
                          id={item}
                          name={item}
                          type="checkbox"
                          className="form-checkbox"
                          checked={formData.type === item}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              type: item,
                            })
                          }
                          disabled={loading}
                        />
                        <span className="text-sm font-medium ml-2">
                          {allTypes[item]}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
                <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                  {ConfigProvider.getTranslation("Status")}
                </div>
                <ul className="mb-4">
                  {Object.keys(allStat).map((item) => (
                    <li key={item} className="py-1 px-3">
                      <label className="flex items-center cursor-pointer">
                        <input
                          id={item}
                          name={item}
                          type="checkbox"
                          className="form-checkbox"
                          checked={formData.status === item}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              status: item,
                            })
                          }
                          disabled={loading}
                        />
                        <span className="text-sm font-medium ml-2">
                          {allStat[item]}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </DropdownFilter>
              <button
                type="submit"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
              </button>
            </div>
          </div>
        </form>
        {/* Table */}
        <PaymentsListTable
          data={data}
          loading={loading}
          formData={formData}
          setFormData={setFormData}
          getItems={getItems}
        />

        {/* Pagination */}
        <div className="mt-8">
          <PaginationClassic
            page={formData.page}
            setPage={(val) => {
              setFormData((prev) => {
                return { ...prev, page: val };
              });
            }}
            totalItems={totalItems}
          />
        </div>
      </div>
    </>
  );
}
function PaymentsListTable({ data, loading, formData, setFormData, getItems }) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;

  const [imageSelectedId, setImageSelectedId] = useState(null);
  const [clickUpload, setClickUpload] = useState(false);
  const handleImageClick = (id) => {
    setImageSelectedId(id);
    setClickUpload(true);
  };
  const uploadFileSuccess = (name) => {
    if (!confirmLoading) {
      Requests.postData("/paymentMethods/updatePaymentMethodImg", {
        _id: imageSelectedId,
        name,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const [confirmLoading, setConfirmLoading] = useState(false);

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("PaymentMethods")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Icon")}
                  </div>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Name")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Type")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("SortOrder")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Deposit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Withdrawal")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                </th>
                <th className="first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
              </tr>
            </thead>

            {!loading && data && data.length === 0 && (
              <tbody className="text-sm">
                <tr>
                  <td colSpan="8">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm">
                <tr>
                  <td colSpan="8" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <PaymentsListTableItem
                  key={item._id}
                  item={item}
                  cdnEndpoint={cdnEndpoint}
                  handleImageClick={handleImageClick}
                />
              ))}
          </table>
        </div>
      </div>
      <ImgUpload
        loading={loading}
        path="paymentMethods"
        callback={(val) => {
          uploadFileSuccess(val);
        }}
        clickUpload={clickUpload}
        setClickUpload={setClickUpload}
      />
    </div>
  );
}
function PaymentsListTableItem({ item, cdnEndpoint, handleImageClick }) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/paymentMethods/getPaymentMethodLimits", {
        _id: item._id,
      })
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res) &&
            res.type === "success" &&
            setData(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (descriptionOpen && data.length === 0) {
      getItems();
    }
    // eslint-disable-next-line
  }, [descriptionOpen]);

  const removePaymentMethodsLimit = (subItem) => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/paymentMethods/removePaymentMethodsLimit", {
        _id: subItem._id,
      })
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res);
          if (res.type !== "success") {
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
            const updatedArray = data.filter((x) => x !== subItem);
            setData(updatedArray);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const [formData, setFormData] = useState({
    currency: "",
    max: "",
    min: "",
    method: item._id,
    type: 0,
    step: 0,
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const updatePaymentMethodsLimit = () => {
    if (!loading) {
      setLoading(true);

      Requests.postData("/paymentMethods/updatePaymentMethodsLimit", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessageMessage"),
            });
            setFormData({
              ...formData,
              currency: "",
              max: "",
              min: "",
              method: item._id,
              type: 0,
            });
            getItems();
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setLoading(false);
        });
    }
  };
  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link
            className="text-left inline-block bg-white p-2 rounded text-slate-700"
            onClick={() => {
              handleImageClick(item._id);
            }}
          >
            <img
              srcSet={cdnEndpoint + "/upload/img/paymentMethods/" + item.icon}
              alt="No IMG"
              className="min-w-[100px]"
            />
          </Link>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
            <Link
              className="text-sky-500 dark:text-sky-400"
              to={"/payments/edit/" + item._id}
            >
              {item.name}
            </Link>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
            {item.auto ? "Auto" : "Manual"}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{item.order}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={classNames(
              "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
              item.deposit
                ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            )}
          >
            {item.deposit
              ? ConfigProvider.getTranslation("Active")
              : ConfigProvider.getTranslation("Passive")}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={classNames(
              "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
              item.withdrawal
                ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            )}
          >
            {item.withdrawal
              ? ConfigProvider.getTranslation("Active")
              : ConfigProvider.getTranslation("Passive")}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className={classNames(
              "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
              item.active
                ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            )}
          >
            {item.active
              ? ConfigProvider.getTranslation("Active")
              : ConfigProvider.getTranslation("Passive")}
          </div>
        </td>
        <td className="first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              type="button"
              className={`text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              aria-controls={`description-${item.id}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item.id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="8"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <form
              className="w-full"
              onSubmit={(e) => {
                e.preventDefault();
                updatePaymentMethodsLimit();
              }}
            >
              <div className="flex flex-col items-center bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
                <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                  <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
                    <tr>
                      <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-48">
                        <div className="grow font-semibold text-left">
                          {ConfigProvider.getTranslation("Type")}
                        </div>
                      </th>

                      <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-24">
                        <div className="grow font-semibold text-center">
                          {ConfigProvider.getTranslation("Currency")}
                        </div>
                      </th>
                      <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-48">
                        <div className="grow font-semibold text-center">
                          {ConfigProvider.getTranslation("Minumum")}
                        </div>
                      </th>
                      <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-48">
                        <div className="grow font-semibold text-center">
                          {ConfigProvider.getTranslation("Maximum")}
                        </div>
                      </th>
                      <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-48">
                        <div className="grow font-semibold text-center">
                          {ConfigProvider.getTranslation("Step")}
                        </div>
                      </th>
                      <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="grow font-semibold text-center">
                          &nbsp;
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {!loading && data && data.length === 0 && (
                    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                      <tr>
                        <td colSpan="6">
                          <NoData />
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {loading && (
                    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                      <tr>
                        <td colSpan="6" className="text-center py-10">
                          <LoadingSpinner className="w-12 h-12" />
                        </td>
                      </tr>
                    </tbody>
                  )}
                  {!loading && (
                    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                      {data.map((item) => (
                        <tr key={item._id}>
                          <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-left">
                              {item.type === 0
                                ? ConfigProvider.getTranslation("Deposit")
                                : ConfigProvider.getTranslation("Withdrawal")}
                            </div>
                          </td>
                          <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-center">{item.currency}</div>
                          </td>
                          <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-center">{item.min}</div>
                          </td>
                          <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-center">{item.max}</div>
                          </td>
                          <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-center">{item.step}</div>
                          </td>
                          <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="text-center">
                              <Link
                                onClick={() => {
                                  removePaymentMethodsLimit(item);
                                }}
                              >
                                <XMarkIcon className="w-4 h-4 text-red-500" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                  {!loading && (
                    <tfoot className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                      <tr>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <select
                            className="btn w-full bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
                            value={formData.type}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                type: e.target.value,
                              });
                            }}
                            required
                          >
                            <option value={0}>
                              {ConfigProvider.getTranslation("Deposit")}
                            </option>
                            <option value={1}>
                              {ConfigProvider.getTranslation("Withdrawal")}
                            </option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="btn w-full min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
                            value={formData.currency}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                currency: e.target.value,
                              });
                            }}
                            required
                          >
                            <option value="">
                              {ConfigProvider.getTranslation(
                                "Drowndown.SelectOne"
                              )}
                            </option>
                            {Object.keys(ConfigProvider.config.currencies).map(
                              (cur) => (
                                <option key={cur} value={cur}>
                                  {cur.toUpperCase()}
                                </option>
                              )
                            )}
                          </select>
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <input
                            id="min"
                            name="min"
                            type="number"
                            value={formData.min}
                            onChange={handleChange}
                            className="w-full form-input"
                            placeholder={ConfigProvider.getTranslation(
                              "Minumum"
                            )}
                            required
                            disabled={loading}
                            step={1}
                          />
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <input
                            id="max"
                            name="max"
                            type="number"
                            value={formData.max}
                            onChange={handleChange}
                            className="w-full form-input"
                            placeholder={ConfigProvider.getTranslation(
                              "Maximum"
                            )}
                            required
                            disabled={loading}
                            step={1}
                          />
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <input
                            id="step"
                            name="step"
                            type="number"
                            value={formData.step}
                            onChange={handleChange}
                            className="w-full form-input"
                            placeholder={ConfigProvider.getTranslation("Step")}
                            required
                            disabled={loading}
                            step={1}
                          />
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <button
                            className="btn bg-green-500 hover:bg-green-600 text-slate-100"
                            type="submit"
                          >
                            <svg
                              className="w-4 h-4 fill-slate-100 shrink-0"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
            </form>
          </td>
        </tr>
      )}
    </tbody>
  );
}
export default PaymentsList;

import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import {
  addDays,
  checkRole,
  classNames,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import DropdownCard from "../../components/Search/DropdownCard";
import Dropdown from "../../components/Dropdown";

function PaymentsReport() {
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkRole(ConfigProvider.config.user, "PaymentMethodsReportTransactions")
    ) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -5), true, false),
    date2: formatDate(addDays(getDate(), 1), true, false),
    card: null,
    pm: "",
  });
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.pm = postData.pm && postData.pm.length > 0 ? postData.pm : null;
      Requests.postData(
        "/paymentMethods/getPaymentTransactionsReport",
        postData
      )
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setData(res.data);
            setTotals(res.totals);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  const [allMethods, setAllMethods] = useState(null);
  useEffect(() => {
    getProviders();
    // eslint-disable-next-line
  }, []);
  const getProviders = () => {
    Requests.postData("/paymentMethods/getPaymentMethodsAll", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setAllMethods(res.data);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          getItems();
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("Transactions")}
              {" / "}
              {ConfigProvider.getTranslation("Report")} ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"></div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0">
            <DropdownCard
              setSelectedItem={(val) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    card: val,
                  };
                });
              }}
            />
          </div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            <input
              id="date1"
              name="date1"
              type="datetime-local"
              value={formData.date1}
              onChange={(e) => {
                return setFormData({ ...formData, date1: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("StartDate")}
              disabled={loading}
              required
            />
            <input
              id="date2"
              name="date2"
              type="datetime-local"
              value={formData.date2}
              onChange={(e) => {
                return setFormData({ ...formData, date2: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("EndDate")}
              disabled={loading}
              required
            />
            {allMethods && (
              <Dropdown
                name="PaymentMethod"
                items={allMethods}
                topItems={{
                  "": ConfigProvider.getTranslation("Drowndown.SelectOne"),
                }}
                selected={formData.pm}
                setSelected={(val) => {
                  setFormData({ ...formData, pm: val });
                }}
              />
            )}

            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
      </form>
      {/* Table */}
      <PaymentsReportTable data={data} loading={loading} />
      <div className="mt-8">
        <div className="lg:flex gap-2">
          {totals.map((item, index) => (
            <ClassicTotals key={index} item={item} />
          ))}
        </div>
      </div>
    </>
  );
}
function PaymentsReportTable({ data, loading }) {
  const ConfigProvider = useConfig();

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Report")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Method")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Type")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Amount")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Count")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan="4" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              )}
              {!loading &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                        {item.methodName}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={classNames(
                          "font-medium text-center",
                          item.type === 0 ? "text-emerald-500" : "text-rose-500"
                        )}
                      >
                        {item.type === 0
                          ? ConfigProvider.getTranslation("Deposit")
                          : ConfigProvider.getTranslation("Withdrawal")}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
                        {`${item.strAmount} ${item.currency}`}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-300 text-center">
                        {item.count}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
function ClassicTotals({ item }) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative mt-2 flex flex-col gap-2 px-3 py-2">
      {item.amount && (
        <div>{`${ConfigProvider.getTranslation("Total")} ${
          item.type === 0
            ? ConfigProvider.getTranslation("Deposit")
            : ConfigProvider.getTranslation("Withdrawal")
        }: ${item.strAmount} ${item.currency}`}</div>
      )}
    </div>
  );
}

export default PaymentsReport;

import React, { useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import AccountSidebar from "../../components/AccountSidebar";
import { usePrefences } from "../../context/PrefencesContext";
import Alert from "../../components/Alert";

function ChangeTimezone() {
  const ConfigProvider = useConfig();
  return (
    <>
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
          {ConfigProvider.getTranslation("Tab.Account")} ✨
        </h1>
      </div>

      {/* Content */}
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <AccountSidebar />
          <ChangeTimezonePage />
        </div>
      </div>
    </>
  );
}
function ChangeTimezonePage() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const [messages, setMessages] = useState([]);
  const updateTimezone = (val) => {
    PrefencesProvider.savePrefences("timezone", val);
    setMessages([
      {
        type: "success",
        text: ConfigProvider.getTranslation("Forms.SuccessMessage"),
      },
    ]);
  };

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
          {ConfigProvider.getTranslation("TimeZone.Selection")}
        </h2>

        {/* Timezone */}
        <section>
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <div className="mt-5">
            <ul>
              {Object.keys(ConfigProvider.config.timezones).map(
                (item, index) => (
                  <li
                    key={index}
                    className={`py-3 pl-3 border-b border-slate-200 dark:border-slate-700 cursor-pointer ${
                      item === PrefencesProvider.prefences.timezone
                        ? "bg-slate-300/10"
                        : ""
                    }`}
                    onClick={() => updateTimezone(item)}
                  >
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}
export default ChangeTimezone;

import React, { useEffect, useState } from "react";
import Transition from "../context/TransitionContext";
import { TypeIcon } from "./Tree/TypeIcon";
import Requests from "../helpers/Requests";
import styles from "./Tree/css/App.module.css";
import {
  DndProvider,
  MultiBackend,
  Tree,
  getBackendOptions,
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./Tree/CustomNode";
import { diagnosticResponse } from "../helpers/Helpers";
import { useConfig } from "../context/ConfigContext";

function ShopTooltip({ className, bg, size, position, shopName, shop, auth }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const ConfigProvider = useConfig();
  const getItems = () => {
    Requests.postData("/user/getTreeNodes", {
      selAccount: shop,
      search: "",
    })
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success" && tooltipOpen) {
          setTreeData(res.data);
        }
      })
      .catch(() => {});
  };

  const positionOuterClasses = (position) => {
    switch (position) {
      case "right":
        return "left-full top-1/2 -translate-y-1/2";
      case "left":
        return "top-full left-0 -translate-x-1/2 md:right-full md:left-auto md:top-1/2 md:-translate-y-1/2 md:translate-x-0";
      case "bottom":
        return "top-full left-1/2 -translate-x-1/2";
      default:
        return "bottom-full left-1/2 -translate-x-1/2";
    }
  };

  const sizeClasses = (size) => {
    switch (size) {
      case "lg":
        return "min-w-72 py-3";
      case "md":
        return "min-w-56 py-3";
      case "sm":
        return "min-w-44 py-2";
      default:
        return "py-2";
    }
  };

  const colorClasses = (bg) => {
    switch (bg) {
      case "light":
        return "bg-white text-slate-600 border-slate-200";
      case "dark":
        return "bg-slate-700 text-slate-100 border-slate-600";
      default:
        return "text-slate-600 bg-white dark:bg-slate-700 dark:text-slate-100 border-slate-200 dark:border-slate-600";
    }
  };

  const positionInnerClasses = (position) => {
    switch (position) {
      case "right":
        return "ml-2";
      case "left":
        return "mr-2";
      case "bottom":
        return "mt-2";
      default:
        return "mb-2";
    }
  };
  useEffect(() => {
    if (tooltipOpen) {
      getItems();
    }
    // eslint-disable-next-line
  }, [tooltipOpen]);

  return (
    <div
      className={`relative ${className}`}
      onMouseEnter={() =>
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") && setTooltipOpen(true)
      }
      onMouseLeave={() => setTooltipOpen(false)}
      onFocus={() =>
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") && setTooltipOpen(true)
      }
      onBlur={() => setTooltipOpen(false)}
    >
      <button
        className={`block ${
          (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1")
            ? ""
            : "cursor-default"
        }`}
        aria-haspopup="true"
        aria-expanded={tooltipOpen}
        onClick={(e) => e.preventDefault()}
      >
        <div className="text-center flex">
          <span className="mr-2">
            <TypeIcon auth={auth} stat={1} />
          </span>
          <span>{shopName}</span>
        </div>
      </button>
      <div className={`z-10 absolute ${positionOuterClasses(position)}`}>
        <Transition
          show={tooltipOpen}
          tag="div"
          className={`rounded border overflow-hidden shadow-lg ${sizeClasses(
            size
          )} ${colorClasses(bg)} ${positionInnerClasses(position)}`}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          {treeData.length > 0 && (
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
              <div className={styles.app + " overflow-x-auto"}>
                <Tree
                  enableAnimateExpand={true}
                  tree={treeData}
                  rootId={0}
                  render={(node, { depth, isOpen }) => (
                    <CustomNode node={node} depth={depth} isOpen={isOpen} />
                  )}
                  classes={{
                    root: styles.treeRoot,
                    draggingSource: styles.draggingSource,
                    dropTarget: styles.dropTarget,
                  }}
                  initialOpen={true}
                  canDrag={false}
                  canDrop={false}
                />
              </div>
            </DndProvider>
          )}
        </Transition>
      </div>
    </div>
  );
}

export default ShopTooltip;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToastr } from "../../../context/ToastrContext";
import Requests from "../../../helpers/Requests";
import { useConfig } from "../../../context/ConfigContext";
import {
  ArrowRightStartOnRectangleIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import { usePrefences } from "../../../context/PrefencesContext";
import { TypeIcon } from "../../../components/Tree/TypeIcon";
import { diagnosticResponse } from "../../../helpers/Helpers";
function AccountBanner({ callback }) {
  const { _id } = useParams();
  const ToastrContext = useToastr();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/accounts/getAccountInfo", { _id: _id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setData(res.data);
            if (callback) {
              callback(res.data);
            }
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (ConfigProvider.customData && ConfigProvider.customData._id === _id) {
      setData(ConfigProvider.customData);
    } else {
      getItems();
    }
    // eslint-disable-next-line
  }, []);

  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  return (
    <div className="relative bg-indigo-100 dark:bg-indigo-500 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      >
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="24.537%"
              x2="50%"
              y2="100%"
              id="welcome-c"
            >
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-d)"
                d="M64-24h80v152H64z"
              />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-f)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-h)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative flex items-center space-x-2">
        <div className="px-2 md:px-0 items-center mr-2">
          <TypeIcon
            auth={data && data.auth}
            stat={data && data.stat}
            className="w-12 h-12 text-slate-800 dark:text-white mx-auto"
          />
        </div>
        <div className="grow flex flex-col space-y-2">
          <div className="flex flex-col md:flex-row items-center space-x-2 text-slate-700 dark:text-slate-100">
            <div
              className={`text-2xl font-bold md:w-1/2 lg:w-1/4 text-indigo-500 dark:text-slate-800 ${
                loading
                  ? "animate-pulse w-full h-6 dark:bg-indigo-300 rounded mr-2"
                  : ""
              }`}
            >
              {!loading && data && data.uname}
            </div>
            <div
              className={`text-md md:w-1/2 lg:w-1/4 mb-2 md:hidden ${
                loading
                  ? "animate-pulse w-full h-6 dark:bg-indigo-300 rounded mr-2"
                  : ""
              }`}
            >
              {!loading && data && `${data.name ?? ""}`}
            </div>
            <div
              className={`flex grow items-center min-w-0 text-sm text-slate-600 dark:text-indigo-200 ${
                loading
                  ? "animate-pulse w-full h-6 dark:bg-indigo-300 rounded mr-2"
                  : ""
              }`}
            >
              {!loading && data && (
                <>
                  <CalendarDaysIcon className="w-5 h-5 mr-2" />
                  {ConfigProvider.getTranslation("RegisterDate")}:
                  <span className="ml-1">
                    {new Intl.DateTimeFormat("default", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: PrefencesProvider.prefences.timezone,
                    }).format(new Date(data.rdate))}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center space-x-2 text-slate-800 dark:text-slate-100">
            <div
              className={`text-md font-bold md:w-1/2 lg:w-1/4 hidden md:block ${
                loading
                  ? "animate-pulse w-full h-6 dark:bg-indigo-300 rounded mr-2"
                  : ""
              }`}
            >
              {!loading && data && `${data.name ?? ""}`}
            </div>
            <div
              className={`flex grow items-center min-w-0 text-sm text-slate-600 dark:text-indigo-200 ${
                loading
                  ? "animate-pulse w-full h-6 dark:bg-indigo-300 rounded mr-2"
                  : ""
              }`}
            >
              {!loading && data && data.lastLogin && (
                <>
                  <ArrowRightStartOnRectangleIcon className="w-5 h-5 mr-2" />
                  {ConfigProvider.getTranslation("LastLogin")}:
                  <span className="ml-1">
                    {new Intl.DateTimeFormat("default", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: PrefencesProvider.prefences.timezone,
                    }).format(new Date(data.lastLogin))}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountBanner;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import TreeMain from "../../components/Tree/TreeMain";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import {
  ArrowRightCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Alert from "../../components/Alert";
import Requests from "../../helpers/Requests";
import { useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import CurrencyDropdown from "../../components/CurrencyDropdown";
function AddCard() {
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CardsAdd")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    uname: "",
    currency: "",
    name: "",
    sname: "",
    email: "",
    identity: "",
    password: "",
    password2: "",
  });
  const [messages, setMessages] = useState([]);

  const updateCard = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/cards/updateCard", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              navigate(`/cards/card/view/${res.newId}`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleChangeInfo = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain />
        ) : null}
        <div className="relative grow min-w-0">
          <WelcomeBanner />
          <form className="w-full" autoComplete="off" onSubmit={updateCard}>
            {/* Panel body */}
            <div className="p-6 space-y-6">
              <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                {ConfigProvider.getTranslation("Register.AccountInformation")}
              </h2>
              <div className="w-full mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>

              <section>
                <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                  <div className="relative col-span-2">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="uname"
                    >
                      {ConfigProvider.getTranslation("Username")}
                    </label>
                    <input
                      id="uname"
                      name="uname"
                      type="text"
                      value={formData.uname}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("Username")}
                      required
                      maxLength={15}
                      disabled={loading}
                    />
                  </div>

                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      {ConfigProvider.getTranslation("Name")}
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("Name")}
                      maxLength={50}
                      disabled={loading}
                    />
                  </div>
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="sname"
                    >
                      {ConfigProvider.getTranslation("Surname")}
                    </label>
                    <input
                      id="sname"
                      name="sname"
                      type="text"
                      value={formData.sname}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("Surname")}
                      maxLength={50}
                      disabled={loading}
                    />
                  </div>
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      {ConfigProvider.getTranslation("E-mail")}
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("E-mail")}
                      d
                      disabled={loading}
                    />
                  </div>
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="identity"
                    >
                      {ConfigProvider.getTranslation("Identity")}
                    </label>
                    <input
                      id="identity"
                      name="identity"
                      type="text"
                      value={formData.identity}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("Identity")}
                      maxLength={20}
                      disabled={loading}
                    />
                  </div>
                  {!ConfigProvider.config.isShopSystem && (
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="currency"
                      >
                        {ConfigProvider.getTranslation("Currency")}
                      </label>
                      <CurrencyDropdown
                        name="currency"
                        selected={formData.currency}
                        setSelected={(val) => {
                          setFormData((prev) => {
                            return { ...prev, currency: val };
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </section>
              <section>
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                  {ConfigProvider.getTranslation("Password")}
                </h2>

                <div className="mt-5">
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        {ConfigProvider.getTranslation("Password.New")}
                      </label>
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password}
                        onChange={handleChangeInfo}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password2"
                      >
                        {ConfigProvider.getTranslation("Password.NewAgain")}
                      </label>
                      <input
                        id="password2"
                        name="password2"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password2}
                        onChange={handleChangeInfo}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <ul className="ml-10 my-2 list-disc">
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.MinMaxLength"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.RequiredUppcaseandLowercaseAndNumber"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.CaseSensetive"
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Panel footer */}
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                <div className="flex self-end">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-4 h-4 mb-1" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-4 h-4" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </footer>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav />
      ) : null}
    </>
  ) : null;
}
function WelcomeBanner() {
  const ConfigProvider = useConfig();
  return (
    <div className="relative bg-indigo-200 dark:bg-indigo-500 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      >
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="24.537%"
              x2="50%"
              y2="100%"
              id="welcome-c"
            >
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-d)"
                d="M64-24h80v152H64z"
              />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-f)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-h)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative">
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">
          {ConfigProvider.getTranslation("Card")}
          {" / "}
          {ConfigProvider.getTranslation("Add")} 💫
        </h1>
      </div>
    </div>
  );
}
export default AddCard;

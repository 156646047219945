import React, { createContext, useContext, useEffect, useState } from "react";
const DeviceSizeContext = createContext();

export function DeviceSizeProvider({ children }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [deviceType, setDeviceType] = useState(
    window.innerWidth < 1024 ? "sm" : window.innerWidth < 1280 ? "lg" : "lg"
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const newDeviceType =
      window.innerWidth < 1024 ? "sm" : window.innerWidth < 1280 ? "lg" : "lg";
    if (newDeviceType !== deviceType) setDeviceType(newDeviceType);
    // eslint-disable-next-line
  }, [windowWidth]);

  return (
    <DeviceSizeContext.Provider
      value={{
        deviceType,
      }}
    >
      {children}
    </DeviceSizeContext.Provider>
  );
}

export function useDeviceSize() {
  return useContext(DeviceSizeContext);
}

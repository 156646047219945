import React, { useEffect, useState } from "react";
import {
  ArrowRightCircleIcon,
  CalendarIcon,
  DocumentTextIcon,
  LinkIcon,
} from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  addDays,
  checkRole,
  classNames,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import PaginationClassic from "../../components/PaginationClassic";
import DropdownFilter from "../../components/DropdownFilter";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import DropdownCard from "../../components/Search/DropdownCard";
import Dropdown from "../../components/Dropdown";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Confirm from "../../components/Confirm";
import { useToastr } from "../../context/ToastrContext";
import { useSocket } from "../../context/SocketContext";

function PaymentsTransactions() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkRole(ConfigProvider.config.user, "PaymentMethodsListTransactions")
    ) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [excel, setExcel] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -5), true, false),
    date2: formatDate(addDays(getDate(), 1), true, false),
    card: null,
    page: 1,
    sort: 0,
    sortBy: 0,
    status: "3",
    type: "-1",
    pm: "",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.excel = excel;
      postData.pm = postData.pm && postData.pm.length > 0 ? postData.pm : null;
      Requests.postData(
        "/paymentMethods/getPaymentTransactions",
        postData,
        excel
      )
        .then((response) => {
          let res = response.data;
          if (excel) {
            if (
              response.headers["content-type"] ===
              "application/json; charset=utf-8"
            ) {
              Requests.postData(
                "/paymentMethods/getPaymentTransactions",
                postData,
                false
              ).then((response) => {
                let res = response.data;
                diagnosticResponse(res);
                if (res.type !== "success") {
                  setMessages([
                    {
                      type: "error",
                      text: res.message.length
                        ? res.message
                        : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                    },
                  ]);
                  document.getElementById("mainBody").scrollTo(0, 0);
                }
              });
            } else {
              const blob = new Blob([response.data], {
                type: "application/vnd.ms-excel",
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "export.xlsx";
              document.body.appendChild(a);
              a.click();
              a.remove();
            }
          } else {
            if (res.type !== "success") {
              diagnosticResponse(res) &&
                setMessages([
                  {
                    type: "error",
                    text: res.message.length
                      ? res.message
                      : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                  },
                ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              if (formData.page === 1) {
                setTotalItems(res.c);
              }
              setData(res.data);
            }
          }
          setLoading(false);
          setExcel(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
          setExcel(false);
        });
    }
  };
  const ToastrContext = useToastr();
  useEffect(() => {
    getItems();

    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const allStat = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    0: ConfigProvider.getTranslation("Allowed"),
    1: ConfigProvider.getTranslation("Approved"),
    2: ConfigProvider.getTranslation("Reject"),
    3: ConfigProvider.getTranslation("UnderReview"),
  };
  const allTypes = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    0: ConfigProvider.getTranslation("Deposit"),
    1: ConfigProvider.getTranslation("Withdrawal"),
  };
  const [allMethods, setAllMethods] = useState(null);
  useEffect(() => {
    getProviders();
    // eslint-disable-next-line
  }, []);
  const getProviders = () => {
    Requests.postData("/paymentMethods/getPaymentMethodsAll", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setAllMethods(res.data);
        }
      })
      .catch(() => {});
  };

  const SocketProvider = useSocket();
  useEffect(() => {
    if (SocketProvider.connected) {
      SocketProvider.subscribe(["paymentTransactions"]);
    }
    return () => {
      SocketProvider.unsubscribe(["paymentTransactions"]);
    };
    // eslint-disable-next-line
  }, [SocketProvider.connected]);

  const { paymentTransactionsQueue, clearPaymentTransactionsMessages } =
    SocketProvider;

  useEffect(() => {
    if (paymentTransactionsQueue.length > 0) {
      const readMessages = paymentTransactionsQueue;
      readMessages.forEach((item) => {
        const message = JSON.parse(item.message);
        if (item.cmd === "insert") {
          if (
            (formData.type === "-1") |
            (formData.type === message.type.toString())
          ) {
            setData((prev) => [...prev, message]);

            if (
              message.type === 1 &&
              message.status === 3 &&
              PrefencesProvider.prefences.audio === 1
            ) {
              const audio = new Audio("/sounds/notification.mp3");
              audio.play().catch(() => {
                ToastrContext.showToast({
                  type: "error",
                  text: ConfigProvider.getTranslation("NewTransaction"),
                });
              });
            }
          }
        } else if (item.cmd === "update") {
          var findItem = data.find((item) => item._id === message._id);
          if (findItem) {
            setData((prev) =>
              prev.map((item) => (item._id === message._id ? message : item))
            );
          }
        }
      });
      clearPaymentTransactionsMessages(readMessages.length);
    }
    // eslint-disable-next-line
  }, [paymentTransactionsQueue, clearPaymentTransactionsMessages]);

  useEffect(() => {
    if (excel) {
      getItems();
    } // eslint-disable-next-line
  }, [excel]);

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("Transactions")} ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"></div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0">
            <DropdownCard
              setSelectedItem={(val) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    card: val,
                  };
                });
              }}
            />
          </div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            <input
              id="date1"
              name="date1"
              type="datetime-local"
              value={formData.date1}
              onChange={(e) => {
                return setFormData({ ...formData, date1: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("StartDate")}
              disabled={loading}
              required
            />
            <input
              id="date2"
              name="date2"
              type="datetime-local"
              value={formData.date2}
              onChange={(e) => {
                return setFormData({ ...formData, date2: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("EndDate")}
              disabled={loading}
              required
            />
            {allMethods && (
              <Dropdown
                name="PaymentMethod"
                items={allMethods}
                topItems={{
                  "": ConfigProvider.getTranslation("Drowndown.SelectOne"),
                }}
                selected={formData.pm}
                setSelected={(val) => {
                  setFormData({ ...formData, pm: val });
                }}
              />
            )}
            <DropdownFilter align="right">
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Type")}
              </div>
              <ul className="mb-4">
                {Object.keys(allTypes).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.type === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            type: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allTypes[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Status")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.status === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            status: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </DropdownFilter>
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
            {checkRole(ConfigProvider.config.user, "CardsExportExcel") && (
              <button
                type="button"
                onClick={() => {
                  setExcel(true);
                }}
                className="btn bg-emerald-500 hover:bg-emerald-600 text-slate-800"
              >
                <DocumentTextIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Excel")}
                </span>
              </button>
            )}
          </div>
        </div>
      </form>
      {/* Table */}
      <PaymentsTransactionsTable
        data={data}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
        allStat={allStat}
        getItems={getItems}
      />

      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}
function PaymentsTransactionsTable({
  data,
  loading,
  formData,
  setFormData,
  allStat,
  getItems,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const PrefencesProvider = usePrefences();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmItem, setConfirmItem] = useState(null);
  const [confirmType, setConfirmType] = useState(1);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [note, setNote] = useState("");

  const updatePaymentTransaction = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/paymentMethods/updatePaymentTransaction", {
        transaction: confirmItem._id,
        confirm: confirmType === 1,
        note: confirmType === 2 ? note : "",
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            setConfirmItem(null);
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowConfirm(false);
          setConfirmItem(null);
        });
    }
  };
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Transactions")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Card")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Type")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Date")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Amount")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("PaymentMethod")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Detail")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Status")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">&nbsp;</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="8">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan="8" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              )}
              {!loading &&
                data.map((item) => (
                  <tr key={item._id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <Link
                        to={"/cards/card/view/" + item.aid}
                        className="text-sky-500 dark:text-sky-400 font-medium text-left"
                      >
                        {item.uname}
                      </Link>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={classNames(
                          "font-medium text-center",
                          item.type === 0 ? "text-emerald-500" : "text-rose-500"
                        )}
                      >
                        {item.type === 0
                          ? ConfigProvider.getTranslation("Deposit")
                          : ConfigProvider.getTranslation("Withdrawal")}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
                        <div className="flex items-center justify-center text-xs text-slate-700 dark:text-slate-500">
                          <CalendarIcon className="w-4 h-4 mr-1" />
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(item.date))}
                        </div>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
                        {`${item.amount} ${item.currency}`}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-300 text-center">
                        {item.method}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-slate-800 dark:text-slate-300 flex flex-col gap-2">
                        {Object.keys(item.extra).map((item2, ix2) => (
                          <div className="flex items-center gap-1" key={ix2}>
                            <span className="font-bold">{item2}:</span>
                            {item2 !== "link" ? (
                              item.extra[item2]
                            ) : (
                              <a
                                href={item.extra[item2]}
                                className="flex items-center"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {<LinkIcon className="w-4 mr-1" />}
                              </a>
                            )}
                          </div>
                        ))}
                        {item.note && item.note.length > 0 && (
                          <div className="flex items-center gap-1">
                            <span className="font-bold">
                              {ConfigProvider.getTranslation("Note")}:
                            </span>
                            {item.note}
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div
                        className={classNames(
                          "font-medium text-center",
                          item.status === 1
                            ? "text-emerald-500"
                            : item.status === 2
                            ? "text-rose-500"
                            : item.status === 3
                            ? "text-amber-500"
                            : "text-slate-800 dark:text-slate-100"
                        )}
                      >
                        {allStat[item.status]}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="flex space-x-2 text-white">
                        {(item.status === 3) |
                          (item.status === 0 && item.type === 0) && (
                          <button
                            type="button"
                            className="rounded p-1 bg-emerald-500 hover:bg-emerald-600"
                            onClick={() => {
                              if (!showConfirm) {
                                setConfirmType(1);
                                setConfirmItem(item);
                                setShowConfirm(true);
                                setNote("");
                              }
                            }}
                          >
                            <CheckIcon className="w-4 h-4 shrink-0" />
                          </button>
                        )}
                        {(item.status === 3) |
                          (item.status === 0 && item.type === 0) && (
                          <button
                            type="button"
                            className="rounded p-1 bg-rose-500 hover:bg-rose-600"
                            onClick={() => {
                              if (!showConfirm) {
                                setConfirmType(2);
                                setConfirmItem(item);
                                setShowConfirm(true);
                                setNote("");
                              }
                            }}
                          >
                            <XMarkIcon className="w-4 h-4 shrink-0" />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {showConfirm && confirmItem !== null && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updatePaymentTransaction();
            setShowConfirm(false);
          }}
        >
          <Confirm
            title={ConfigProvider.getTranslation("AreYouSure")}
            buttonType="submit"
            text={
              <>
                <p
                  className={classNames(
                    confirmType === 1 ? "text-emerald-500" : "text-rose-500"
                  )}
                >{`${ConfigProvider.getTranslation("Type")}: ${
                  confirmType === 1
                    ? ConfigProvider.getTranslation("Approved")
                    : ConfigProvider.getTranslation("Reject")
                }`}</p>
                <p>{`${ConfigProvider.getTranslation("Username")}: ${
                  confirmItem.uname
                }`}</p>
                <p>{`${ConfigProvider.getTranslation("Method")}: ${
                  confirmItem.method
                }`}</p>
                <p>{`${ConfigProvider.getTranslation("Amount")}: ${
                  confirmItem.amount
                } ${confirmItem.currency}`}</p>

                {confirmType === 2 && (
                  <p>
                    {ConfigProvider.getTranslation("Note")}:
                    <input
                      type="text"
                      className="w-full form-input"
                      placeholder=""
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                      required
                      maxLength={255}
                    />
                  </p>
                )}
              </>
            }
            confirm={() => {}}
            callback={() => {
              setShowConfirm(false);
              setConfirmItem(null);
            }}
          />
        </form>
      )}
    </div>
  );
}

export default PaymentsTransactions;

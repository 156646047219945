import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { CustomNode } from "./CustomNode";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
} from "@minoru/react-dnd-treeview";
import styles from "./css/App.module.css";
import { CustomDragPreview } from "./CustomDragPreview";
import Requests from "../../helpers/Requests";
import { useConfig } from "../../context/ConfigContext";
import { useTreeData } from "../../context/TreeDataContext";
import { diagnosticResponse } from "../../helpers/Helpers";
function TreeComponent({ enableDnd = false, callback = undefined }) {
  const [search, setSearch] = useState("");
  const ConfigProvider = useConfig();
  const TreeDataProvider = useTreeData();

  const handleDrop = (newTree) => {
    if (draggedItem !== null && enableDnd) {
      let updatedItem = newTree.find((x) => x.id === draggedItem.id);
      if (updatedItem !== null) {
        const from = draggedItem.id;
        const parent = updatedItem.parent;
        Requests.postData("/accounts/moveAccount", {
          from: from,
          parent: parent,
        })
          .then((response) => {
            console.log("x");
            let res = response.data;
            if (diagnosticResponse(res) && res.type === "success") {
              TreeDataProvider.setTreeData(newTree);
            }
            setDraggedItem(null);
          })
          .catch(() => setDraggedItem(null));
      }
    }
  };

  const handleSelect = (node) => {
    Requests.postData("/user/setSelTree", { id: node.id })
      .then(() => {
        TreeDataProvider.setSelectedNode(node.id);
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        console.log("err");
      });
  };

  const [searchTimeout, setSearchTimeout] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      if (
        (TreeDataProvider.treeData.length === 0) |
        (TreeDataProvider.lastSearch !== "")
      ) {
        TreeDataProvider.getItems(search, null);
      }
    } else {
      if (!TreeDataProvider.loading) {
        searchTimeout && window.clearTimeout(searchTimeout);
        setSearchTimeout(
          window.setTimeout(() => {
            TreeDataProvider.getItems(search, null);
          }, 300)
        );
      }
    }
    return () => {
      if (searchTimeout !== null) window.clearTimeout(searchTimeout);
    };
    // eslint-disable-next-line
  }, [search]);
  const [draggedItem, setDraggedItem] = useState(null);

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="flex w-full mb-4 relative"
      >
        <input
          type="text"
          className="w-full dark:text-slate-300 bg-white dark:bg-slate-800 border-0 focus:ring-transparent placeholder-slate-400 dark:placeholder-slate-500 appearance-none py-3 pl-10 pr-4"
          value={search}
          onChange={(e) => {
            !TreeDataProvider.loading && setSearch(e.target.value);
          }}
          placeholder="Search"
        />
        <button
          className="absolute inset-0 right-auto group"
          type="submit"
          aria-label="Search"
        >
          <svg
            className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-4 mr-2"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
            <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
          </svg>
        </button>
        <input
          type="submit"
          value={ConfigProvider.getTranslation("Button.Send")}
          className="hidden"
        />
      </form>
      {TreeDataProvider.treeData.length > 0 && (
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <div className={styles.app + " overflow-x-auto"}>
            <Tree
              enableAnimateExpand={true}
              tree={TreeDataProvider.treeData}
              rootId={0}
              render={(node, { depth, isOpen, onToggle }) => (
                <CustomNode
                  node={node}
                  depth={depth}
                  isOpen={isOpen}
                  onToggle={() => {
                    onToggle();
                    if (
                      !TreeDataProvider.treeData.find(
                        (x) => x.parent === node.id
                      )
                    ) {
                      TreeDataProvider.getItems("", node.id);
                    }
                  }}
                  onSelect={handleSelect}
                  selectedNode={TreeDataProvider.selectedNode}
                  enableCheckbox={true}
                />
              )}
              dragPreviewRender={(monitorProps) => (
                <CustomDragPreview monitorProps={monitorProps} />
              )}
              onDrop={handleDrop}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                dropTarget: styles.dropTarget,
              }}
              initialOpen={true}
              canDrag={() => enableDnd}
              canDrop={(item, dragItem) => {
                if (!enableDnd) {
                  return false;
                }
                return (
                  dragItem.dropTarget !== undefined &&
                  dragItem.dragSource.parent !== 0
                );
              }}
              onDragStart={(node) => {
                setDraggedItem(node);
              }}
            />
          </div>
        </DndProvider>
      )}
    </div>
  );
}

export default TreeComponent;

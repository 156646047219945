import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import Requests from "../../helpers/Requests";
import PaginationClassic from "../../components/PaginationClassic";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import { ArrowRightCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import SortByItem from "../../components/SortByItems";

import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { Link } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import { useNavigate } from "react-router-dom";
import Confirm from "../../components/Confirm";
import { useToastr } from "../../context/ToastrContext";

function ReserveDomain() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SiteManageDomain")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const deleteRecord = (item) => {
    if (!showConfirm) {
      setDeleteItem(item);
      setShowConfirm(true);
    }
  };

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 1,
    sortBy: 0,
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/administration/getDomains", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const ToastrContext = useToastr();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const deleteDomain = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/administration/deleteReservedDomain", {
        _id: deleteItem._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            setDeleteItem(null);
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowConfirm(false);
          setDeleteItem(null);
        });
    }
  };

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("DomainRezerv")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchFilter
                  placeholder={ConfigProvider.getTranslation("Search")}
                  search={search}
                  setSearch={setSearch}
                />
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 sm:mb-0">&nbsp;</div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                {/* Add button */}
                <Link
                  type="button"
                  to="/system/reserveDomain/add"
                  className="btn bg-green-500 hover:bg-green-600 text-slate-100"
                >
                  <svg
                    className="w-4 h-4 fill-slate-100 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">
                    {ConfigProvider.getTranslation("Add")}
                  </span>
                </Link>
              </div>
            </div>
            {/* Table */}
            <ReserveDomainTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
              deleteRecord={deleteRecord}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
            </div>
          </form>
        </div>
        {showConfirm && deleteItem !== null && (
          <Confirm
            title={ConfigProvider.getTranslation("AreYouSure")}
            text={
              <>
                <p>{`${ConfigProvider.getTranslation("Domain")}: ${
                  deleteItem.name
                }`}</p>
              </>
            }
            confirm={() => {
              deleteDomain();
            }}
            callback={() => {
              setShowConfirm(false);
              setDeleteItem(null);
            }}
          />
        )}
      </div>
    </>
  ) : null;
}

function ReserveDomainTable({
  data,
  loading,
  formData,
  setFormData,
  deleteRecord,
}) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("DomainRezerv")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">#</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">Domain:</div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Nameservers")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">&nbsp;</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="4" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item) => (
                  <tr key={item._id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="grow flex items-center justify-center">
                        #
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                        {item.name}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="grow flex flex-col items-center justify-center">
                        {item.nameServers.map((d, i) => (
                          <p key={i}>{d}</p>
                        ))}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="grow flex items-center justify-center">
                        <button
                          className={`bg-rose-500 hover:bg-rose-600 w-6 h-6 rounded-full shrink-0 my-2 mr-3`}
                          type="button"
                          title={ConfigProvider.getTranslation("Delete")}
                          onClick={() => {
                            deleteRecord(item);
                          }}
                        >
                          <XMarkIcon className="w-6 h-6 text-indigo-50 p-0.5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReserveDomain;

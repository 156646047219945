import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  ArrowRightCircleIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { usePrefences } from "../../context/PrefencesContext";
import { BuildingLibraryIcon } from "@heroicons/react/24/solid";
import NoData from "../../components/NoData";

function SettlementBet() {
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SettlementBetSettlement")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [betId, setBetId] = useState("");
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState([]);
  const getBetDetail = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      setData([]);
      Requests.postData("/bets/getBetDetail", { cid: betId })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setData(res.data.cm);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="grow mx-auto">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("BetSettlement")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={getBetDetail}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="s w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>

          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="betId"
                >
                  {ConfigProvider.getTranslation("BetId")}
                </label>
                <input
                  id="betId"
                  name="betId"
                  type="text"
                  value={betId}
                  onChange={(e) => {
                    setBetId(e.target.value);
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("BetId")}
                  required
                  maxLength={50}
                  disabled={loading}
                />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Search")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>

      {!loading && (
        <SettlementBetItem
          data={data}
          setMessages={setMessages}
          cb={() => {
            setData([]);
            setBetId("");
          }}
          setData={setData}
        />
      )}
    </div>
  );
}

function SettlementBetItem({ data, setData, cb, setMessages }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const [loading, setLoading] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    if (!loading && data.length > 0) {
      setLoading(true);
      setMessages([]);
      const formData = { _id: data[0].cm.cid, form: [] };
      data.forEach((item) => {
        formData.form.push({ id: item.cm._id, status: item.cm.status });
      });
      Requests.postData("/resulting/updateCoupon", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            cb();
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <form className="w-full" autoComplete="off" onSubmit={submitForm}>
      <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
        {data && data.length > 0 && (
          <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
            <tr>
              <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="grow font-semibold text-left">
                  {ConfigProvider.getTranslation("Event")}
                </div>
              </th>

              <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="grow font-semibold text-center">
                  {ConfigProvider.getTranslation("Tip")}
                </div>
              </th>
              <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="grow font-semibold text-center">
                  {ConfigProvider.getTranslation("Selection")}
                </div>
              </th>
              <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="grow font-semibold text-center">
                  {ConfigProvider.getTranslation("Rate")}
                </div>
              </th>
              <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="grow font-semibold text-center">
                  {ConfigProvider.getTranslation("Status")}
                </div>
              </th>
              <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="grow font-semibold text-center">
                  {ConfigProvider.getTranslation("IsLive")}
                </div>
              </th>
              <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                <div className="grow font-semibold text-center">
                  {ConfigProvider.getTranslation("Period")}
                </div>
              </th>
            </tr>
          </thead>
        )}
        {data && data.length === 0 && (
          <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
            <tr>
              <td colSpan="8">
                <NoData />
              </td>
            </tr>
          </tbody>
        )}

        <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
          {data.map((item) => (
            <tr key={item.cm._id}>
              <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                <div className="font-medium text-slate-800 dark:text-slate-100 flex flex-col">
                  <div className="flex items-center text-xs text-slate-700 dark:text-slate-500">
                    <CalendarIcon className="w-4 h-4 mr-1" />
                    {new Intl.DateTimeFormat("default", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      timeZone: PrefencesProvider.prefences.timezone,
                    }).format(new Date(item.e.eventdate))}
                  </div>
                  <div>{item.e.label}</div>
                  <div className="text-xs text-slate-700 dark:text-slate-500">
                    {`${item.country.name} / ${item.league.name}`}
                  </div>
                </div>
              </td>

              <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                <div className="text-center">{item.market}</div>
              </td>
              <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                <div className="flex items-center justify-center w-full">
                  {item.selection}
                  {item.cm.isBanko && (
                    <BuildingLibraryIcon className="ml-1 w-4 h-4 text-slate-700 dark:text-slate-500" />
                  )}
                </div>
              </td>

              <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                <div className="text-center">
                  {parseFloat(item.cm.odd).toFixed(2)}
                </div>
              </td>
              <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                <div className={classNames("text-center font-semibold")}>
                  <select
                    className="btn w-full min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
                    value={item.cm.status}
                    onChange={(e) => {
                      setData((prevData) => {
                        const updatedData = prevData.map((dataItem) => {
                          if (dataItem.cm._id === item.cm._id) {
                            return {
                              ...dataItem,
                              cm: {
                                ...dataItem.cm,
                                status: e.target.value,
                              },
                            };
                          }

                          return dataItem;
                        });
                        return updatedData;
                      });
                    }}
                  >
                    <option value={0}>
                      {ConfigProvider.getTranslation("Pending")}
                    </option>
                    <option value={1}>
                      {ConfigProvider.getTranslation("Won")}
                    </option>
                    <option value={4}>
                      {ConfigProvider.getTranslation("HalfWon")}
                    </option>
                    <option value={2}>
                      {ConfigProvider.getTranslation("Lost")}
                    </option>
                    <option value={5}>
                      {ConfigProvider.getTranslation("HalfLost")}
                    </option>
                    <option value={3}>
                      {ConfigProvider.getTranslation("Return")}
                    </option>
                  </select>
                </div>
              </td>
              <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                <div
                  className={`text-center ${
                    item.cm.isLive ? "text-emerald-500" : ""
                  }`}
                >
                  {item.cm.isLive
                    ? ConfigProvider.getTranslation("Yes")
                    : ConfigProvider.getTranslation("No")}
                </div>
              </td>
              <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                <div className="flex w-full flex-col text-xs">
                  <div className="text-center text-slate-400 dark:text-slate-500">
                    {item.p}
                    {item.cm.timer && item.cm.timer.length > 0
                      ? " - " + item.cm.timer
                      : ""}
                  </div>
                  <div className="text-center">({item.cm.score})</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Panel footer */}
      {data && data.length > 0 && (
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Save")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      )}
    </form>
  );
}

export default SettlementBet;

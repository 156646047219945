import React, { useEffect, useState } from "react";
import Requests from "../helpers/Requests";
import styles from "./Tree/css/App.module.css";
import {
  DndProvider,
  MultiBackend,
  Tree,
  getBackendOptions,
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./Tree/CustomNode";
import { diagnosticResponse } from "../helpers/Helpers";

function ShowTree({ shop }) {
  const [treeData, setTreeData] = useState([]);
  const getItems = () => {
    Requests.postData("/user/getTreeNodes", {
      selAccount: shop,
      search: "",
    })
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setTreeData(res.data);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  return treeData.length > 0 ? (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className={styles.app + " overflow-x-auto"}>
        <Tree
          enableAnimateExpand={true}
          tree={treeData}
          rootId={0}
          render={(node, { depth, isOpen }) => (
            <CustomNode node={node} depth={depth} isOpen={isOpen} />
          )}
          classes={{
            root: styles.treeRoot,
            draggingSource: styles.draggingSource,
            dropTarget: styles.dropTarget,
          }}
          initialOpen={true}
          canDrag={false}
          canDrop={false}
        />
      </div>
    </DndProvider>
  ) : null;
}

export default ShowTree;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import Confirm from "../../components/Confirm";
import Dropdown from "../../components/Dropdown";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import TreeMain from "../../components/Tree/TreeMain";

function UpsertLimitShop() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SportsManageShopLimits")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [limitClasses, setLimitClasses] = useState(null);
  const [formData, setFormData] = useState({
    preSingleLimit: 0,
    preMultibleLimit: 0,
    liveSingleLimit: 0,
    liveMultibleLimit: 0,
    val: "",
    _id: _id ? _id : undefined,
  });

  const [messages, setMessages] = useState([]);

  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/sports/updateLimitShop", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              navigate(`/sports/limitShops`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  useEffect(() => {
    Requests.postData("/sports/getLimitShop", { _id: formData._id })
      .then((response) => {
        let res = response.data;
        if (res.type !== "success") {
          diagnosticResponse(res);
          ToastrContext.showToast({
            type: "error",
            text: res.message.length
              ? res.message
              : ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
        } else {
          setLimitClasses(res.limitClasses);
          if (res.data.val) {
            setFormData((prev) => {
              return {
                ...prev,
                preSingleLimit: res.data.preSingleLimit ?? 0,
                preMultibleLimit: res.data.preMultibleLimit ?? 0,
                liveSingleLimit: res.data.liveSingleLimit ?? 0,
                liveMultibleLimit: res.data.liveMultibleLimit ?? 0,
                val: res.data.val,
              };
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        ToastrContext.showToast({
          type: "error",
          text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
        });
        document.getElementById("mainBody").scrollTo(0, 0);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteItem = () => {
    if (formData._id && !loading) {
      setLoading(true);
      Requests.postData("/sports/removeLimitShop", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            navigate("/sports/limitShops");
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setLoading(false);
          setShowConfirm(false);
        });
    }
  };
  return (
    <div className="flex space-x-4">
      {!formData._id &&
      ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMain />
      ) : null}
      <div className="relative grow min-w-0">
        <div className="grow max-w-lg mx-auto lg:mt-20">
          <WelcomeBanner>
            {`${ConfigProvider.getTranslation(
              "Shop"
            )} ${ConfigProvider.getTranslation("Limits")}`}
            {" / "}
            {formData._id
              ? ConfigProvider.getTranslation("Edit")
              : ConfigProvider.getTranslation("Add")}
            💫
          </WelcomeBanner>
          <form className="w-full" autoComplete="off" onSubmit={submitForm}>
            {/* Panel body */}
            <div className="p-6 space-y-6">
              <div className="w-full mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>

              <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                {ConfigProvider.getTranslation("PreGame")}
              </h2>
              <section>
                <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                  <div className="relative col-span-2">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="val"
                    >
                      {ConfigProvider.getTranslation("Name")}
                    </label>
                    <div className="my-2">
                      {limitClasses && (
                        <Dropdown
                          name="val"
                          items={limitClasses}
                          selected={formData.val}
                          setSelected={(val) =>
                            setFormData({
                              ...formData,
                              val: val,
                            })
                          }
                          disabled={loading}
                        />
                      )}
                    </div>
                  </div>
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="preSingleLimit"
                    >
                      {ConfigProvider.getTranslation("SingleBetLimit")}
                    </label>
                    <input
                      id="preSingleLimit"
                      name="preSingleLimit"
                      type="number"
                      value={formData.preSingleLimit}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation(
                        "SingleBetLimit"
                      )}
                      required
                      disabled={loading}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="preMultibleLimit"
                    >
                      {ConfigProvider.getTranslation("MultibleBetLimit")}
                    </label>
                    <input
                      id="preMultibleLimit"
                      name="preMultibleLimit"
                      type="number"
                      value={formData.preMultibleLimit}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation(
                        "MultibleBetLimit"
                      )}
                      required
                      disabled={loading}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </section>
              <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                {ConfigProvider.getTranslation("Live")}
              </h2>
              <section>
                <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="liveSingleLimit"
                    >
                      {ConfigProvider.getTranslation("SingleBetLimit")}
                    </label>
                    <input
                      id="liveSingleLimit"
                      name="liveSingleLimit"
                      type="number"
                      value={formData.liveSingleLimit}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation(
                        "SingleBetLimit"
                      )}
                      required
                      disabled={loading}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="liveMultibleLimit"
                    >
                      {ConfigProvider.getTranslation("MultibleBetLimit")}
                    </label>
                    <input
                      id="liveMultibleLimit"
                      name="liveMultibleLimit"
                      type="number"
                      value={formData.liveMultibleLimit}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation(
                        "MultibleBetLimit"
                      )}
                      required
                      disabled={loading}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </section>
            </div>
            {/* Panel footer */}
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                <div className="flex justify-between">
                  {formData._id ? (
                    <button
                      type="button"
                      disabled={loading}
                      className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                      onClick={() => {
                        setShowConfirm(true);
                      }}
                    >
                      {loading ? (
                        <span>
                          <LoadingSpinner className="w-4 h-4 mb-1" />
                        </span>
                      ) : (
                        <span>
                          <XMarkIcon className="w-4 h-4" />
                        </span>
                      )}
                      <span className="ml-2 uppercase">
                        {ConfigProvider.getTranslation("Delete")}
                      </span>
                    </button>
                  ) : (
                    <>&nbsp;</>
                  )}
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-4 h-4 mb-1" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-4 h-4" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </footer>
          </form>

          {showConfirm && (
            <Confirm
              title={ConfigProvider.getTranslation("AreYouSure")}
              text={
                <>
                  <p>
                    {ConfigProvider.getTranslation("ToContinuePleaseConfirm")}
                  </p>
                </>
              }
              confirm={() => {
                deleteItem();
                setShowConfirm(false);
              }}
              callback={() => {
                setShowConfirm(false);
              }}
            />
          )}
        </div>{" "}
      </div>
      {!formData._id &&
      ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav />
      ) : null}
    </div>
  );
}

export default UpsertLimitShop;

import React from "react";
import {
  BanknotesIcon,
  ForwardIcon,
  GiftIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
function CardMaker({ icon, text, val, desc, desc2, compare, loading }) {
  return (
    <div className="flex flex-col col-span-full xl:col-span-4 bg-white dark:bg-slate-800  shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <div className="px-5 pt-5">
        <header className="flex items-start mb-2 text-indigo-400 dark:text-indigo-300">
          {icon === "money" ? (
            <BanknotesIcon className="w-8 h-8 mr-2" />
          ) : icon === "sport" ? (
            <svg className="shrink-0 w-8 h-8 mr-2" viewBox="0 0 1024 1024">
              <path
                className={`fill-current text-indigo-300`}
                d="M940.992 508.74c1.151 97.505-30.722 194.499-93.35 273.143h-.031l-158.247-30.915-63.139-69.916 70.972-218.284 123.016-18.414 120.78 64.385z"
              ></path>
              <path
                className={`fill-current ${"text-slate-600"}`}
                d="M940.992 508.74l-120.779-64.386 67.167-138.426c35.23 63.522 52.845 133.279 53.612 202.811z"
              ></path>
              <path
                className={`fill-current text-indigo-300`}
                d="M887.38 305.928l-67.167 138.426-123.016 18.414L509.25 327.922V234.83l.927-.736 150.35-122.345.352-.96c72.857 27.046 139.289 74.137 190.311 140.153a429.934 429.934 0 0136.19 54.987z"
              ></path>
              <path
                className={`fill-current ${"text-slate-600"}`}
                d="M847.61 781.883h.033c-21.1 26.534-45.716 50.958-73.72 72.601-32.833 25.384-68.19 45.268-104.955 59.782l20.396-163.298 158.247 30.915zM697.198 462.768l-70.972 218.284h-229.41l-70.97-218.284L509.25 327.922z"
              ></path>
              <path
                className={`fill-current text-indigo-300`}
                d="M626.225 681.052l63.14 69.916-20.397 163.298c-98.273 38.939-206.616 39.578-303.866 4.668L344.1 750.968l52.717-69.916h229.41z"
              ></path>
              <path
                className={`fill-current ${"text-slate-600"}`}
                d="M660.88 110.789l-.352.959-150.35 122.345-.927.736-151.246-123.081c98.337-37.372 206.264-36.797 302.875-.96z"
              ></path>
              <path
                className={`fill-current text-indigo-300`}
                d="M509.25 234.829v93.093L325.845 462.768 213.25 444.354l-68.03-140.249-8.088-3.548c27.302-47.794 64.29-91.112 110.55-126.885 34.43-26.63 71.642-47.219 110.324-61.924l151.246 123.08zM325.844 462.768l70.972 218.284-52.717 69.916-168.413 32.897a377.137 377.137 0 01-5.307-6.714c-60.39-78.1-89.673-170.427-89.833-262.05h.032l132.671-70.747 112.595 18.414z"
              ></path>
              <path
                className={`fill-current ${"text-slate-600"}`}
                d="M344.099 750.968l21.003 167.966c-72.09-25.863-138.106-71.26-189.416-135.07l168.413-32.896zM80.546 515.101c-.128-74.616 19.085-148.816 56.585-214.544l8.088 3.548 68.03 140.249L80.578 515.1h-.032z"
              ></path>
              <path
                className={`fill-current ${"text-slate-800"}`}
                d="M511.095 966.705a453.244 453.244 0 01-153.553-26.71c-78.174-28.043-147.09-77.186-199.295-142.106a420.388 420.388 0 01-5.56-7.033c-61.662-79.75-94.344-175.088-94.516-275.717-.134-79.142 20.447-157.179 59.526-225.669 29.578-51.787 68.702-96.697 116.294-133.503 35.457-27.423 74.507-49.34 116.063-65.137 51.32-19.504 105.3-29.392 160.44-29.392 54.204 0 107.423 9.546 158.173 28.373 79.15 29.38 148.388 80.367 200.235 147.447a453.541 453.541 0 0138.063 57.842c36.077 65.044 55.587 138.841 56.412 213.396 1.228 104.203-33.654 206.252-98.225 287.328a453.01 453.01 0 01-77.544 76.371 451.633 451.633 0 01-110.42 62.89c-52.958 20.978-108.846 31.62-166.093 31.62zm-.604-860.513c-49.686 0-98.314 8.906-144.535 26.473-37.43 14.23-72.618 33.98-104.584 58.708-42.908 33.18-78.173 73.653-104.81 120.283-35.21 61.717-53.76 132.055-53.638 203.407.157 90.657 29.604 176.555 85.16 248.399a373.052 373.052 0 004.987 6.314c47.09 58.56 109.174 102.835 179.589 128.093a408.622 408.622 0 00138.435 24.08c51.58 0 101.92-9.585 149.631-28.488a407.087 407.087 0 0099.507-56.681 408.19 408.19 0 0069.897-68.826c58.171-73.05 89.593-165.009 88.49-258.95-.741-67.166-18.315-133.63-50.808-192.22a409.645 409.645 0 00-34.319-52.148c-46.729-60.46-109.107-106.402-180.398-132.866a408.689 408.689 0 00-142.604-25.578z"
              ></path>
              <path
                className={`fill-current ${"text-slate-800"}`}
                d="M626.225 703.43h-229.41a22.378 22.378 0 01-21.281-15.46L304.563 469.69a22.372 22.372 0 018.024-24.949l183.406-134.845a22.378 22.378 0 0126.301-.153L710.24 444.587a22.378 22.378 0 018.235 25.102L647.507 687.97a22.372 22.372 0 01-21.282 15.46zm-213.153-44.756h196.897l60.885-187.262-161.443-115.83-157.288 115.642 60.949 187.45zm96.166-401.467a22.282 22.282 0 01-14.111-5.023L344.243 129.398a22.33 22.33 0 01-8.629-17.65c0-12.36 10-22.379 22.362-22.379a22.479 22.479 0 0114.16 5.023l151.245 123.08a22.378 22.378 0 01-14.143 39.735zM80.598 537.483a22.378 22.378 0 01-10.55-42.13l113.668-60.613-58.63-120.868a22.382 22.382 0 0140.27-19.536l68.03 140.248a22.378 22.378 0 01-9.603 29.514L91.112 534.845a22.295 22.295 0 01-10.515 2.638zm284.476 403.832a22.382 22.382 0 01-22.177-19.606l-18.03-144.184-144.894 28.302c-12.12 2.382-23.881-5.54-26.253-17.672a22.378 22.378 0 0117.672-26.253l168.413-32.896a22.378 22.378 0 0126.493 19.188l21.004 167.965a22.378 22.378 0 01-22.228 25.156zm575.902-410.194a22.324 22.324 0 01-10.508-2.635L809.69 464.101a22.378 22.378 0 01-9.606-29.517l67.023-138.129c4.699-10.096 16.3-15.377 27.164-11.873 11.761 3.795 18.235 16.358 14.443 28.12l-.032.096a22.96 22.96 0 01-1.163 2.9L849.756 434.74l101.77 54.252a22.378 22.378 0 01-10.55 42.129zM668.968 936.66a22.366 22.366 0 01-22.206-25.17l20.4-163.297a22.385 22.385 0 0126.496-19.188l158.246 30.914a22.375 22.375 0 0117.673 26.253 22.366 22.366 0 01-26.253 17.672L708.6 777.528l-17.388 139.22a22.394 22.394 0 01-22.244 19.913z"
              ></path>
              <path
                className={`fill-current ${"text-slate-800"}`}
                d="M509.25 350.3a22.378 22.378 0 01-22.378-22.378V234.83a22.378 22.378 0 1144.757 0v93.093a22.378 22.378 0 01-22.378 22.379zm187.918 134.85a22.382 22.382 0 01-3.283-44.514L816.9 422.22a22.382 22.382 0 016.624 44.265L700.51 484.9a22.36 22.36 0 01-3.341.25zm-7.797 288.197a22.314 22.314 0 01-16.615-7.382l-63.139-69.916a22.378 22.378 0 0133.22-29.997l63.138 69.917a22.378 22.378 0 01-16.604 37.378zm-345.288 0a22.378 22.378 0 01-17.852-35.85l52.717-69.917a22.372 22.372 0 0131.343-4.395 22.378 22.378 0 014.395 31.339L361.97 764.44a22.35 22.35 0 01-17.886 8.907zM325.87 485.149c-1.199 0-2.414-.096-3.638-.297l-112.595-18.414a22.378 22.378 0 017.225-44.169l112.595 18.414a22.378 22.378 0 01-3.587 44.466z"
              ></path>
              <path
                className={`fill-current ${"text-slate-800"}`}
                d="M510.194 256.472a22.378 22.378 0 01-14.137-39.738l150.35-122.345a22.378 22.378 0 1128.248 34.718L524.308 251.452a22.314 22.314 0 01-14.114 5.02z"
              ></path>
            </svg>
          ) : icon === "casino" ? (
            <svg className="shrink-0 w-8 h-8 mr-2" viewBox="-4 0 20 16">
              <path
                className={`fill-current text-indigo-500`}
                d="M8.757 14H3.485a.41.41 0 01-.41-.41c0-4.122 2.27-5.955 4.514-8.198l1.108-1.108c-.258.034-.53.05-.818.05-1.154 0-2.151-.438-3.076-.438-.985 0-1.786.801-1.786 1.786a.41.41 0 01-.41.41H.41a.41.41 0 01-.41-.41V.41A.41.41 0 01.41 0h2.197a.41.41 0 01.41.41v.462C3.874.312 4.963 0 6.12 0c1.84 0 2.728.879 4.394.879a.47.47 0 00.468-.469.41.41 0 01.41-.41h2.197a.41.41 0 01.41.41c0 2.034-.978 3.46-2.013 4.97-1.322 1.926-2.82 4.11-2.82 8.21a.41.41 0 01-.41.41z"
              ></path>
            </svg>
          ) : icon === "bonus" ? (
            <GiftIcon className="w-8 h-8 mr-2" />
          ) : icon === "wagering" ? (
            <ForwardIcon className="w-8 h-8 mr-2" />
          ) : icon === "freespin" ? (
            <SparklesIcon className="w-8 h-8 mr-2" />
          ) : (
            icon
          )}
          <h2 className="text-lg font-semibold mb-2">{text}</h2>
        </header>
        <div className="flex items-center mb-2">
          {desc && (
            <div className="text-md font-semibold text-slate-400 dark:text-slate-500 uppercase mr-2">
              {desc}
              <br />
              {desc2 && <span className="text-xs">{desc2}</span>}
            </div>
          )}
          <div
            className={`${desc ? "text-2xl" : "text-3xl"} ${
              compare && compare !== 0
                ? compare > 0
                  ? "text-emerald-500"
                  : "text-rose-500"
                : " text-slate-800 dark:text-slate-100"
            } font-bold mr-2 grow min-w-0 text-right ${
              loading
                ? "animate-pulse h-8 bg-slate-100 dark:bg-slate-500 rounded"
                : ""
            }`}
          >
            {compare && compare !== 0
              ? compare > 0
                ? "+"
                : val.indexOf("-") === -1
                ? "-"
                : ""
              : ""}
            {val}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CardMaker;

import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const getData = (path) => {
  return axios.get(apiEndpoint + path);
};
const postDataForm = (path, data) => {
  return axios.post(apiEndpoint + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const postData = (path, data, blob = false) => {
  return axios.post(apiEndpoint + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: blob ? "arraybuffer" : undefined,
  });
};

const getConf = async () => {
  return await postData("/general/conf", {});
};
const getTranslations = async () => {
  return await getData("/general/translations");
};

const diagnostics = (err, navigate) => {
  if (err.response && err.response.status === 4010) {
    navigate("/");
  } else console.log(err);
  return false;
};
// eslint-disable-next-line
export default {
  getConf,
  getTranslations,
  postData,
  diagnostics,
  postDataForm,
};

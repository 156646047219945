import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useConfig } from "../context/ConfigContext";
import {
  KeyIcon,
  ClockIcon,
  GlobeAltIcon,
  BanknotesIcon,
} from "@heroicons/react/24/solid";

function AccountSidebar() {
  const ConfigProvider = useConfig();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 dark:border-slate-700 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">
          {ConfigProvider.getTranslation("Account")}
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to="/profile/changePassword"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.startsWith("/profile/changePassword") &&
                "bg-indigo-50 dark:bg-indigo-500/30"
              }`}
            >
              <KeyIcon
                className={`w-4 h-4 shrink-0 mr-2 ${
                  pathname.startsWith("/profile/changePassword")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              />

              <span
                className={`text-sm font-medium ${
                  pathname.startsWith("/profile/changePassword")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              >
                {ConfigProvider.getTranslation("ChangePassword")}
              </span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to="/profile/changeTimezone"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.startsWith("/profile/changeTimezone") &&
                "bg-indigo-50 dark:bg-indigo-500/30"
              }`}
            >
              <ClockIcon
                className={`w-4 h-4 shrink-0 mr-2 ${
                  pathname.startsWith("/profile/changeTimezone")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  pathname.startsWith("/profile/changeTimezone")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              >
                {ConfigProvider.getTranslation("TimeZones")}
              </span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to="/profile/language"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.startsWith("/profile/language") &&
                "bg-indigo-50 dark:bg-indigo-500/30"
              }`}
            >
              <GlobeAltIcon
                className={`w-4 h-4 shrink-0 mr-2 ${
                  pathname.startsWith("/profile/language")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  pathname.startsWith("/profile/language")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              >
                {ConfigProvider.getTranslation("Languages")}
              </span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to="/profile/currency"
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.startsWith("/profile/currency") &&
                "bg-indigo-50 dark:bg-indigo-500/30"
              }`}
            >
              <BanknotesIcon
                className={`w-4 h-4 shrink-0 mr-2 ${
                  pathname.startsWith("/profile/currency")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  pathname.startsWith("/profile/currency")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              >
                {ConfigProvider.getTranslation("Currency")}
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AccountSidebar;

import React, { createContext, useContext, useState } from "react";
import { useConfig } from "../context/ConfigContext";

const PrefencesContext = createContext();

export function PrefencesProvider({ children }) {
  const validValue = (key, val) => {
    if (
      key === "timezone" &&
      ConfigProvider.config.timezones.hasOwnProperty(val)
    ) {
      return true;
    } else if (key === "offset" && val > 0 && val <= 100) {
      return true;
    } else if (key === "audio" && (val === 0) | (val === 1)) {
      return true;
    } else return false;
  };
  const ConfigProvider = useConfig();
  const key = "prefences_bo";
  const savedData = localStorage.getItem(key);
  let defaultPrefences = {
    timezone: ConfigProvider.config.defaultTimezone,
    offset: 20,
    audio: 0,
  };
  if (savedData) {
    let data = JSON.parse(savedData);
    Object.keys(data).map((x) => {
      if (defaultPrefences.hasOwnProperty(x)) {
        let val = data[x];

        if (validValue(x, val)) {
          defaultPrefences[x] = val;
        }
      }
      return false;
    });
  }
  const [prefences, setPrefences] = useState(defaultPrefences);

  const savePrefences = (keys, value) => {
    if (validValue(keys, value)) {
      setPrefences((prevObject) => {
        prevObject[keys] = value;
        localStorage.setItem(key, JSON.stringify(prevObject));
        return prevObject;
      });
    }
  };

  return (
    <PrefencesContext.Provider value={{ prefences, savePrefences }}>
      {children}
    </PrefencesContext.Provider>
  );
}

export function usePrefences() {
  return useContext(PrefencesContext);
}

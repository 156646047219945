import React from "react";
import { CalendarIcon } from "@heroicons/react/24/outline";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import NoData from "../NoData";
import FinanceIcons from "./FinanceIcons";
function RecentTransactions({ data, creditDetail = false }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  return (
    <div className="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b bg-slate-100 dark:bg-slate-700 border-slate-200 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("RecentTransactions")}
          {data && data.length > 0 && (
            <div className="ml-1 inline-block text-sm font-semibold text-white px-1.5 bg-amber-500 rounded-full">
              {data.length}
            </div>
          )}
        </h2>
      </header>
      <div className="p-3">
        <div>
          <ul className="my-1">
            {data && data.length === 0 && <NoData />}
            {data
              ? data.map((item) => (
                  <li key={item._id} className="flex items-center px-2">
                    <FinanceIcons
                      creditDetail={creditDetail}
                      item={item}
                      classNames="my-2 mr-3 "
                    />
                    <div className="grow flex min-w-0 items-center gap-2 border-b border-slate-100 dark:border-slate-700 text-sm py-2">
                      <div className="text-left min-w-0 grow">
                        <div className="flex items-center text-xs w-full mb-0.5 text-slate-500">
                          <CalendarIcon className="w-4 h-4 mr-1" />
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            hour: "numeric",
                            year: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(item.date))}
                        </div>
                        <div className="truncate">{item.desc}</div>
                      </div>

                      <div className="shrink-0 text-right flex flex-col md:flex-row items-center">
                        {item.bonusAmount !== 0 && (
                          <span
                            className={`md:mr-2 text-xs ${
                              item.bonusAmount > 0
                                ? "text-emerald-500"
                                : item.bonusAmount < 0
                                ? "text-rose-500"
                                : ""
                            } font-medium`}
                          >
                            {item.strBonusAmount} {item.currency}
                          </span>
                        )}
                        <span
                          className={`${
                            item.amount > 0
                              ? "text-emerald-500"
                              : item.amount < 0
                              ? "text-rose-500"
                              : ""
                          } font-medium`}
                        >
                          {item.strAmount} {item.currency}
                        </span>
                      </div>
                    </div>
                  </li>
                ))
              : [1, 2, 3, 4, 5].map((item) => (
                  <li
                    key={item}
                    className="flex w-full mb-2 h-10 animate-pulse bg-slate-300 dark:bg-slate-500 rounded"
                  >
                    &nbsp;
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RecentTransactions;

import React from "react";
import CardLayout from "./helpers/CardLayout";
import Tickets from "../Tickets";
import { useParams } from "react-router-dom";

function TicketCard() {
  return (
    <CardLayout>
      <TicketCardPage />
    </CardLayout>
  );
}
function TicketCardPage() {
  const { _id } = useParams();
  return (
    <>
      <Tickets selCard={_id} />
    </>
  );
}

export default TicketCard;

import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import TreeMain from "../../components/Tree/TreeMain";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import DropdownAccount from "../../components/Search/DropdownAccount";

function JackpotList() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "JackpotsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 1,
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/jackpot/getJackpots", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  return (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <div>
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (formData.page !== 1) {
                  setFormData((prev) => {
                    return { ...prev, page: 1 };
                  });
                } else {
                  getItems();
                }
              }}
              className=""
            >
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-5">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                    {ConfigProvider.getTranslation("Jackpot")} ✨
                  </h1>
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {checkRole(ConfigProvider.config.user, "JackpotsEdit") && (
                    <Link
                      to="/jackpot/add"
                      className="btn bg-green-500 hover:bg-green-600 text-slate-100"
                    >
                      <svg
                        className="w-4 h-4 fill-slate-100 shrink-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                      <span className="hidden xs:block ml-2">
                        {ConfigProvider.getTranslation("Add")}
                      </span>
                    </Link>
                  )}
                </div>
              </div>
              {/* More actions */}
              <div className="lg:flex lg:justify-between lg:items-center mb-5">
                {/* Left side */}
                <div className="mb-4 lg:mb-0"></div>
                {/* Right side */}
                <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
                  {/* Search form */}
                  <SearchFilter
                    placeholder={ConfigProvider.getTranslation("Search")}
                    search={search}
                    setSearch={setSearch}
                  />

                  <button
                    type="submit"
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  >
                    <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="ml-2">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                  </button>
                </div>
              </div>
            </form>
            {/* Table */}
            <JackpotListTable
              data={data}
              setData={setData}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
            </div>
          </div>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  );
}
function JackpotListTable({ data, setData, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  const [confirmLoading, setConfirmLoading] = useState(false);
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Jackpot")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Name")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Global")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Shop")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={6}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Balance")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Currency")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("MinPayout")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("MaxPayout")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("StartLimit")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={6}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      % {ConfigProvider.getTranslation("Percent")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="text-center">&nbsp;</div>
                </th>
              </tr>
            </thead>

            {!loading && data && data.length === 0 && (
              <tbody className="text-sm">
                <tr>
                  <td colSpan="10">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm">
                <tr>
                  <td colSpan="10" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <JackpotListTableItem
                  key={item._id}
                  item={item}
                  loading={loading}
                  confirmLoading={confirmLoading}
                  setConfirmLoading={setConfirmLoading}
                  setData={setData}
                />
              ))}
          </table>
        </div>
      </div>
    </div>
  );
}

function JackpotListTableItem({
  item,
  loading,
  confirmLoading,
  setConfirmLoading,
  setData,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const removeJackpotShop = (item, val) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/jackpot/removeJackpotShop", {
        _id: item._id,
        shop: val,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const [selectedShop, setSelectedShop] = useState(null);
  const [selectedItemText, setSelectedItemText] = useState(null);
  const updateJackpotShop = (item, val) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/jackpot/updateJackpotShop", {
        _id: item._id,
        shop: val,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
            <Link
              className="text-sky-500 dark:text-sky-400"
              to={
                checkRole(ConfigProvider.config.user, "JackpotsEdit")
                  ? "/jackpot/edit/" + item._id
                  : ""
              }
            >
              {item.name}
            </Link>
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center">
          <div
            className={classNames(
              "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
              item.global
                ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                : "bg-slate-100 dark:bg-slate-800 text-slate-500 dark:text-slate-400"
            )}
          >
            {item.global
              ? ConfigProvider.getTranslation("Active")
              : ConfigProvider.getTranslation("Passive")}
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="w-full">
            {Object.keys(item.shops).map((key, ixx) => (
              <div key={ixx} className="flex items-center justify-between">
                <span className="mr-1">{item.shops[key]}</span>
                <Link
                  onClick={() => {
                    if (checkRole(ConfigProvider.config.user, "JackpotsEdit")) {
                      removeJackpotShop(item, key);
                      setData((prev) => {
                        const updatedItems = prev.map((subItem) => {
                          if (subItem._id === item._id) {
                            const updateItems = {
                              ...subItem.shops,
                            };
                            delete updateItems[key];
                            return {
                              ...subItem,
                              shops: updateItems,
                            };
                          }
                          return subItem;
                        });
                        return updatedItems;
                      });
                    }
                  }}
                  className="text-rose-500"
                >
                  <XMarkIcon className="w-4 fill-current" />
                </Link>
              </div>
            ))}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
            {item.balance}
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
            {item.currency}
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
            {item.minPayout}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
            {item.maxPayout}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
            {item.startLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
            % {item.percent}
          </div>
        </td>
        <td className="first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              type="button"
              className={`text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              aria-controls={`description-${item.id}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item.id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="10"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="lg:flex lg:space-x-2  items-center bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (selectedShop !== null) {
                    updateJackpotShop(item, selectedShop);

                    setData((prev) => {
                      const updatedItems = prev.map((subItem) => {
                        if (subItem._id === item._id) {
                          return {
                            ...subItem,
                            shops: {
                              ...subItem.shops,
                              [selectedShop]: selectedItemText,
                            },
                          };
                        }
                        return subItem;
                      });
                      return updatedItems;
                    });
                    setSelectedShop(null);
                  }
                }}
                className="grow p-6 space-y-6"
              >
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {`${ConfigProvider.getTranslation(
                    "Shop"
                  )} / ${ConfigProvider.getTranslation("Add")}`}
                </h2>
                <div className="relative grow">
                  <DropdownAccount
                    setSelectedItem={(val) => {
                      setSelectedShop(val);
                    }}
                    setSelectedItemText={(val) => {
                      setSelectedItemText(val);
                    }}
                    selectText={ConfigProvider.getTranslation(
                      "Drowndown.SelectOne"
                    )}
                  />
                </div>
                <footer>
                  <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn bg-emerald-500 hover:bg-emerald-600 text-white ml-3 flex items-center"
                      >
                        <span className="mr-2 uppercase">
                          {ConfigProvider.getTranslation("Add")}
                        </span>
                        {loading ? (
                          <span>
                            <LoadingSpinner className="w-4 h-4 mb-1" />
                          </span>
                        ) : (
                          <span>
                            <ArrowRightCircleIcon className="w-4 h-4" />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </footer>
              </form>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}
export default JackpotList;

import React, { useEffect, useState } from "react";
import {
  ArrowRightCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import DropdownFilter from "../../components/DropdownFilter";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Confirm from "../../components/Confirm";
import { useToastr } from "../../context/ToastrContext";
import Dropdown from "../../components/Dropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";

function CasinoGames() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CasinoManageCasinoGames")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 0,
    rootProvider: "-1",
    siteProvider: ConfigProvider.ObjectIdEmpty,
    provider: "-1",
    category: ConfigProvider.ObjectIdEmpty,
    casinoPage: ConfigProvider.ObjectIdEmpty,
    stat: "-1",
    freespin: "-1",
    isLive: "-1",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/casino/getCasinoGames", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  const [filterData, setFilterData] = useState(null);
  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = () => {
    Requests.postData("/casino/getCasinoGamesFilters", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setFilterData(res.data);
        }
      })
      .catch(() => {});
  };
  const allStat = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    1: ConfigProvider.getTranslation("Active"),
    0: ConfigProvider.getTranslation("Passive"),
  };
  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}

      {messages.length > 0 && (
        <div className="mt-2 mb-4">
          <Alert messages={messages} setMessages={setMessages} />
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
      >
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("CasinoGames")} ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />
            <DropdownFilter align="right">
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Status")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.stat === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            stat: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Freespin")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.freespin === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            freespin: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Live")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.isLive === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            isLive: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </DropdownFilter>

            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 space-y-2 lg:grow lg:mb-0 lg:flex lg:space-x-2 lg:space-y-0">
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="rootProvider"
                >
                  {ConfigProvider.getTranslation("RootProvider")}
                </label>
                <Dropdown
                  name="rootProvider"
                  items={filterData.rootProviders}
                  topItems={{
                    "-1": ConfigProvider.getTranslation("Filter.All"),
                  }}
                  selected={formData.rootProvider}
                  setSelected={(val) => {
                    setFormData({ ...formData, rootProvider: val });
                  }}
                />
              </div>
            )}
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="provider"
                >
                  {ConfigProvider.getTranslation("Provider")}
                </label>
                <Dropdown
                  name="provider"
                  items={filterData.providers}
                  topItems={{
                    "-1": ConfigProvider.getTranslation("Filter.All"),
                  }}
                  selected={formData.provider}
                  setSelected={(val) => {
                    setFormData({ ...formData, provider: val });
                  }}
                />
              </div>
            )}
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="siteProvider"
                >
                  {ConfigProvider.getTranslation("SiteProvider")}
                </label>
                <Dropdown
                  name="siteProvider"
                  items={filterData.ourProviders}
                  topItems={{
                    [ConfigProvider.ObjectIdEmpty]:
                      ConfigProvider.getTranslation("Filter.All"),
                    [ConfigProvider.ObjectIdUndefined]:
                      ConfigProvider.getTranslation("Undefined"),
                  }}
                  selected={formData.siteProvider}
                  setSelected={(val) => {
                    setFormData({ ...formData, siteProvider: val });
                  }}
                />
              </div>
            )}
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="casinoPage"
                >
                  {ConfigProvider.getTranslation("Page")}
                </label>
                <Dropdown
                  name="casinoPage"
                  items={filterData.casinoPages}
                  topItems={{
                    [ConfigProvider.ObjectIdEmpty]:
                      ConfigProvider.getTranslation("Filter.All"),
                    [ConfigProvider.ObjectIdUndefined]:
                      ConfigProvider.getTranslation("Undefined"),
                  }}
                  selected={formData.casinoPage}
                  setSelected={(val) => {
                    setFormData({ ...formData, casinoPage: val });
                  }}
                />
              </div>
            )}
            {filterData && (
              <div className="relative grow">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="category"
                >
                  {ConfigProvider.getTranslation("Category")}
                </label>
                <Dropdown
                  name="category"
                  items={filterData.casinoCategories}
                  topItems={{
                    [ConfigProvider.ObjectIdEmpty]:
                      ConfigProvider.getTranslation("Filter.All"),
                    [ConfigProvider.ObjectIdUndefined]:
                      ConfigProvider.getTranslation("Undefined"),
                  }}
                  selected={formData.category}
                  setSelected={(val) => {
                    setFormData({ ...formData, category: val });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </form>

      {/* Table */}
      <CasinoGamesTable
        data={data}
        setData={setData}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
        getItems={getItems}
        filterData={filterData}
        search={search}
      />

      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}
function CasinoGamesTable({
  data,
  setData,
  loading,
  formData,
  setFormData,
  filterData,
  getItems,
  search,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmItem, setConfirmItem] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const setCasinoGame = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/casino/setCasinoGame", {
        _id: confirmItem.data._id,
        type: confirmItem.type,
        val:
          confirmItem.type === 0
            ? !confirmItem.data.activeSite
            : !confirmItem.data.freespin,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            setConfirmItem(null);
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowConfirm(false);
          setConfirmItem(null);
        });
    }
  };
  const [allItemSiteProvider, setAllItemSiteProvider] = useState(null);
  const [allItemPage, setAllItemPage] = useState(null);
  const [allItemExtraCategory, setAllItemExtraCategory] = useState(null);

  const setCasinoGameProviderAll = () => {
    if (allItemSiteProvider != null) {
      if (!loading) {
        setConfirmLoading(true);
        const postData = formData;
        postData.search = search;
        postData.p = allItemSiteProvider;
        Requests.postData("/casino/setCasinoGameProviderAll", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res) &&
                ToastrContext.showToast({
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                });
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              ToastrContext.showToast({
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation(
                      "Forms.SuccessMessageMessage"
                    ),
              });
              setAllItemSiteProvider(null);
              getItems();
            }
            setConfirmLoading(false);
          })
          .catch(() => {
            ToastrContext.showToast({
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
            setConfirmLoading(false);
          });
      }
    }
  };
  const setCasinoPageAll = () => {
    if (allItemPage != null) {
      if (!loading) {
        setConfirmLoading(true);
        const postData = formData;
        postData.search = search;
        postData.p = allItemPage;
        Requests.postData("/casino/setCasinoPageAll", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res) &&
                ToastrContext.showToast({
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                });
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              ToastrContext.showToast({
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation(
                      "Forms.SuccessMessageMessage"
                    ),
              });
              setAllItemPage(null);
              getItems();
            }
            setConfirmLoading(false);
          })
          .catch(() => {
            ToastrContext.showToast({
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
            setConfirmLoading(false);
          });
      }
    }
  };
  const setCasinoExtraCategoryAll = () => {
    if (allItemExtraCategory != null) {
      if (!loading) {
        setConfirmLoading(true);
        const postData = formData;
        postData.search = search;
        postData.p = allItemExtraCategory;
        Requests.postData("/casino/setCasinoExtraCategoryAll", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res) &&
                ToastrContext.showToast({
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                });
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              ToastrContext.showToast({
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation(
                      "Forms.SuccessMessageMessage"
                    ),
              });
              setAllItemExtraCategory(null);
              getItems();
            }
            setConfirmLoading(false);
          })
          .catch(() => {
            ToastrContext.showToast({
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
            setConfirmLoading(false);
          });
      }
    }
  };
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("CasinoGames")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Icon")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Name")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">&nbsp;</div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("SortOrder")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">&nbsp;</div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("RootProvider")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Provider")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("SiteProvider")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("FreeSpin")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Category")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Page")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">&nbsp;</div>
                </th>
              </tr>
            </thead>

            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="13">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="13" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <CasinoGamesTableItem
                  key={item._id}
                  item={item}
                  setData={setData}
                  loading={loading}
                  filterData={filterData}
                  getItems={getItems}
                  confirmLoading={confirmLoading}
                  setConfirmLoading={setConfirmLoading}
                  setConfirmItem={setConfirmItem}
                  setShowConfirm={setShowConfirm}
                />
              ))}
            <tfoot className="text-sm dark:bg-slate-900/70">
              <tr>
                <td colSpan="7">&nbsp;</td>
                <td colSpan="6">
                  {filterData && (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setCasinoGameProviderAll();
                        }}
                        className="flex gap-2 p-3 items-center justify-center"
                      >
                        <div className="flex flex-col items-end justify-end">
                          <span>
                            {ConfigProvider.getTranslation("UpdateAll")}
                          </span>
                          <span className="text-xs">
                            ({ConfigProvider.getTranslation("SiteProvider")})
                          </span>
                        </div>
                        <div className="grow">
                          <Dropdown
                            name="siteProvider"
                            items={filterData.ourProviders}
                            topItems={{
                              [ConfigProvider.ObjectIdEmpty]:
                                ConfigProvider.getTranslation("None"),
                            }}
                            selected={allItemSiteProvider}
                            setSelected={(val) => {
                              setAllItemSiteProvider(val);
                            }}
                            align="top"
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                          >
                            <span className="mr-2 uppercase">
                              {ConfigProvider.getTranslation("Button.Send")}
                            </span>
                            {loading ? (
                              <span>
                                <LoadingSpinner className="w-4 h-4 mb-1" />
                              </span>
                            ) : (
                              <span>
                                <ArrowRightCircleIcon className="w-4 h-4" />
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setCasinoPageAll();
                        }}
                        className="flex gap-2 p-3 items-center justify-center"
                      >
                        <div className="flex flex-col items-end justify-end">
                          <span>
                            {ConfigProvider.getTranslation("UpdateAll")}
                          </span>
                          <span className="text-xs">
                            ({ConfigProvider.getTranslation("Page")})
                          </span>
                        </div>
                        <div className="grow">
                          <Dropdown
                            name="page"
                            items={filterData.casinoPages}
                            align="top"
                            selected={allItemPage}
                            setSelected={(val) => {
                              setAllItemPage(val);
                            }}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                          >
                            <span className="mr-2 uppercase">
                              {ConfigProvider.getTranslation("Button.Send")}
                            </span>
                            {loading ? (
                              <span>
                                <LoadingSpinner className="w-4 h-4 mb-1" />
                              </span>
                            ) : (
                              <span>
                                <ArrowRightCircleIcon className="w-4 h-4" />
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setCasinoExtraCategoryAll();
                        }}
                        className="flex gap-2 p-3 items-center justify-center"
                      >
                        <div className="flex flex-col items-end justify-end">
                          <span>
                            {ConfigProvider.getTranslation("UpdateAll")}
                          </span>
                          <span className="text-xs">
                            ({ConfigProvider.getTranslation("Category")})
                          </span>
                        </div>
                        <div className="grow">
                          <Dropdown
                            name="extraCategory"
                            items={filterData.casinoCategories}
                            align="top"
                            selected={allItemExtraCategory}
                            setSelected={(val) => {
                              setAllItemExtraCategory(val);
                            }}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                          >
                            <span className="mr-2 uppercase">
                              {ConfigProvider.getTranslation("Button.Send")}
                            </span>
                            {loading ? (
                              <span>
                                <LoadingSpinner className="w-4 h-4 mb-1" />
                              </span>
                            ) : (
                              <span>
                                <ArrowRightCircleIcon className="w-4 h-4" />
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {showConfirm && confirmItem !== null && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{`${confirmItem.data.displayName}`}</p>
              {confirmItem.type === 0 && (
                <p>{`${ConfigProvider.getTranslation("Status")} -> ${
                  confirmItem.data.activeSite
                    ? ConfigProvider.getTranslation("Passive")
                    : ConfigProvider.getTranslation("Active")
                }`}</p>
              )}
              {confirmItem.type === 1 && (
                <p>{`${ConfigProvider.getTranslation("Freespin")} -> ${
                  confirmItem.data.freespin
                    ? ConfigProvider.getTranslation("Passive")
                    : ConfigProvider.getTranslation("Active")
                }`}</p>
              )}
            </>
          }
          confirm={() => {
            setCasinoGame();
          }}
          callback={() => {
            setShowConfirm(false);
            setConfirmItem(null);
          }}
        />
      )}
    </div>
  );
}
function CasinoGamesTableItem({
  item,
  setData,
  filterData,
  loading,
  getItems,
  confirmLoading,
  setConfirmLoading,
  setConfirmItem,
  setShowConfirm,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const sortCasinoGame = (item, up) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/casino/sortCasinoGame", {
        _id: item._id,
        up: up,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const setCasinoGameProvider = (item, val) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/casino/setCasinoGameProvider", {
        _id: item._id,
        siteProvider: val,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const unsetCategoryOrPage = (item, val, type) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/casino/unsetCategoryOrPage", {
        _id: item._id,
        name: val,
        type,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const setCategoryOrPage = (item, val, type) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/casino/setCategoryOrPage", {
        _id: item._id,
        name: val,
        type,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const updateCasinoGame = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/casino/updateCasinoGame", {
        _id: cloneItem._id,
        imageUrl: cloneItem.imageUrl,
        displayName: cloneItem.displayName,
        order: cloneItem.order,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
            setData((prev) => {
              const updatedItems = prev.map((subItem) => {
                if (subItem._id === cloneItem._id) {
                  return {
                    ...subItem,
                    imageUrl: cloneItem.imageUrl,
                    displayName: cloneItem.displayName,
                    order: cloneItem.order,
                  };
                }
                return subItem;
              });
              return updatedItems;
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  const [cloneItem, setCloneItem] = useState(item);
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setCloneItem({ ...cloneItem, [name]: value });
    }
  };
  const [selectedPage, setselectedPage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center inline-block">
            <img
              srcSet={item.imageUrl}
              alt="No IMG"
              className="min-w-[120px]"
            />
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left flex flex-col">
            <div>
              <Link
                onClick={() => setDescriptionOpen(!descriptionOpen)}
                className="text-sky-500 dark:text-sky-400 font-semibold"
              >
                {item.displayName}
              </Link>
            </div>
            <div>
              <span className="font-semibold">Desktop Id:</span>
              {item.gameId}
            </div>
            <div>
              <span className="font-semibold">Mobile Id:</span>
              {item.mobileId}
            </div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link
            className="text-center hover:text-indigo-500"
            onClick={() => {
              sortCasinoGame(item, false);
            }}
          >
            <ChevronDownIcon className="w-4 h-4 fill-current" />
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{item.order}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link
            className="text-center hover:text-indigo-500"
            onClick={() => {
              sortCasinoGame(item, true);
            }}
          >
            <ChevronUpIcon className="w-4 h-4 fill-current" />
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{item.rootProvider}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{item.provider}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">
            {filterData && (
              <Dropdown
                name="siteProvider"
                items={filterData.ourProviders}
                topItems={{
                  [ConfigProvider.ObjectIdEmpty]:
                    ConfigProvider.getTranslation("None"),
                }}
                selected={item.siteProvider}
                setSelected={(val) => {
                  setCasinoGameProvider(item, val);
                  setData((prev) => {
                    const updatedItems = prev.map((subItem) => {
                      if (subItem._id === item._id) {
                        return { ...subItem, siteProvider: val };
                      }
                      return subItem;
                    });
                    return updatedItems;
                  });
                }}
              />
            )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center justify-center">
            <Link
              className={classNames(
                "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
                item.freespin
                  ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                  : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
              )}
              onClick={() => {
                setConfirmItem({ type: 1, data: item });
                setShowConfirm(true);
              }}
            >
              {item.freespin
                ? ConfigProvider.getTranslation("Active")
                : ConfigProvider.getTranslation("Passive")}
            </Link>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="w-full">
            {Object.keys(item.extraCategories).map((key, ixx) => (
              <div key={ixx} className="flex items-center justify-between">
                <span>{item.extraCategories[key]}</span>
                <Link
                  onClick={() => {
                    unsetCategoryOrPage(item, key, 0);
                    setData((prev) => {
                      const updatedItems = prev.map((subItem) => {
                        if (subItem._id === item._id) {
                          const updateItems = {
                            ...subItem.extraCategories,
                          };
                          delete updateItems[key];
                          return {
                            ...subItem,
                            extraCategories: updateItems,
                          };
                        }
                        return subItem;
                      });
                      return updatedItems;
                    });
                  }}
                  className="text-rose-500"
                >
                  <XMarkIcon className="w-4 fill-current" />
                </Link>
              </div>
            ))}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="w-full">
            {Object.keys(item.pages).map((key, ixx) => (
              <div key={ixx} className="flex items-center justify-between">
                <span>{item.pages[key]}</span>
                <Link
                  onClick={() => {
                    unsetCategoryOrPage(item, key, 1);
                    setData((prev) => {
                      const updatedItems = prev.map((subItem) => {
                        if (subItem._id === item._id) {
                          const updateItems = {
                            ...subItem.pages,
                          };
                          delete updateItems[key];
                          return {
                            ...subItem,
                            pages: updateItems,
                          };
                        }
                        return subItem;
                      });
                      return updatedItems;
                    });
                  }}
                  className="text-rose-500"
                >
                  <XMarkIcon className="w-4 fill-current" />
                </Link>
              </div>
            ))}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center justify-center">
            <Link
              className={classNames(
                "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
                item.activeSite
                  ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                  : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
              )}
              onClick={() => {
                setConfirmItem({ type: 0, data: item });
                setShowConfirm(true);
              }}
            >
              {item.activeSite
                ? ConfigProvider.getTranslation("Active")
                : ConfigProvider.getTranslation("Passive")}
            </Link>
          </div>
        </td>
        <td className="first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              type="button"
              className={`text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              aria-controls={`description-${item.id}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item.id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="13"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="lg:flex lg:space-x-2  items-center bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              <div className="min-w-[400px]">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (selectedCategory !== null) {
                      setCategoryOrPage(item, selectedCategory, 0);

                      setData((prev) => {
                        const updatedItems = prev.map((subItem) => {
                          if (subItem._id === item._id) {
                            return {
                              ...subItem,
                              extraCategories: {
                                ...subItem.extraCategories,
                                [selectedCategory]:
                                  filterData.casinoCategories[selectedCategory],
                              },
                            };
                          }
                          return subItem;
                        });
                        return updatedItems;
                      });
                      setSelectedCategory(null);
                    }
                  }}
                  className="p-6 space-y-6"
                >
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                    {`${ConfigProvider.getTranslation(
                      "Category"
                    )} / ${ConfigProvider.getTranslation("Add")}`}
                  </h2>
                  {filterData && (
                    <div className="relative grow">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="category"
                      >
                        {ConfigProvider.getTranslation("Category")}
                      </label>
                      <Dropdown
                        name="category"
                        items={filterData.casinoCategories}
                        selected={selectedCategory}
                        setSelected={(val) => {
                          setSelectedCategory(val);
                        }}
                      />
                    </div>
                  )}
                  <footer>
                    <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn bg-emerald-500 hover:bg-emerald-600 text-white ml-3 flex items-center"
                        >
                          <span className="mr-2 uppercase">
                            {ConfigProvider.getTranslation("Add")}
                          </span>
                          {loading ? (
                            <span>
                              <LoadingSpinner className="w-4 h-4 mb-1" />
                            </span>
                          ) : (
                            <span>
                              <ArrowRightCircleIcon className="w-4 h-4" />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </footer>
                </form>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (selectedPage !== null) {
                      setCategoryOrPage(item, selectedPage, 1);

                      setData((prev) => {
                        const updatedItems = prev.map((subItem) => {
                          if (subItem._id === item._id) {
                            return {
                              ...subItem,
                              pages: {
                                ...subItem.pages,
                                [selectedPage]:
                                  filterData.casinoPages[selectedPage],
                              },
                            };
                          }
                          return subItem;
                        });
                        return updatedItems;
                      });
                      setselectedPage(null);
                    }
                  }}
                  className="p-6 space-y-6"
                >
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                    {`${ConfigProvider.getTranslation(
                      "Page"
                    )} / ${ConfigProvider.getTranslation("Add")}`}
                  </h2>
                  {filterData && (
                    <div className="relative grow">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="page"
                      >
                        {ConfigProvider.getTranslation("Page")}
                      </label>
                      <Dropdown
                        name="page"
                        items={filterData.casinoPages}
                        selected={selectedPage}
                        setSelected={(val) => {
                          setselectedPage(val);
                        }}
                      />
                    </div>
                  )}
                  <footer>
                    <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn bg-emerald-500 hover:bg-emerald-600 text-white ml-3 flex items-center"
                        >
                          <span className="mr-2 uppercase">
                            {ConfigProvider.getTranslation("Add")}
                          </span>
                          {loading ? (
                            <span>
                              <LoadingSpinner className="w-4 h-4 mb-1" />
                            </span>
                          ) : (
                            <span>
                              <ArrowRightCircleIcon className="w-4 h-4" />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </footer>
                </form>
              </div>
              <div className="grow">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateCasinoGame();
                  }}
                  className="p-6 space-y-6"
                >
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                    {ConfigProvider.getTranslation("Edit")}
                  </h2>

                  <section>
                    <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                      <div className="relative col-span-2">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="displayName"
                        >
                          {ConfigProvider.getTranslation("Name")}
                        </label>
                        <input
                          id="displayName"
                          name="displayName"
                          type="text"
                          value={cloneItem.displayName}
                          className="w-full form-input"
                          placeholder={ConfigProvider.getTranslation("Name")}
                          required
                          maxLength={255}
                          disabled={loading}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                      <div className="relative col-span-2">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="order"
                        >
                          {ConfigProvider.getTranslation("SortOrder")}
                        </label>
                        <input
                          id="order"
                          name="order"
                          type="number"
                          value={cloneItem.order}
                          className="w-full form-input"
                          placeholder={ConfigProvider.getTranslation(
                            "SortOrder"
                          )}
                          required
                          maxLength={50}
                          disabled={loading}
                          onChange={handleChange}
                          step={1}
                        />
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                      <div className="relative col-span-2">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="imageUrl"
                        >
                          {ConfigProvider.getTranslation("Thumbnail")}
                        </label>
                        <input
                          id="imageUrl"
                          name="imageUrl"
                          type="text"
                          value={cloneItem.imageUrl}
                          className="w-full form-input"
                          placeholder={ConfigProvider.getTranslation(
                            "Thumbnail"
                          )}
                          maxLength={255}
                          disabled={loading}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </section>
                  <footer>
                    <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                      <div className="flex justify-start">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                        >
                          <span className="mr-2 uppercase">
                            {ConfigProvider.getTranslation("Button.Send")}
                          </span>
                          {loading ? (
                            <span>
                              <LoadingSpinner className="w-4 h-4 mb-1" />
                            </span>
                          ) : (
                            <span>
                              <ArrowRightCircleIcon className="w-4 h-4" />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </footer>
                </form>
              </div>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}
export default CasinoGames;

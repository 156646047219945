import React from "react";
import { useConfig } from "../../context/ConfigContext";
import AccountSidebar from "../../components/AccountSidebar";
import Requests from "../../helpers/Requests";

function AccountCurrency() {
  const ConfigProvider = useConfig();
  return (
    <>
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
          {ConfigProvider.getTranslation("Tab.Account")} ✨
        </h1>
      </div>

      {/* Content */}
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <AccountSidebar />
          <AccountCurrencyPage />
        </div>
      </div>
    </>
  );
}

function AccountCurrencyPage() {
  const ConfigProvider = useConfig();

  const changeCurrency = async (item) => {
    Requests.postData("/user/setCurrency", { currency: item })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        window.location.reload();
      });
  };
  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
          {ConfigProvider.getTranslation("Currency")}
        </h2>

        {/* Currency */}
        <section>
          <div className="mt-5">
            <ul>
              {Object.keys(ConfigProvider.config.currencies).map((key) => (
                <li
                  key={key}
                  className={`flex items-center py-3 pl-3 border-b border-slate-200 dark:border-slate-700 cursor-pointer ${
                    key === ConfigProvider.config.user.curSession
                      ? "bg-slate-300/10"
                      : ""
                  }`}
                  onClick={() => changeCurrency(key)}
                >
                  {ConfigProvider.config.currencies[key]}
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AccountCurrency;

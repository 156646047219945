import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
function SortByItem({ children, index, formData, setFormData }) {
  return (
    <div
      className="flex w-full justify-between cursor-pointer"
      onClick={() => {
        setFormData({
          ...formData,
          sortBy: formData.sortBy === 1 ? 0 : 1,
          sort: index,
          page: 1,
        });
      }}
    >
      {children}
      {formData.sort === index &&
        (formData.sortBy === 1 ? (
          <ChevronDownIcon className="ml-2 w-4 h-4" />
        ) : (
          <ChevronUpIcon className="ml-2 w-4 h-4" />
        ))}
    </div>
  );
}

export default SortByItem;

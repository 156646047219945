import React from "react";
import CardLayout from "./helpers/CardLayout";
import BalanceTransactions from "../finance/BalanceTransactions";
import { useParams } from "react-router-dom";
function BalanceTransactionsCard() {
  return (
    <CardLayout>
      <BalanceTransactionsCardPage />
    </CardLayout>
  );
}
function BalanceTransactionsCardPage() {
  const { _id } = useParams();
  return (
    <>
      <BalanceTransactions selCard={_id} />
    </>
  );
}

export default BalanceTransactionsCard;

import {
  ArrowUturnLeftIcon,
  BanknotesIcon,
  DocumentTextIcon,
  ForwardIcon,
  GiftIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as CashoutSVG } from "../../assets/img/cashout.svg";
import React from "react";

import { ReactComponent as DiamondSVG } from "../../assets/img/diamond.svg";

function FinanceIcons({ item, creditDetail = false, classNames }) {
  return (
    <div
      className={`${
        item.amount > 0
          ? "bg-emerald-500"
          : item.amount < 0
          ? "bg-rose-500"
          : "bg-amber-500"
      } w-9 h-9 rounded-full shrink-0 flex items-center justify-center ${classNames}`}
    >
      {creditDetail ? (
        <>
          <BanknotesIcon className="w-5 h-5 text-white" />
        </>
      ) : (
        <>
          {item.comment === 7 ? (
            <CashoutSVG className="shrink-0 w-4 h-4 fill-current text-white" />
          ) : item.comment === 8 ? (
            <DiamondSVG className="shrink-0 w-4 h-4 text-white" />
          ) : item.comment === 21 ? (
            <ArrowUturnLeftIcon className="w-4 h-4 text-white" />
          ) : (item.comment === 22) | (item.comment === 23) ? (
            <ForwardIcon className="w-4 h-4 text-white" />
          ) : item.comment === 24 ? (
            <GiftIcon className="w-4 h-4 text-white" />
          ) : item.comment === 25 ? (
            <SparklesIcon className="w-4 h-4 text-white" />
          ) : item.cj === 1 ? (
            <svg className="shrink-0 w-4 h-4" viewBox="-3 -1 20 16">
              <path
                className={`fill-current text-white`}
                d="M8.757 14H3.485a.41.41 0 01-.41-.41c0-4.122 2.27-5.955 4.514-8.198l1.108-1.108c-.258.034-.53.05-.818.05-1.154 0-2.151-.438-3.076-.438-.985 0-1.786.801-1.786 1.786a.41.41 0 01-.41.41H.41a.41.41 0 01-.41-.41V.41A.41.41 0 01.41 0h2.197a.41.41 0 01.41.41v.462C3.874.312 4.963 0 6.12 0c1.84 0 2.728.879 4.394.879a.47.47 0 00.468-.469.41.41 0 01.41-.41h2.197a.41.41 0 01.41.41c0 2.034-.978 3.46-2.013 4.97-1.322 1.926-2.82 4.11-2.82 8.21a.41.41 0 01-.41.41z"
              ></path>
            </svg>
          ) : item.cj === 2 ? (
            <DocumentTextIcon className="w-4 h-4 text-white" />
          ) : (
            <BanknotesIcon className="w-5 h-5 text-white" />
          )}
        </>
      )}
    </div>
  );
}

export default FinanceIcons;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  ArrowRightCircleIcon,
  CodeBracketIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import Confirm from "../../components/Confirm";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropdown from "../../components/Dropdown";

function UpsertEmailTemplate() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CMSManageEmailTemplates")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    subject: "",
    lan: ConfigProvider.config.lan,
    type: null,
    active: true,
    detail: "",
    _id: _id ? _id : undefined,
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);
      let postData = formData;

      Requests.postData("/cms/updateEmailTemplate", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              navigate(`/cms/emailTemplates`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  useEffect(() => {
    if (formData._id) {
      Requests.postData("/cms/getEmailTemplateInfo", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setFormData((prev) => {
              return {
                ...prev,
                subject: res.data.subject,
                detail: res.data.detail,
                active: res.data.active,
                type: res.data.type,
                lan: res.data.lan,
              };
            });
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [formData._id]);
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteItem = () => {
    if (formData._id && !loading) {
      setLoading(true);
      Requests.postData("/cms/deleteEmailTemplate", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            navigate("/cms/emailTemplates");
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setLoading(false);
          setShowConfirm(false);
        });
    }
  };
  const allLanguages = ConfigProvider.config.languages;
  const allTypes = {
    0: ConfigProvider.getTranslation("Register"),
    1: ConfigProvider.getTranslation("LostPassword"),
  };
  const [enabledHTML, setEnabledHTML] = useState(false);

  return (
    <div className="grow mx-auto">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("EmailTemplates")}
        {" / "}
        {formData._id
          ? ConfigProvider.getTranslation("Edit")
          : ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>

          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="title"
                >
                  {ConfigProvider.getTranslation("Subject")}
                </label>
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Subject")}
                  required
                  disabled={loading}
                />
              </div>

              <div className="relative">
                <label className="block text-sm font-medium mb-1" htmlFor="lan">
                  {ConfigProvider.getTranslation("Type")}
                </label>
                <Dropdown
                  name="type"
                  items={allTypes}
                  selected={formData.type}
                  setSelected={(val) => {
                    setFormData({ ...formData, type: val });
                  }}
                />
              </div>
              <div className="relative">
                <label className="block text-sm font-medium mb-1" htmlFor="lan">
                  {ConfigProvider.getTranslation("Language")}
                </label>
                <Dropdown
                  name="lan"
                  items={allLanguages}
                  selected={formData.lan}
                  setSelected={(val) => {
                    setFormData({ ...formData, lan: val });
                  }}
                />
              </div>

              <div className="relative col-span-2">
                <div className="flex items-center my-2">
                  <div className="form-switch">
                    <input
                      id="active"
                      name="active"
                      type="checkbox"
                      className="sr-only"
                      checked={formData.active}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          active: !formData.active,
                        })
                      }
                      disabled={loading}
                    />
                    <label
                      className="bg-slate-400 dark:bg-slate-700"
                      htmlFor="active"
                    >
                      <span
                        className="bg-white shadow-sm"
                        aria-hidden="true"
                      ></span>
                    </label>
                  </div>
                  <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                    {formData.active
                      ? ConfigProvider.getTranslation("Active")
                      : ConfigProvider.getTranslation("Passive")}
                  </div>
                </div>
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="detail"
                >
                  {ConfigProvider.getTranslation("Detail")}:{" "}
                  {"{username}, {name}, {sname}, {link}"}
                </label>
                <div className="bg-white w-full text-black">
                  <button
                    type="button"
                    className={`p-2 flex items-center hover:text-blue-500 ${
                      enabledHTML ? "text-blue-600" : ""
                    }`}
                    onClick={() => setEnabledHTML(!enabledHTML)}
                  >
                    <span className="mx-2">HTML</span>
                    <CodeBracketIcon className="w-4 h-4" />
                  </button>
                  {enabledHTML ? (
                    <textarea
                      value={formData.detail}
                      onChange={(e) => {
                        setFormData((prev) => {
                          return { ...prev, detail: e.target.value };
                        });
                      }}
                      className="w-full min-h-[500px]"
                    />
                  ) : (
                    <ReactQuill
                      theme="snow"
                      value={formData.detail}
                      onChange={(val) => {
                        setFormData((prev) => {
                          return { ...prev, detail: val };
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              {formData._id ? (
                <button
                  type="button"
                  disabled={loading}
                  className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                >
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <XMarkIcon className="w-4 h-4" />
                    </span>
                  )}
                  <span className="ml-2 uppercase">
                    {ConfigProvider.getTranslation("Delete")}
                  </span>
                </button>
              ) : (
                <>&nbsp;</>
              )}
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>

      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("ToContinuePleaseConfirm")}</p>
            </>
          }
          confirm={() => {
            deleteItem();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}

export default UpsertEmailTemplate;

import React from "react";
import { useConfig } from "../../context/ConfigContext";

function CasinoTotals({ item }) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative mt-2 flex flex-col gap-2 px-3 py-2">
      {item.total && (
        <div>{`${ConfigProvider.getTranslation("Total")}: ${item.total} ${
          item.currency
        }`}</div>
      )}
      {item.slot && (
        <div>{`${ConfigProvider.getTranslation("Casino")}: ${item.slot} ${
          item.currency
        }`}</div>
      )}
      {item.liveCasino && (
        <div>{`${ConfigProvider.getTranslation("LiveCasino")}: ${
          item.liveCasino
        } ${item.currency}`}</div>
      )}
      {item.bonusWageringBet && (
        <div>{`${ConfigProvider.getTranslation("BonusWageringBet")}: ${
          item.bonusWageringBet
        } ${item.currency}`}</div>
      )}
      {item.bonusWageringWin && (
        <div>{`${ConfigProvider.getTranslation("BonusWageringWin")}: ${
          item.bonusWageringWin
        } ${item.currency}`}</div>
      )}
      {item.bonusAward && (
        <div>{`${ConfigProvider.getTranslation("BonusAward")}: ${
          item.bonusAward
        } ${item.currency}`}</div>
      )}
      {item.freespinWin && (
        <div>{`${ConfigProvider.getTranslation("Freespin")}: ${
          item.freespinWin
        } ${item.currency}`}</div>
      )}
      {item.jackpot && (
        <div>{`${ConfigProvider.getTranslation("Jackpot")}: ${item.jackpot} ${
          item.currency
        }`}</div>
      )}
    </div>
  );
}

export default CasinoTotals;

import React, { useEffect, useState } from "react";
import {
  ArrowRightCircleIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";
import { useConfig } from "../../context/ConfigContext";
import Requests from "../../helpers/Requests";
import { useParams } from "react-router-dom";
import { useToastr } from "../../context/ToastrContext";
import {
  addDays,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import CardLayout from "./helpers/CardLayout";

import { ReactComponent as RouletteSVG } from "../../assets/img/roulette.svg";
import { ReactComponent as DiamondSVG } from "../../assets/img/diamond.svg";
import CardMaker from "../../components/CardMaker";
function SummaryCard() {
  return (
    <CardLayout>
      <SummaryCardPage />
    </CardLayout>
  );
}
function SummaryCardPage() {
  const ConfigProvider = useConfig();
  const { _id } = useParams();
  const ToastrContext = useToastr();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -5), false),
    date2: formatDate(addDays(getDate(), 1), false),
    _id: _id,
  });
  const [data, setData] = useState(null);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/cards/getCardSummary", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          getItems();
        }}
        className="p-4"
      >
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("Summary")} ✨
            </h1>
          </div>
          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 grow flex flex-col lg:flex-row lg:mb-0 gap-4 mr-2">
            <div className="grow max-w-[300px]"></div>
          </div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            <input
              id="date1"
              name="date1"
              type="date"
              value={formData.date1}
              onChange={(e) => {
                return setFormData({ ...formData, date1: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("StartDate")}
              disabled={loading}
              required
            />
            <input
              id="date2"
              name="date2"
              type="date"
              value={formData.date2}
              onChange={(e) => {
                return setFormData({ ...formData, date2: e.target.value });
              }}
              className="w-full form-input"
              placeholder={ConfigProvider.getTranslation("EndDate")}
              disabled={loading}
              required
            />
          </div>
        </div>
      </form>

      <div className="grid grid-cols-12 gap-6 p-2">
        <CardMaker
          icon="money"
          text={ConfigProvider.getTranslation("Payment")}
          val={
            !loading && data ? (
              <span className="text-emerald-500">{`${data.payment1} ${data.currency}`}</span>
            ) : (
              ""
            )
          }
          desc={ConfigProvider.getTranslation("Income")}
          loading={loading}
        />
        <CardMaker
          icon="money"
          text={ConfigProvider.getTranslation("Payment")}
          val={
            !loading && data ? (
              <span className="text-rose-500">{`${data.payment2} ${data.currency}`}</span>
            ) : (
              ""
            )
          }
          desc={ConfigProvider.getTranslation("Expense")}
          loading={loading}
        />
        <CardMaker
          icon="money"
          text={ConfigProvider.getTranslation("Payment")}
          val={!loading && data ? `${data.payment} ${data.currency}` : ""}
          desc={ConfigProvider.getTranslation("NET")}
          loading={loading}
        />
        <CardMaker
          icon={<ArrowUturnLeftIcon className="w-8 h-8 mr-2" />}
          text={`${ConfigProvider.getTranslation(
            "Discount"
          )} / ${ConfigProvider.getTranslation("NoWageringCredit")}`}
          val={
            !loading && data ? (
              <span className="text-emerald-500">{`${data.discount1} ${data.currency}`}</span>
            ) : (
              ""
            )
          }
          desc={ConfigProvider.getTranslation("Income")}
          loading={loading}
        />
        <CardMaker
          icon={<ArrowUturnLeftIcon className="w-8 h-8 mr-2" />}
          text={`${ConfigProvider.getTranslation(
            "Discount"
          )} / ${ConfigProvider.getTranslation("NoWageringCredit")}`}
          val={
            !loading && data ? (
              <span className="text-rose-500">{`${data.discount2} ${data.currency}`}</span>
            ) : (
              ""
            )
          }
          desc={ConfigProvider.getTranslation("Expense")}
          loading={loading}
        />
        <CardMaker
          icon={<ArrowUturnLeftIcon className="w-8 h-8 mr-2" />}
          text={`${ConfigProvider.getTranslation(
            "Discount"
          )} / ${ConfigProvider.getTranslation("NoWageringCredit")}`}
          val={!loading && data ? `${data.discount} ${data.currency}` : ""}
          desc={ConfigProvider.getTranslation("NET")}
          loading={loading}
        />

        {!ConfigProvider.config.onlyCasino && (
          <CardMaker
            icon="sport"
            text={ConfigProvider.getTranslation("Sport")}
            val={
              !loading && data ? (
                <span className="text-emerald-500">{`${data.sport1} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Income")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlyCasino && (
          <CardMaker
            icon="sport"
            text={ConfigProvider.getTranslation("Sport")}
            val={
              !loading && data ? (
                <span className="text-rose-500">{`${data.sport2} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Expense")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlyCasino && (
          <CardMaker
            icon="sport"
            text={ConfigProvider.getTranslation("Sport")}
            val={!loading && data ? `${data.sport} ${data.currency}` : ""}
            desc={ConfigProvider.getTranslation("NET")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="casino"
            text={ConfigProvider.getTranslation("Slot")}
            val={
              !loading && data ? (
                <span className="text-emerald-500">{`${data.slot1} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Income")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="casino"
            text={ConfigProvider.getTranslation("Slot")}
            val={
              !loading && data ? (
                <span className="text-rose-500">{`${data.slot2} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Expense")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="casino"
            text={ConfigProvider.getTranslation("Slot")}
            val={!loading && data ? `${data.slot} ${data.currency}` : ""}
            desc={ConfigProvider.getTranslation("NET")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon={<RouletteSVG className="w-8 h-8 mr-2 fill-indigo-300" />}
            text={ConfigProvider.getTranslation("LiveCasino")}
            val={
              !loading && data ? (
                <span className="text-emerald-500">{`${data.liveCasino1} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Income")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon={<RouletteSVG className="w-8 h-8 mr-2 fill-indigo-300" />}
            text={ConfigProvider.getTranslation("LiveCasino")}
            val={
              !loading && data ? (
                <span className="text-rose-500">{`${data.liveCasino2} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Expense")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon={<RouletteSVG className="w-8 h-8 mr-2 fill-indigo-300" />}
            text={ConfigProvider.getTranslation("LiveCasino")}
            val={!loading && data ? `${data.liveCasino} ${data.currency}` : ""}
            desc={ConfigProvider.getTranslation("NET")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="wagering"
            text={ConfigProvider.getTranslation("BonusWagering")}
            val={
              !loading && data ? (
                <span className="text-emerald-500">{`${data.bonusWagering1} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Income")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="wagering"
            text={ConfigProvider.getTranslation("BonusWagering")}
            val={
              !loading && data ? (
                <span className="text-rose-500">{`${data.bonusWagering2} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Expense")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="bonus"
            text={ConfigProvider.getTranslation("BonusWagering")}
            val={
              !loading && data ? `${data.bonusWagering} ${data.currency}` : ""
            }
            desc={ConfigProvider.getTranslation("NET")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="bonus"
            text={ConfigProvider.getTranslation("BonusAward")}
            val={
              !loading && data ? (
                <span className="text-emerald-500">{`${data.bonusAward1} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Income")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="bonus"
            text={ConfigProvider.getTranslation("BonusAward")}
            val={
              !loading && data ? (
                <span className="text-rose-500">{`${data.bonusAward2} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Expense")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="wagering"
            text={ConfigProvider.getTranslation("BonusAward")}
            val={!loading && data ? `${data.bonusAward} ${data.currency}` : ""}
            desc={ConfigProvider.getTranslation("NET")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="freespin"
            text={ConfigProvider.getTranslation("Freespin")}
            val={
              !loading && data ? (
                <span className="text-emerald-500">{`${data.freespin1} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Income")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="freespin"
            text={ConfigProvider.getTranslation("Freespin")}
            val={
              !loading && data ? (
                <span className="text-rose-500">{`${data.freespin2} ${data.currency}`}</span>
              ) : (
                ""
              )
            }
            desc={ConfigProvider.getTranslation("Expense")}
            loading={loading}
          />
        )}
        {!ConfigProvider.config.onlySports && (
          <CardMaker
            icon="freespin"
            text={ConfigProvider.getTranslation("Freespin")}
            val={!loading && data ? `${data.freespin} ${data.currency}` : ""}
            desc={ConfigProvider.getTranslation("NET")}
            loading={loading}
          />
        )}
        <CardMaker
          icon={<DiamondSVG className="w-8 h-8 mr-2 text-indigo-300" />}
          text={ConfigProvider.getTranslation("Jackpot")}
          val={
            !loading && data ? (
              <span className="text-emerald-500">{`${data.jackpot1} ${data.currency}`}</span>
            ) : (
              ""
            )
          }
          desc={ConfigProvider.getTranslation("Income")}
          loading={loading}
        />
        <CardMaker
          icon={<DiamondSVG className="w-8 h-8 mr-2 text-indigo-300" />}
          text={ConfigProvider.getTranslation("Jackpot")}
          val={
            !loading && data ? (
              <span className="text-rose-500">{`${data.jackpot2} ${data.currency}`}</span>
            ) : (
              ""
            )
          }
          desc={ConfigProvider.getTranslation("Expense")}
          loading={loading}
        />
        <CardMaker
          icon={<DiamondSVG className="w-8 h-8 mr-2 text-indigo-300" />}
          text={ConfigProvider.getTranslation("Jackpot")}
          val={!loading && data ? `${data.jackpot} ${data.currency}` : ""}
          desc={ConfigProvider.getTranslation("NET")}
          loading={loading}
        />
      </div>
    </>
  );
}

export default SummaryCard;

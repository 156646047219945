import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import TreeMain from "../../components/Tree/TreeMain";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import {
  ArrowRightCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Alert from "../../components/Alert";
import Requests from "../../helpers/Requests";
import { useNavigate } from "react-router-dom";
import { TypeIcon } from "../../components/Tree/TypeIcon";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import DomainDropdown from "../../components/DomainDropdown";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";

import { useTreeData } from "../../context/TreeDataContext";

function AddAccount() {
  const ConfigProvider = useConfig();
  const TreeDataProvider = useTreeData();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "AccountsAdd")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    uname: "",
    currency: "",
    name: "",
    password: "",
    password2: "",
    auth: "2",
    allowTransferMoney: false,
    domain: [],
  });
  const [messages, setMessages] = useState([]);

  const updateAccount = (e) => {
    e.preventDefault();

    if (!loading) {
      setMessages([]);
      let requiredFieldErros = [];
      if (formData.currency.length !== 3) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Currency"));
      }

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        document.getElementById("mainBody").scrollTo(0, 0);
      } else {
        setLoading(true);
        Requests.postData("/accounts/updateAccount", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              TreeDataProvider.getItems();
              setTimeout(() => {
                navigate(`/accounts/account/view/${res.newId}`);
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleChangeInfo = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain />
        ) : null}
        <div className="relative grow min-w-0">
          <WelcomeBanner />
          <form className="w-full" autoComplete="off" onSubmit={updateAccount}>
            {/* Panel body */}
            <div className="p-6 space-y-6">
              <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                {ConfigProvider.getTranslation("Register.AccountInformation")}
              </h2>
              <div className="w-full mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>

              <section>
                <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="uname"
                    >
                      {ConfigProvider.getTranslation("Username")}
                    </label>
                    <input
                      id="uname"
                      name="uname"
                      type="text"
                      value={formData.uname}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("Username")}
                      required
                      maxLength={15}
                      disabled={loading}
                    />
                  </div>

                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      {ConfigProvider.getTranslation("Name")}
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChangeInfo}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("Name")}
                      maxLength={50}
                      disabled={loading}
                    />
                  </div>
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="currency"
                    >
                      {ConfigProvider.getTranslation("Currency")}
                    </label>
                    <CurrencyDropdown
                      name="currency"
                      selected={formData.currency}
                      setSelected={(val) => {
                        setFormData((prev) => {
                          return { ...prev, currency: val };
                        });
                      }}
                    />
                  </div>
                  {ConfigProvider.config.isShopSystem && (
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="role"
                      >
                        {ConfigProvider.getTranslation("Role")}
                      </label>

                      <div className="flex w-full flex-wrap -space-x-px">
                        <button
                          type="button"
                          className={`inline-flex w-1/2 justify-center items-center border text-sm font-semibold p-2  border-slate-200 dark:border-slate-600 rounded-none first:rounded-l last:rounded-r ${
                            formData.auth === "1"
                              ? "text-white dark:text-slate:200 bg-indigo-500"
                              : "bg-slate-50 dark:bg-slate-800 hover:bg-slate-50  dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300"
                          }`}
                          onClick={() =>
                            setFormData({ ...formData, auth: "1" })
                          }
                          disabled={loading}
                        >
                          <span className="mr-2">
                            <TypeIcon auth="1" stat={1} />
                          </span>
                          {ConfigProvider.getTranslation("Role.Reseller")}
                        </button>
                        <button
                          type="button"
                          className={`inline-flex w-1/2 justify-center items-center border text-sm font-semibold p-2  border-slate-200 dark:border-slate-600 rounded-none first:rounded-l last:rounded-r ${
                            formData.auth === "2"
                              ? "text-white dark:text-slate:200 bg-indigo-500"
                              : "bg-slate-50 dark:bg-slate-800 hover:bg-slate-50  dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300"
                          }`}
                          onClick={() =>
                            setFormData({ ...formData, auth: "2" })
                          }
                          disabled={loading}
                        >
                          <span className="mr-2">
                            <TypeIcon auth="2" stat={1} />
                          </span>
                          {ConfigProvider.getTranslation("Role.Shop")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                  {ConfigProvider.getTranslation("Password")}
                </h2>

                <div className="mt-5">
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        {ConfigProvider.getTranslation("Password.New")}
                      </label>
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password}
                        onChange={handleChangeInfo}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password2"
                      >
                        {ConfigProvider.getTranslation("Password.NewAgain")}
                      </label>
                      <input
                        id="password2"
                        name="password2"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password2}
                        onChange={handleChangeInfo}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <ul className="ml-10 my-2 list-disc">
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.MinMaxLength"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.RequiredUppcaseandLowercaseAndNumber"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.CaseSensetive"
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              {ConfigProvider.config.isShopSystem &&
                ConfigProvider.config.user.auth === "0" && (
                  <section>
                    <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                      {ConfigProvider.getTranslation("Settings")}
                    </h2>
                    <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                      <div className="relative">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="domain"
                        >
                          {ConfigProvider.getTranslation("DomainSettings")}
                        </label>
                        <DomainDropdown
                          name="domain"
                          selected={formData.domain}
                          setSelected={(val) => {
                            setFormData((prev) => {
                              if (prev.domain.includes(val)) {
                                return {
                                  ...prev,
                                  domain: prev.domain.filter(
                                    (item) => item !== val
                                  ),
                                };
                              } else {
                                return {
                                  ...prev,
                                  domain: [...prev.domain, val],
                                };
                              }
                            });
                          }}
                        />
                      </div>
                      <div className="relative">
                        <label className="block text-sm font-medium mb-1">
                          &nbsp;
                        </label>
                        <div className="m-3 w-full">
                          <div className="flex items-center gap-3">
                            <div className="form-switch">
                              <input
                                id="allowTransferMoney"
                                name="allowTransferMoney"
                                type="checkbox"
                                className="sr-only"
                                checked={formData.allowTransferMoney}
                                onChange={() =>
                                  setFormData({
                                    ...formData,
                                    allowTransferMoney:
                                      !formData.allowTransferMoney,
                                  })
                                }
                                disabled={loading}
                              />
                              <label
                                className="bg-slate-400 dark:bg-slate-700"
                                htmlFor="allowTransferMoney"
                              >
                                <span
                                  className="bg-white shadow-sm"
                                  aria-hidden="true"
                                ></span>
                              </label>
                            </div>

                            <label
                              className="block text-sm font-medium"
                              htmlFor="allowTransferMoney"
                            >
                              {ConfigProvider.getTranslation(
                                "AllowsPlayerstoTransferMoneyBetweenShops"
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
            </div>
            {/* Panel footer */}
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                <div className="flex self-end">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-4 h-4 mb-1" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-4 h-4" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </footer>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav />
      ) : null}
    </>
  ) : null;
}
function WelcomeBanner() {
  const ConfigProvider = useConfig();
  return (
    <div className="relative bg-indigo-200 dark:bg-indigo-500 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      >
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="24.537%"
              x2="50%"
              y2="100%"
              id="welcome-c"
            >
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-d)"
                d="M64-24h80v152H64z"
              />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-f)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-h)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative">
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">
          {ConfigProvider.getTranslation("Account")}
          {" / "}
          {ConfigProvider.getTranslation("Add")} 💫
        </h1>
      </div>
    </div>
  );
}

export default AddAccount;

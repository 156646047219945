import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import ModalBlank from "./ModalBlank";
function Confirm({ title, text, confirm, callback, buttonType = "button" }) {
  const ConfigProvider = useConfig();
  const [modalOpen, setModalOpen] = useState(null);
  useEffect(() => {
    if (modalOpen !== null) {
      if (!modalOpen && callback !== null) {
        callback();
      }
    }
    // eslint-disable-next-line
  }, [modalOpen]);
  useEffect(() => {
    setModalOpen(true);
    // eslint-disable-next-line
  }, []);
  return (
    <ModalBlank id="modal" modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="p-5 flex space-x-4">
        {/* Icon */}
        <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-emerald-100 dark:bg-emerald-400/30">
          <svg
            className="w-4 h-4 shrink-0 fill-current text-emerald-500"
            viewBox="0 0 16 16"
          >
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
          </svg>
        </div>
        {/* Content */}
        <div className="grow">
          {/* Modal header */}
          <div className="mb-2">
            <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
              {title}
            </div>
          </div>
          {/* Modal content */}
          <div className="text-sm mb-10">
            <div className="space-y-2">{text}</div>
          </div>
          {/* Modal footer */}
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              type="button"
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(false);
              }}
            >
              {ConfigProvider.getTranslation("Cancel")}
            </button>
            <button
              type={buttonType}
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={(e) => {
                e.stopPropagation();
                buttonType !== "submit" && setModalOpen(false);
                confirm();
              }}
            >
              {ConfigProvider.getTranslation("Confirm")}
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
  );
}

export default Confirm;

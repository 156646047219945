import React from "react";
import AccountLayout from "./helpers/AccountLayout";
import Tickets from "../Tickets";
import { useParams } from "react-router-dom";

function TicketAccount() {
  return (
    <AccountLayout>
      <TicketAccountPage />
    </AccountLayout>
  );
}
function TicketAccountPage() {
  const { _id } = useParams();
  return (
    <>
      <Tickets selAdmin={_id} />
    </>
  );
}

export default TicketAccount;
